import "./styles.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ThanksLetter = () => {
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  return (
    <div className="thanks-msg container">
      <div className="text-center">
        <h1>
          <span>{t("thank")}</span> {t("you!")} <br /> {t("We received")}{" "}
          <span>{t("your request")}</span> {t("successfully")}
        </h1>
        <p>
          <b>"{t("King Saud University for Publication")}"</b> {t("benefit")}{" "}
          {t("Receipt_publication_proposal")}
          <b> ({state?.title[i18n.language]}) </b>
          {t("has number")}
          <b> ({state?.id}) </b>
          {t("request_submitted_through_electronic")} <br />
          <b>{t("Follow_status_application")}</b>
          <br />
          <b>{t("CEO")} </b> {t("for ")}
          {t("King Saud University for Publication")} ,
          {t("with my best regards.")}
        </p>
        <NavLink to="/applicant/publications" className="as-btn">
          {t("apply_publish")}
        </NavLink>
      </div>
    </div>
  );
};
