import { useEffect, useState } from "react";
import { IPopup } from "../../types/popup";
import "./Popup.scss";
export const Popup = (props: React.PropsWithChildren<IPopup>) => {
  const [showPopup, setShowPopup] = useState(false);

  const closeHandler = () => {
    setShowPopup(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShowPopup(props.showPopup);
  }, [props.showPopup]);
  return (
    <div
      className="popup"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
      }}
    >
      <div className="popup__overlay" onClick={closeHandler}></div>
      <div className={`popup__content-container ${props.widePopup && "wide"}`}>
        {props.children}
      </div>
    </div>
  );
};
