import { FormPanel } from "components/FormPanel";
import InputNumber from "components/InputNumber";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
export const ProposalDetails = () => {
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel title={`${t("details")} ${formType}`}>
      <div className="main-form__radio-container">
        <label>
          {formType === t("book_translation")
            ? t("translation_lang")
            : t("Authorship_lang")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="authoringLanguage"
            value="arabic"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "authoringLanguage",
                  val: e.target.value,
                })
              )
            }
            checked={publicationDetails.authoringLanguage === "arabic"}
            type="radio"
          />
          {t("arabic")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="authoringLanguage"
            value="english"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "authoringLanguage",
                  val: e.target.value,
                })
              )
            }
            checked={publicationDetails.authoringLanguage === "english"}
            type="radio"
          />
          {t("english")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="authoringLanguage"
            value=""
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "authoringLanguage",
                  val: e.target.value,
                })
              )
            }
            checked={
              !["arabic", "english"].includes(
                publicationDetails.authoringLanguage
              )
            }
            type="radio"
          />
          {t("other")}
        </label>
        {!["arabic", "english"].includes(
          publicationDetails.authoringLanguage
        ) && (
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="authoringLanguage"
              value={publicationDetails.authoringLanguage}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "authoringLanguage",
                    val: e.target.value,
                  })
                )
              }
              type="input"
              placeholder={t("language")}
            />
          </label>
        )}
      </div>
      <div className="col-3">
        <div className="main-form__input-container">
          <label>
            {t("title")} {formType} {t("by_ar")}{" "}
            <span className="required-input">*</span>
          </label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("title")} ${formType} ${t("by_ar")}`}
            type="text"
            name="arabicTitle"
            value={publicationDetails.arabicTitle || ""}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "arabicTitle",
                  val: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="main-form__input-container">
          <label>
            {t("title")} {formType} {t("by_en")}{" "}
            <span className="required-input">*</span>
          </label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("title")} ${formType} ${t("by_en")}`}
            type="text"
            name="englishTitle"
            value={publicationDetails.englishTitle || ""}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "englishTitle",
                  val: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="main-form__input-container">
          <label>
            {t("title")} {formType} {t("by_other")}
          </label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("title")} ${formType} ${t("by_other")}`}
            type="text"
            name="otherLanguageTitle"
            value={publicationDetails.otherLanguageTitle || ""}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "otherLanguageTitle",
                  val: e.target.value,
                })
              )
            }
          />
        </div>
      </div>
      <div className="col-2">
        <div className="main-form__input-container">
          <label>
            {t("main_idea_of")}
            {formType} {t("(within 100 words)")}{" "}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("main_idea_of")} ${formType} ${t(
              "(within 100 words)"
            )}`}
            name="mainIdea"
            value={publicationDetails.mainIdea}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "mainIdea",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("scientific_value_of")}
            {formType} {formType !== t("series") && t("(within 100 words)")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("placeholder_main_idea_book_explain")}
            name="scientificValue"
            value={publicationDetails.scientificValue}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "scientificValue",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      </div>
      <div className="col-2">
        <div className="main-form__input-container">
          <label>
            {t("scientific_method_used")}
            {formType} {t("(within 50 words)")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("scientific_method_used")}${formType} ${t(
              "(within 50 words)"
            )}`}
            name="scientificMethod"
            value={publicationDetails.scientificMethod}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "scientificMethod",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("Preliminary_list_of")} {formType}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("Preliminary_list_of")}${formType}`}
            name="contentsList"
            value={publicationDetails.contentsList}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "contentsList",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      </div>
      <div className="col-3">
        <div className="main-form__input-container">
          <label>
            {t("page_num")} {t("250_word_page")}
            <span className="required-input">*</span>
          </label>
          <InputNumber
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("page_num")} ${t("250_word_page")}`}
            name="pagesNumber"
            value={publicationDetails.pagesNumber || 0}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "pagesNumber",
                  val: e.target.value,
                })
              )
            }
          />
        </div>
        {[t("book"), t("Series")].includes(formType) && (
          <div className="main-form__input-container">
            <label>
              {t("num_chapters")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("num_chapters")}
              name="chaptersNumber"
              value={publicationDetails.chaptersNumber || 0}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "chaptersNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        )}

        {formType !== t("book_translation") && (
          <div className="main-form__input-container">
            <label>
              {t("num_parts")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("num_parts")}
              name="partsNumber"
              value={publicationDetails.partsNumber || 0}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "partsNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        )}
      </div>
      {formType === t("atlas") && (
        <div className="main-form__input-container">
          <label>
            {t("topic_of")}
            {formType}
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("topic_of")}${formType} ${t(
              "(within 100 words)"
            )}`}
            name="topic"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "topic",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      )}
      {[t("atlas"), t("encyclopedia")].includes(formType) && (
        <div className="main-form__input-container">
          <label>
            {t("target_from")}
            {formType}
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("target_from")}${formType} ${t(
              "(within 100 words)"
            )}`}
            name="otherGoal"
            value={publicationDetails.otherGoal}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "otherGoal",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      )}
    </FormPanel>
  );
};
