import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IAlert } from "components/Alert/alert";

let alertTimeout: NodeJS.Timeout;
// Define the initial state using that type
const initialState: IAlert = {
  show: false,
  successMsgAR: "",
  successMsgEN: "",
  msgAR: "",
  msgEN: "",
  type: "normal",
  hasControls: false,
  onAcceptBody: {},
  invoker: "general",
  refetchDataCount: 0,
};

const getAlertType = (type: string) => {
  let pickAlertType: Pick<IAlert, "type"> = {};
  pickAlertType.type = "normal";
  switch (true) {
    case type === "success":
      pickAlertType.type = "success";
      break;
    case type === "Accept":
      pickAlertType.type = "normal";
      break;
    case type === "Reject" || type === "Reject request":
      pickAlertType.type = "error";
      break;

    default:
      pickAlertType.type = "warning";
      break;
  }
  return pickAlertType.type;
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    pushAlert: (state, action) => {
      return {
        ...state,
        ...action.payload,
        show: action.payload.show ?? state.show,
        type: action.payload.type ? getAlertType(action.payload.type) : state.type,
        successMsgAR: action.payload.successMsgAR ?? state.successMsgAR,
        successMsgEN: action.payload.successMsgEN ?? state.successMsgEN,
      };
    },
  },
});

export const selectAlert = (state: RootState) => state.alert;
export const { pushAlert } = alertSlice.actions;
export default alertSlice.reducer;
