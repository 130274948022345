import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import { Popup } from "../../../components/Popup/Popup";
import { GetFields } from "../../../services/fields.service";
import {
  AddUser,
  EnableDisableUser,
  GetUserRoles,
  GetUsers,
  RemoveUser,
} from "../../../services/user.service";
import "./ManageUsers.scss";
import { User } from "./../../../types/user";
import { EditUsers } from "./../../../services/user.service";
export const ManageUsers = () => {
  const [userRoles, setUserRoles] = useState([]);
  const [fields, setFields] = useState([]);
  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const { t } = useTranslation();

  const userTemp: Partial<User> = {
    name: "",
    email: "",
    password: "",
    role: "Editor",
    phoneNumber: "",
    fieldId: 0,
    insource: false,
  };
  const [user, setUser] = useState<Partial<User>>(userTemp);
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    getUserRoles();
    getUsers();
    getFields();
  }, []);
  const getFields = () => {
    GetFields().then((response) => {
      setFields(response.data);
    });
  };
  const getUserRoles = () => {
    GetUserRoles().then((res) => setUserRoles(res.data));
  };
  const getUsers = () => {
    GetUsers().then((response) => {
      setUsers(response.data);
      setUser(userTemp);
      setShowAddEditPopup(false);
      setShowRemovePopup(false);
    });
  };
  const renderUsers = () => {
    const filteredUsers = users.filter((user) =>
      selectedFilter ? user.role === selectedFilter : true
    );
    return filteredUsers.map((user, i) => (
      <tr key={user.id}>
        <td>{user.name}</td>
        <td>{t(user.role ?? "")}</td>
        <td>{user.email}</td>
        <td>{user.phoneNumber}</td>
        <td>{user.fieldName}</td>
        <td>
          <button
            className="btn edit"
            onClick={() => {
              setShowAddEditPopup(true);
              setIsEditMode(true);
              setUser(user);
            }}
          >
            {t("Edit")}
          </button>
        </td>
        <td>
          {user.role !== "Admin" && (
            <button
              className="btn delete"
              onClick={() => {
                setShowRemovePopup(true);
                setUser(user);
              }}
            >
              {t("delete")}
            </button>
          )}
        </td>
        <td>
          {user.role !== "Admin" && (
            <div className="check-box">
              <input
                autoComplete="off"
                type="checkbox"
                key={i}
                checked={user.isActive}
                onChange={() => toggleUserActivity(user)}
              />
            </div>
          )}
        </td>
      </tr>
    ));
  };
  // Form Listen & Submit
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const key = event.target.name;
    let val: string | boolean = event.target.value;
    let tempUser = { ...user };
    if (key === "role") tempUser = { ...tempUser, fieldId: 0 };
    if (key === "insource") val = Boolean(+val);

    setUser({
      ...tempUser,
      [key]: val,
    });
  };
  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    isEditMode
      ? EditUsers(user.id, user).then(() => {
          getUsers();
        })
      : AddUser(user).then(() => {
          getUsers();
        });
  };

  const toggleUserActivity = (user: User) => {
    const tempList = [
      ...users.map((u) =>
        u.id === user.id ? { ...user, isActive: !user.isActive } : u
      ),
    ];
    setUsers(tempList);
    EnableDisableUser(user).then(() => getUsers());
  };

  const isAddFormInvalid = () => {
    if (user.email && user.password && user.phoneNumber) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = emailPattern.test(user.email);
      const isPasswordValid = user.password.length >= 8;
      const isPhoneNumberValid = user.phoneNumber.length >= 6;

      return !(isEmailValid && isPasswordValid && isPhoneNumberValid);
    }

    return true;
  };

  const isEditFormInvalid = () => {
    if (user.email && user.phoneNumber) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = emailPattern.test(user.email);
      const isPhoneNumberValid = user.phoneNumber.length >= 6;

      return !(isEmailValid && isPhoneNumberValid);
    }

    return true;
  };

  return (
    <div className="container">
      <Breadcrumb>
        <div className="breadcrumb__filters">
          <select
            className="filter-select"
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <option value="">{t("all")}</option>
            <option value="Author">{t("authors")}</option>
            <option value="Arbitrator">{t("arbitrators")}</option>
          </select>
          <button
            onClick={() => {
              setShowAddEditPopup(true);
              setIsEditMode(false);
              setUser(userTemp);
            }}
          >
            {t("add_user")}
          </button>
        </div>
      </Breadcrumb>
      <div className="table-responsive">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("user_name")}</th>
              <th>{t("role")}</th>
              <th>{t("email")}</th>
              <th>{t("phone")}</th>
              <th>{t("field")}</th>
              <th colSpan={2}></th>
              <th>{t("activation")}</th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              renderUsers()
            ) : (
              <tr>
                <td colSpan={12}>{t("no_data_show")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowAddEditPopup(false);
        }}
        showPopup={showAddEditPopup}
      >
        <form
          autoComplete="off"
          onSubmit={handleFormSubmit}
          className="modern-form"
        >
          <h2>{isEditMode ? t("edit_user") : t("add_user")} </h2>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("user_name")}</label>
              <input
                autoComplete="off"
                type="text"
                name="name"
                value={user.name}
                onChange={handleFormChange}
                placeholder={t("user_name")}
                autoFocus
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("email")}</label>
              <input
                autoComplete="off"
                type="email"
                name="email"
                value={user.email}
                onChange={handleFormChange}
                placeholder={t("email")}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("phone")}</label>
              <input
                autoComplete="off"
                type="text"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleFormChange}
                placeholder={t("phone")}
              />
            </div>
            {!isEditMode && (
              <div className="modern-form__input-container">
                <label>{t("password")}</label>
                <input
                  autoComplete="off"
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleFormChange}
                  placeholder={t("password")}
                />
              </div>
            )}
          </div>
          <div className={user.role === "Editor" ? "col-2" : "col-1"}>
            {!isEditMode && (
              <>
                <div className="modern-form__input-container">
                  <label>{t("role")}</label>
                  <select
                    name="role"
                    value={user.role}
                    onChange={handleFormChange}
                    disabled={isEditMode}
                  >
                    <option value="">{t("role")}</option>
                    {userRoles.map((role) => (
                      <option key={role} value={role}>
                        {t(role)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {user.role === "Editor" && !isEditMode && (
              <div className="modern-form__input-container">
                <label>{t("field")}</label>
                <select
                  name="fieldId"
                  value={user.fieldId}
                  onChange={handleFormChange}
                >
                  <option value="">{t("field")}</option>
                  {fields.map((field) => (
                    <option key={field["id"]} value={field["id"]}>
                      {field["name"]}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {user.role === "Proofreader" && (
            <div className="main-form__radio-container">
              <label>{t("insource")}</label>
              <label>
                <input
                  autoComplete="off"
                  name="insource"
                  value={1}
                  onChange={handleFormChange}
                  type="radio"
                />
                {t("yes")}
              </label>
              <label>
                <input
                  autoComplete="off"
                  name="insource"
                  value={0}
                  onChange={handleFormChange}
                  type="radio"
                />
                {t("no")}
              </label>
            </div>
          )}
          <div className="modern-form__footer">
            <button
              disabled={isEditMode ? isEditFormInvalid() : isAddFormInvalid()}
            >
              {isEditMode ? t("Edit") : t("add")}
            </button>
          </div>
        </form>
      </Popup>
      <Popup
        onClose={() => {
          setShowRemovePopup(false);
        }}
        showPopup={showRemovePopup}
      >
        <div className="popup__remove">
          <p>
            {t("delete_user_request")}
            <b>{user.name} ؟ </b>
          </p>
          <div>
            <button
              className="btn normal"
              onClick={() => {
                setShowRemovePopup(false);
              }}
            >
              {t("close")}
            </button>
            <button
              className="btn delete"
              onClick={() => {
                RemoveUser(user.id ?? 0).then(() => {
                  getUsers();
                });
              }}
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
