import { FormPanel } from "components/FormPanel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import DatePicker from "react-datepicker";
import {
  addPublishingOther,
  modifyPublicationDetails,
  removePublishingOther,
  selectPublicationDetails,
} from "../../../../../../store/states/publicationDetailsSlice";
import convertDateToFormat from "../../../../../../utils/convertDateToFormat";
export const OtherPublications = () => {
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);

  const getPerson = (formType: string) => {
    switch (formType) {
      case t("manu_script"):
        return t("investigator_book");

      case t("book_translation"):
        return t("translator_book");

      case t("dictionary"):
        return t("Author_dictionary");

      case t("atlas"):
        return t("Author_atlas");

      default:
        return t("author_book");
    }
  };
  return (
    <FormPanel title={t("other_publications")}>
      <div className="main-form__radio-container">
        <label>
          {t("is_the")}
          {getPerson(formType)} {t("main")}{" "}
          {t("or_parnter_another_publication")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasOtherPublishings"
            value="1"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "hasOtherPublishings",
                  val: e.target.value,
                })
              )
            }
            checked={!!+publicationDetails.hasOtherPublishings}
            type="radio"
          />
          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasOtherPublishings"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "hasOtherPublishings",
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.hasOtherPublishings}
            type="radio"
          />
          {t("no")}
        </label>
      </div>
      {!!+publicationDetails.hasOtherPublishings && (
        <>
          {publicationDetails.publishingOthers.map((_: any, i: number) => (
            <FormPanel
              staticPanel={true}
              key={i}
              title={`${t("book_num")} "${i + 1}"`}
              headerButton={
                i ? (
                  <button
                    className="btn delete"
                    type="button"
                    onClick={(event) =>
                      dispatch(removePublishingOther({ removeId: i }))
                    }
                  >
                    {t("delete")}
                  </button>
                ) : (
                  ""
                )
              }
            >
              <div className="col-4 mb-2">
                <div className="main-form__input-container">
                  <label>
                    {t("publisher")}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    placeholder={t("publisher")}
                    type="text"
                    name="publisher"
                    value={publicationDetails.publishingOthers[i].publisher}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "publisher",
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("book_title")}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    placeholder={t("book_title")}
                    type="text"
                    name="title"
                    value={publicationDetails.publishingOthers[i].title}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "title",
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("publication_place")}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    placeholder={t("publication_place")}
                    type="text"
                    name="publishingPlace"
                    value={
                      publicationDetails.publishingOthers[i].publishingPlace
                    }
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "publishingPlace",
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("Publisher_date")}
                    <span className="required-input">*</span>
                  </label>

                  <DatePicker
                    disabled={publicationDetails.isViewMode}
                    placeholderText={t("Publisher_date")}
                    name="publishingDate"
                    value={convertDateToFormat(
                      publicationDetails.publishingOthers[i].publishingDate ||
                        new Date()
                    )}
                    onChange={(date) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "publishingDate",
                          val: date,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                    dateFormat={"dd/MM/yyyy"}
                  />
                </div>
              </div>
              <div className="main-form__radio-container">
                <label>{t("book_type")}</label>
                <label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    value={t("author")}
                    name={`type_${i}`}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: `type_${i}`,
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                    checked={
                      publicationDetails.publishingOthers[i].type ===
                      t("author")
                    }
                    type="radio"
                  />
                  {t("author")}
                </label>
                <label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    name={`type_${i}`}
                    value={t("Translator")}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: `type_${i}`,
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                    checked={
                      publicationDetails.publishingOthers[i].type ===
                      t("Translator")
                    }
                    type="radio"
                  />
                  {t("Translator")}
                </label>
                <label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    name={`type_${i}`}
                    value={t("investigator")}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: `type_${i}`,
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                    checked={
                      publicationDetails.publishingOthers[i].type ===
                      t("investigator")
                    }
                    type="radio"
                  />
                  {t("investigator")}
                </label>
                <label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    name={`type_${i}`}
                    value={t("other")}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: `type_${i}`,
                          val: e.target.value,
                          dynamicFormKey: "publishingOthers",
                          i: i,
                        })
                      )
                    }
                    checked={
                      ![
                        t("author"),
                        t("Translator"),
                        t("investigator"),
                      ].includes(publicationDetails.publishingOthers[i].type)
                    }
                    type="radio"
                  />
                  {t("other")}
                </label>
                {![t("author"), t("Translator"), t("investigator")].includes(
                  publicationDetails.publishingOthers[i].type
                ) && (
                  <label>
                    <input
                      autoComplete="off"
                      disabled={publicationDetails.isViewMode}
                      name={`type_${i}`}
                      value={publicationDetails.publishingOthers[i].type}
                      onChange={(e) =>
                        dispatch(
                          modifyPublicationDetails({
                            key: `type_${i}`,
                            val: e.target.value,
                            dynamicFormKey: "publishingOthers",
                            i: i,
                          })
                        )
                      }
                      type="input"
                      placeholder={t("Attachment_type")}
                    />
                  </label>
                )}
              </div>
            </FormPanel>
          ))}
          <div className="text-center">
            <button
              className="btn"
              type="button"
              onClick={() => dispatch(addPublishingOther())}
            >
              {t("add")}
            </button>
          </div>
        </>
      )}
    </FormPanel>
  );
};
