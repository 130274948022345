import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import i18n from "../../i18n";

// Define the initial state using that type
const initialState = "";

export const formTypeSlice = createSlice({
  name: "formType",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    modifyFormState: (state, action): string => {
      action.payload = action.payload.toString();
      let currentFormKey = "book";
      if (!action.payload) {
        return i18n.t(localStorage.getItem("formType") || currentFormKey);
      }
      switch (action.payload) {
        case "6":
          currentFormKey = "manu_script"; //manu_script;
          break;
        case "2":
          currentFormKey = "book_translation"; //book_translation;
          break;
        case "12":
          currentFormKey = "series"; //series;
          break;
        case "13":
          currentFormKey = "series"; //series;
          break;
        case "8":
          currentFormKey = "dictionary"; //dictionary;
          break;
        case "9":
          currentFormKey = "atlas"; //atlas;
          break;
        case "10":
          currentFormKey = "encyclopedia"; //encyclopedia;
          break;
        case "7":
          currentFormKey = "scientific_letter"; //scientific_letter;
          break;
        case "11":
          currentFormKey = "conference"; //conference;
          break;
        case "14":
          currentFormKey = "research"; //research
          break;
        default:
          currentFormKey = "book";
          break;
      }
      localStorage.setItem("formType", currentFormKey);
      return i18n.t(currentFormKey);
    },
  },
});

export const selectFormType = (state: RootState) => state.formType;
export const { modifyFormState } = formTypeSlice.actions;
export default formTypeSlice.reducer;
