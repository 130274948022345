import InputNumber from "components/InputNumber";
import { Popup } from "components/Popup/Popup";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { pushAlert } from "store/states/alertSlice";
import { User } from "types/user";
interface ChooseProofreaderPopupProps {
  setShowSelectProofreaders: (type: boolean) => void;
  showSelectProofreaders: any;

  setSearchTerm: (term: string) => void;
  selectedProofreader: User | undefined;
  proofreaders: User[];
  searchTerm: string;
  handleProofreadersFormChange: (
    proofreader: User,
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  setComment: (comment: string) => void;
  comment: string;
}
export const ChooseProofreaderPopup = (props: ChooseProofreaderPopupProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    setShowSelectProofreaders,
    showSelectProofreaders,
    setSearchTerm,
    proofreaders,
    searchTerm,
    handleProofreadersFormChange,
    selectedProofreader,
    setComment,
    comment,
  } = props;
  const renderProofreaders = () => {
    let tempProofreadersList = [...proofreaders];

    return tempProofreadersList
      .filter((proofreader) => proofreader.name.includes(searchTerm))
      .map((proofreader, i) => (
        <tr key={proofreader.id}>
          <td>{i + 1}</td>
          <td>
            <input
              autoComplete="off"
              className="select-arbitrators"
              onChange={(e) => handleProofreadersFormChange(proofreader, e)}
              checked={selectedProofreader?.id === proofreader.id}
              value={proofreader.id}
              type="radio"
              name="proofreaders"
            />
          </td>
          <td>{proofreader.name}</td>
          <td>
            {!proofreader.insource && (
              <InputNumber
                value={proofreader.amount}
                name="amount"
                onChange={(e) => handleProofreadersFormChange(proofreader, e)}
              />
            )}
          </td>
          <td>{proofreader.email}</td>
          <td>{proofreader.insource ? t("yes") : t("no")} </td>
        </tr>
      ));
  };
  return (
    <Popup
      onClose={() => {
        setShowSelectProofreaders(false);
      }}
      showPopup={showSelectProofreaders}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowSelectProofreaders(false);
          dispatch(
            pushAlert({
              show: true,
            })
          );
        }}
        className="modern-form"
      >
        <h2>{t("Please_select_proofreader")}</h2>
        <div className="comments-container__arbitratos-selection">
          <input
            autoComplete="off"
            onKeyUp={(event) =>
              setSearchTerm((event.target as HTMLInputElement).value)
            }
            type="text"
            placeholder={t("Please_select_proofreader")}
          />

          <div className="table-responsive">
            <table className="basic-table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>{t("id")}</th>
                  <th>{t("choose")}</th>
                  <th>{t("name")}</th>
                  <th>{t("amount")} SAR</th>
                  <th>{t("email")}</th>
                  <th>{t("insource")}</th>
                </tr>
              </thead>
              <tbody>
                {proofreaders.length ? (
                  renderProofreaders()
                ) : (
                  <tr>
                    <td colSpan={10}>{t("no_data_show")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <textarea
          placeholder={t("Write_comments")}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <div className="modern-form__footer">
          <button>{t("select_proofreader")}</button>
        </div>
      </form>
    </Popup>
  );
};
