import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

// Define a type for the slice state
interface UserDetailsState {
  email: string;
  name: string;
  role: string;
  isApplicant: boolean;
  isArbitrator: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  isManager: boolean;
  isViceManager: boolean;
  isMarketingDirector: boolean;
  isMarketingOfficer: boolean;
  isSecretarialEmployee: boolean;
  isScientificCommitteeDecided: boolean;
  isScientificCommitteeMember: boolean;
  isProofreader: boolean;
  isArtDirector: boolean;
  isArchiveEmployee: boolean;
}

// Define the initial state using that type
const initialState: UserDetailsState = {
  email: "",
  name: "",
  role: "",
  isApplicant: false,
  isArbitrator: false,
  isAdmin: false,
  isEditor: false,
  isManager: false,
  isViceManager: false,
  isMarketingDirector: false,
  isMarketingOfficer: false,
  isSecretarialEmployee: false,
  isScientificCommitteeDecided: false,
  isScientificCommitteeMember: false,
  isProofreader: false,
  isArtDirector: false,
  isArchiveEmployee: false,
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    storeUserDetails: (state) => {
      const userData = JSON.parse(localStorage.getItem("user_details") ?? "{}");
      state.email = userData.email;
      state.name = userData.name;
      state.role = userData.role;
      state.isApplicant = userData.role === "Author";
      state.isArbitrator = userData.role === "Arbitrator";
      state.isAdmin = userData.role === "Admin";
      state.isEditor = userData.role === "Editor";
      state.isManager = userData.role === "Manager";
      state.isViceManager = userData.role === "Vice Manager";
      state.isMarketingDirector = userData.role === "Marketing Director";
      state.isMarketingOfficer = userData.role === "Marketing Officer";
      state.isSecretarialEmployee = userData.role === "Secretarial Employee";
      state.isScientificCommitteeDecided =
        userData.role === "Scientific Committee Decided";
      state.isScientificCommitteeMember =
        userData.role === "Scientific Committee Member";
      state.isProofreader = userData.role === "Proofreader";
      state.isArtDirector = userData.role === "Art director";
      state.isArchiveEmployee = userData.role === "Archive employee";
    },
  },
});

export const selectUserDetails = (state: RootState) => state.userDetails;
export const { storeUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
