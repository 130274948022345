import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";

interface StaticButtonsProps {
  setShowCommentsPopup: (type: boolean) => void;
  setShowRejectionReasonsPopup: (type: boolean) => void;
  setShowCriteriaPopup: (type: boolean) => void;
}
export const StaticButtons = (props: StaticButtonsProps) => {
  const { t } = useTranslation();

  const publicationDetails = useAppSelector(selectPublicationDetails);
  const {
    role,
    isScientificCommitteeDecided,
    isScientificCommitteeMember,
    isManager,
    isSecretarialEmployee,
  } = useAppSelector(selectUserDetails);

  return (
    <>
      {/* Arbitrator can't see comments log */}
      {role !== "Arbitrator" && (
        <button
          onClick={() => props.setShowCommentsPopup(true)}
          type="button"
          className="black"
          disabled={
            publicationDetails.publishingFeedbacks?.length === 0 ||
            role === "Arbitrator"
          }
        >
          {t("Comments_log")}
        </button>
      )}
      {/* Roles below only can see rejection log if exist */}
      {!!publicationDetails.publishingReasonRejects?.length &&
        (isScientificCommitteeDecided ||
          isScientificCommitteeMember ||
          isManager ||
          isSecretarialEmployee) && (
          <button
            onClick={() => props.setShowRejectionReasonsPopup(true)}
            type="button"
          >
            {t("Rejection_record")}
          </button>
        )}
      {/* Roles below only can see arbitration quality log if exist */}
      {!!publicationDetails.publishingArbitrationCriterias?.length &&
        (isScientificCommitteeDecided ||
          isScientificCommitteeMember ||
          isManager ||
          isSecretarialEmployee) && (
          <button
            onClick={() => props.setShowCriteriaPopup(true)}
            type="button"
            className="black"
          >
            {t("Arbitratio_nquality_record")}
          </button>
        )}
    </>
  );
};
