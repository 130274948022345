import axios from "axios";

export const AddSpecializations = () => {
  return axios.post("Specializations");
};
export const GetSpecializations = () => {
  return axios.get("Specializations");
};
export const EditSpecialization = (id: any, Specialization: any) => {
  return axios.put(`Specializations/${id}`, Specialization);
};
