import "./Auth.scss";
import GLogo from "../../assets/icons/google.svg";
import { useState } from "react";
import {
  Login,
  Register,
  ResetPassword,
  SetPassword,
  Verification,
} from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { pushAlert } from "store/states/alertSlice";
import { useAppDispatch } from "store/hooks";
import { Alert } from "components/Alert";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export const Auth = () => {
  const isForgetPasswordPage =
    window.location.pathname.includes("forget_password");
  const isSetPasswordPage = window.location.pathname.includes("set_password");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [currentForm, setcurrentForm] = useState("login");
  const [userCred, setUserCred] = useState({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    verification: "",
    fieldName: "",
  });
  const [userCredReg, setUserCredReg] = useState({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    verification: "",
    fieldName: "",
    amount: 0,
  });
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCred({
      ...userCred,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormRegChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCredReg({
      ...userCredReg,
      [event.target.name]: event.target.value,
    });
  };
  const handleFormSubmit = (type: string, event: any) => {
    event.preventDefault();
    switch (true) {
      case type === "set_password":
        SetPassword(userCred, queryParams.get("token") || "")
          .then((response) => {
            setTimeout(() => {
              navigate("/auth");
            }, 2000);
          })
          .catch((e) => {
            dispatch(
              pushAlert({
                show: true,
                type: "Reject",
                msgAR: e.response.data.message,
                msgEN: e.response.data.message,
              })
            );

            setTimeout(() => {
              dispatch(
                pushAlert({
                  show: false,
                })
              );
            }, 3000);
          });
        break;
      case type === "reset_password":
        ResetPassword(userCred)
          .then((response) => {})
          .catch((e) => {
            dispatch(
              pushAlert({
                show: true,
                type: "Reject",
                msgAR: e.response.data.message,
                msgEN: e.response.data.message,
              })
            );

            setTimeout(() => {
              dispatch(
                pushAlert({
                  show: false,
                })
              );
            }, 3000);
          });
        break;
      case type === "login":
        login(userCred);
        break;
      case type === "register":
        Register(userCredReg)
          .then(() => {
            setShowVerificationPopup(true);
          })
          .catch((e) => {
            dispatch(
              pushAlert({
                show: true,
                type: "Reject",
                msgAR: e.response.data.message,
                msgEN: e.response.data.message,
              })
            );

            setTimeout(() => {
              dispatch(
                pushAlert({
                  show: false,
                })
              );
            }, 3000);
          });
        break;
      case type === "verification":
        Verification(userCredReg)
          .then(() => {
            login(userCredReg);
          })
          .catch((e) => {
            dispatch(
              pushAlert({
                show: true,
                type: "Reject",
                msgAR: e.response.data.message,
                msgEN: e.response.data.message,
              })
            );

            setTimeout(() => {
              dispatch(
                pushAlert({
                  show: false,
                })
              );
            }, 3000);
          });
        break;
    }
  };
  const login = (cred: any) => {
    Login(cred)
      .then((response) => {
        const responseData = response.data;
        const user_token = responseData.token;

        localStorage.setItem(
          "user_details",
          JSON.stringify({
            name: responseData.name,
            email: responseData.email,
            role: responseData.role,
          })
        );

        if (user_token) {
          localStorage.setItem("user_token", user_token);
          window.location.reload();
        } else {
          throw new Error("NO token found");
        }
      })
      .catch((e) => {
        dispatch(
          pushAlert({
            show: true,
            type: "Reject",
            msgAR: e.response.data.message,
            msgEN: e.response.data.message,
          })
        );

        setTimeout(() => {
          dispatch(
            pushAlert({
              show: false,
            })
          );
        }, 3000);
      });
  };
  const isFormInvalid = (): boolean => {
    const emailMatch = userCred.email.match(/.*@.*\.[a-zA-Z]+$/g);
    return !(
      emailMatch &&
      emailMatch[0] === userCred.email &&
      userCred.password.length > 0
    );
  };

  const isRegFormInvalid = (): boolean => {
    const emailMatch = userCredReg.email.match(/.*@.*.[a-z]$/g);
    return !(
      emailMatch &&
      emailMatch[0] === userCredReg.email &&
      userCredReg.password.length >= 8 &&
      userCredReg.phoneNumber.length >= 6
    );
  };

  return (
    <div className="login">
      <div
        className={`login-container ${
          isForgetPasswordPage || isSetPasswordPage ? "one-column" : ""
        }`}
      >
        {isSetPasswordPage && (
          <div
            className={`${
              currentForm === "login" ? "show_form" : ""
            } login-form`}
          >
            <form
              autoComplete="off"
              onSubmit={(event) => handleFormSubmit("set_password", event)}
            >
              <h3>{t("Password_renewal")}</h3>
              <h5>{t("please_insert_password")}</h5>
              <label htmlFor="">
                {t("password")}
                <input
                  autoComplete="off"
                  type="password"
                  name="password"
                  value={userCred.password}
                  onChange={handleFormChange}
                  autoFocus
                  placeholder={t("password")}
                />
              </label>

              <button>{t("Password_renewal")}</button>
            </form>
          </div>
        )}
        {isForgetPasswordPage && (
          <div
            className={`${
              currentForm === "login" ? "show_form" : ""
            } login-form`}
          >
            <form
              autoComplete="off"
              onSubmit={(event) => handleFormSubmit("reset_password", event)}
            >
              <h3>{t("Restore_password")}</h3>
              <h5>{t("please_insert_email")}</h5>
              <label htmlFor="">
                {t("email")}
                <input
                  autoComplete="off"
                  type="text"
                  name="email"
                  value={userCred.email.toLowerCase()}
                  onChange={handleFormChange}
                  autoFocus
                  placeholder={t("email")}
                />
              </label>

              <button>{t("Restore_password")}</button>

              <button
                type="button"
                className="switch-form"
                onClick={() => setcurrentForm("signup")}
              >
                {t("Restore_password")}
              </button>
            </form>
          </div>
        )}
        {!(isForgetPasswordPage || isSetPasswordPage) && (
          <>
            <div
              className={`${
                currentForm === "login" ? "show_form" : ""
              } login-form`}
            >
              <form
                autoComplete="off"
                onSubmit={(event) => handleFormSubmit("login", event)}
              >
                <h3>{t("welcome_back")}</h3>
                <h5>{t("enter_your_email_and_password")} </h5>
                <label htmlFor="">
                  {t("email")}
                  <input
                    autoComplete="off"
                    type="text"
                    name="email"
                    value={userCred.email}
                    onChange={handleFormChange}
                    autoFocus
                    placeholder={t("email")}
                  />
                </label>
                <label htmlFor="">
                  {t("password")}
                  <input
                    autoComplete="off"
                    type="password"
                    name="password"
                    value={userCred.password}
                    onChange={handleFormChange}
                    autoFocus
                    placeholder={t("password")}
                  />
                </label>
                <div className="login-remember">
                  <label htmlFor="">
                    <input autoComplete="off" type="checkbox" />{" "}
                    {t("remember_me")}
                  </label>
                  <NavLink to="/auth/forget_password">
                    {t("forget_password")}
                  </NavLink>
                </div>
                <button disabled={isFormInvalid()}>{t("login")}</button>
                <button
                  type="button"
                  className="switch-form"
                  onClick={() => setcurrentForm("signup")}
                >
                  {t("register")}
                </button>
              </form>
            </div>
            <div
              className={`${
                currentForm === "signup" ? "show_form" : ""
              } signup-form`}
            >
              <>
                {showVerificationPopup ? (
                  <form
                    autoComplete="off"
                    onSubmit={(event) =>
                      handleFormSubmit("verification", event)
                    }
                  >
                    <h3> {t("welcome")}</h3>
                    <h5>{t("Enter_activation_code")}</h5>
                    <label htmlFor="">
                      {t("activation_code")}
                      <input
                        autoComplete="off"
                        type="text"
                        name="verification"
                        value={userCredReg.verification}
                        onChange={handleFormRegChange}
                        placeholder={t("activation_code")}
                      />
                    </label>

                    <button>{t("activation")}</button>
                  </form>
                ) : (
                  <form
                    autoComplete="off"
                    onSubmit={(event) => handleFormSubmit("register", event)}
                  >
                    <h3>{t("welcome")}</h3>
                    <h5>{t("enter_what_is_required_to_register")}</h5>
                    <label htmlFor="">
                      {t("name")}
                      <input
                        autoComplete="off"
                        type="text"
                        name="name"
                        value={userCredReg.name}
                        onChange={handleFormRegChange}
                        placeholder={t("name")}
                      />
                    </label>
                    <label htmlFor="">
                      {t("phone_number")}
                      <input
                        autoComplete="off"
                        type="text"
                        name="phoneNumber"
                        value={userCredReg.phoneNumber}
                        onChange={handleFormRegChange}
                        placeholder={t("phone_number")}
                      />
                    </label>
                    <label htmlFor="">
                      {t("email")}
                      <input
                        autoComplete="off"
                        type="text"
                        name="email"
                        value={userCredReg.email.toLowerCase()}
                        onChange={handleFormRegChange}
                        placeholder={t("email")}
                      />
                    </label>
                    <label htmlFor="">
                      {t("password")}{" "}
                      <input
                        autoComplete="off"
                        type="password"
                        name="password"
                        value={userCredReg.password}
                        onChange={handleFormRegChange}
                        placeholder={t("password_rule")}
                      />
                    </label>
                    <button disabled={isRegFormInvalid()}>
                      {t("register")}{" "}
                    </button>
                    <button
                      type="button"
                      className="switch-form"
                      onClick={() => setcurrentForm("login")}
                    >
                      {t("login")}
                    </button>
                  </form>
                )}
              </>
            </div>
          </>
        )}
      </div>
      <Alert />
    </div>
  );
};
