import { FormPanel } from "components/FormPanel";
import FilesUploader from "components/FilesUploader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import countries from "assets/countries.json";
import {
  addPublishingAuthor,
  modifyPublicationDetails,
  removePublishingAuthor,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import { useEffect, useState } from "react";
import { selectFormType } from "store/states/formTypeSlice";
export const InfoBookAuthor = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formType = useAppSelector(selectFormType);
  const publicationDetails = useAppSelector(selectPublicationDetails);

  let name: any = "";
  let buttonTitle: string = "";
  switch (formType) {
    case t("manu_script"):
      name = t("Co-investigators");
      buttonTitle = t("investigator");
      break;
    case t("book_translation"):
      name = t("Co-translators");
      buttonTitle = t("translator");
      break;
    case t("research"):
      name = t("Co-resershers");
      buttonTitle = t("resersher");
      break;
    case t("series"):
    case t("scientific_letter"):
    case t("book"):
    case t("encyclopedia"):
    case t("dictionary"):
    case t("atlas"):
      buttonTitle = t("author");
      name = t("Co-authors");
      break;

    default:
      t("Co-authors");
      break;
  }
  return (
    <FormPanel title={`${t("info_about")} ${name}`}>
      {publicationDetails.publishingAuthors?.map((_: any, i: number) => (
        <FormPanel
          key={i}
          staticPanel={true}
          title={`${name} ${i + 1 === 1 ? t("main") : i + 1}`}
          headerButton={
            <button
              className="btn delete"
              type="button"
              onClick={(event) =>
                dispatch(removePublishingAuthor({ removeId: i }))
              }
            >
              {t("delete")}
            </button>
          }
        >
          <div className="col-5">
            <div className="main-form__input-container">
              <label>{t("full_name")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("full_name")}
                type="text"
                name="name"
                value={publicationDetails.publishingAuthors[i].name}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "name",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("degree")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("degree")}
                type="text"
                name="degree"
                value={publicationDetails.publishingAuthors[i].degree}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "degree",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Scientific_specialization")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("Scientific_specialization")}
                type="text"
                name="scientificSpecialization"
                value={
                  publicationDetails.publishingAuthors[i]
                    .scientificSpecialization
                }
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "scientificSpecialization",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("university")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("university")}
                type="string"
                name="university"
                value={publicationDetails.publishingAuthors[i].university}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "university",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("facluty")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("facluty")}
                type="text"
                name="college"
                value={publicationDetails.publishingAuthors[i].college}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "college",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("country")}</label>
              <select
                disabled={publicationDetails.isViewMode}
                placeholder={t("country")}
                name="country"
                value={publicationDetails.publishingAuthors[i].country}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                  dispatch(
                    modifyPublicationDetails({
                      key: "country",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  );
                }}
              >
                {countries.map((e, i) => (
                  <option key={i}>{e.country}</option>
                ))}
              </select>
            </div>
            <div className="main-form__input-container">
              <label>{t("city")}</label>
              <select
                disabled={publicationDetails.isViewMode}
                placeholder={t("city")}
                name="city"
                value={publicationDetails.publishingAuthors[i].city}
                onChange={(e) => {
                  dispatch(
                    modifyPublicationDetails({
                      key: "city",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  );
                }}
              >
                {countries
                  .filter(
                    (obj) =>
                      obj.country ===
                      publicationDetails.publishingAuthors[i].country
                  )[0]
                  ?.city.map((c, index) => (
                    <option key={index} value={c}>
                      {c}
                    </option>
                  ))}
              </select>
            </div>
            <div className="main-form__input-container">
              <label>{t("side")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("side")}
                type="text"
                name="side"
                value={publicationDetails.publishingAuthors[i].side}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "side",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("department")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("department")}
                type="text"
                name="department"
                value={publicationDetails.publishingAuthors[i].department}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "department",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("postal")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("postal")}
                type="text"
                name="postbox"
                value={publicationDetails.publishingAuthors[i].postbox}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "postbox",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("postal_code")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("postal_code")}
                type="text"
                name="postalCode"
                value={publicationDetails.publishingAuthors[i].postalCode}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "postalCode",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("land_line_number")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("land_line_number")}
                type="text"
                name="landlineNumber"
                value={publicationDetails.publishingAuthors[i].landlineNumber}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "landlineNumber",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("phone")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("phone")}
                type="text"
                name="mobileNumber"
                value={publicationDetails.publishingAuthors[i].mobileNumber}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "mobileNumber",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("email")}</label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("email")}
                type="text"
                name="email"
                value={publicationDetails.publishingAuthors[i].email}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "email",
                      val: e.target.value,
                      dynamicFormKey: "publishingAuthors",
                      i,
                    })
                  )
                }
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("attachment")}
              <span>
                (السيرة الذاتية, التفويض للمؤلف الرئيس, و أي مرفقات أخرى)
              </span>
            </label>
            <FilesUploader
              uploadPath={`publishings/${publicationDetails.id}/files`}
              deletePath="publishings/file"
              deleteType="byURL"
              initialAttachments={
                publicationDetails.publishingAuthors[i].attachments
              }
              attachments={(files) => {
                dispatch(
                  modifyPublicationDetails({
                    key: "attachments",
                    val: files,
                    dynamicFormKey: "publishingAuthors",
                    i,
                  })
                );
              }}
            />
          </div>
        </FormPanel>
      ))}

      <div className="text-center">
        {!publicationDetails.isViewMode && (
          <button
            className="btn"
            type="button"
            onClick={() => dispatch(addPublishingAuthor())}
          >
            {`${t("add")} ${t("partner")} ${t("to")} ${t(buttonTitle)}`}
          </button>
        )}
      </div>
    </FormPanel>
  );
};
