import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import Divider from "components/Divider";
import { FormPanel } from "components/FormPanel";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { GetPublicationAttachments } from "services/publications.service";
import { PublicationComments } from "../PublicationComments";
import { ProposalDetails } from "modules/applicant/pages/Publications/AddPublication/common/ProposalDetails";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectPublicationDetails,
  storePublicationDetails,
  toggleViewMode,
} from "store/states/publicationDetailsSlice";
import { ArbitrationForms } from "modules/arbitrator/ArbitrationForms";
import { modifyFormState, selectFormType } from "store/states/formTypeSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";

export const ApplicationAttachments = () => {
  const formType = useAppSelector(selectFormType);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const publishingId = searchParams.get("publishingId") || 0;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchData();
    document
      .getElementsByClassName("main-form__panel form-panel-selector hide")[0]
      ?.classList.remove("hide");
  }, []);

  const fetchData = async () => {
    const res = await GetPublicationAttachments(+publishingId);
    dispatch(storePublicationDetails(res.data));
    dispatch(toggleViewMode(true));
    dispatch(modifyFormState(res.data.applicationId));
  };
  const renderPublicationAttachments = () => {
    if (!publicationDetails) {
      return null;
    }
    return Object.entries(publicationDetails.attachments).map(
      ([key, attachmentsList]) => {
        return (
          <FormPanel key={key} title={t(key)} staticPanel={true}>
            <table className="basic-table">
              <thead>
                <tr>
                  <th>{t("number")}</th>
                  <th>{t("the_attachment")}</th>
                  <th>{t("view")}</th>
                </tr>
              </thead>
              <tbody>
                {(attachmentsList as []).map((a: any, i: number) => (
                  <tr key={a.id}>
                    <td>{i + 1}</td>
                    <td>{a.fileName}</td>
                    <td>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          window.open(baseURL + a.fileUrl, "_blank")
                        }
                      >
                        {t("view")}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </FormPanel>
        );
      }
    );
  };
  return (
    <div className="container main-form">
      <Breadcrumb />
      <ProposalDetails />
      <div
        className={
          publicationDetails.attachments.length > 1 ? "col-2" : "col-1"
        }
      >
        {renderPublicationAttachments()}
      </div>
      <br />
      <br />
      {publicationDetails &&
        (!!publicationDetails.publishingActions?.length ||
          !!publicationDetails.publishingFeedbacks?.length) && (
          <>
            <Divider />
            <PublicationComments
              fieldId={publicationDetails.fieldId.toString()}
              publishingId={+publishingId}
              actions={publicationDetails.publishingActions}
              refreshPublicatoinDetails={() => fetchData()}
            />
          </>
        )}
      {useMemo(
        () => !!publicationDetails.startArbitration && <ArbitrationForms />,
        [publicationDetails.startArbitration]
      )}
    </div>
  );
};
