import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pushAlert, selectAlert } from "store/states/alertSlice";
import { AddCommentOnPublication } from "services/publications.service";

export const Alert = () => {
  const alertConfig = useAppSelector(selectAlert);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const closeAlert = () => {
    dispatch(pushAlert({ show: false }));
  };
  const alertState = useAppSelector(selectAlert);

  const onAccept = () => {
    closeAlert();
    switch (alertState.invoker) {
      case "addPublication":
        AddCommentOnPublication(alertState.onAcceptBody)
          .then((res) => {
            dispatch(
              pushAlert({
                show: true,
                type: "success",
                hasControls: false,
                refetchDataCount: alertState.refetchDataCount + 1,
                msgAR: alertState.successMsgAR,
                msgEN: alertState.successMsgEN,
              })
            );
          })
          .catch(() => {
            dispatch(
              pushAlert({
                show: true,
                msg: "حدث خطأ",
                type: "error",
                hasControls: false,
              })
            );
          })
          .finally(() =>
            setTimeout(() => {
              closeAlert();
            }, 3000)
          );
        break;
    }
  };

  return (
    <div className={`${alertConfig.show ? "show" : ""} alerts`}>
      <div className={`alert ${alertConfig.type || "normal"}`}>
        <p>{i18n.language === "en" ? alertConfig.msgEN && t(alertConfig.msgEN) : alertConfig.msgAR && t(alertConfig.msgAR)}</p>

        <div className="controls">
          {alertConfig.hasControls && (
            <button type="button" onClick={() => onAccept()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          )}
          <button type="button" onClick={closeAlert}>
            <FontAwesomeIcon icon={faX} />
          </button>
        </div>
      </div>
    </div>
  );
};
