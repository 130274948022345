import "./styles/App.scss";

import { RouterProvider } from "react-router-dom";
import React from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { login } from "./store/states/authSlice";

import {
  selectUserDetails,
  storeUserDetails,
} from "./store/states/userDetailsSlice";
import MainRouter from "./router";
import { modifyFormState } from "./store/states/formTypeSlice";
import "react-datepicker/dist/react-datepicker.css";
import i18n from "./i18n";
import { GetProfile } from "services/profile.service";
import { setProfile } from "store/states/profileSlice";
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";

function App() {
  const dispatch = useAppDispatch();
  const { isApplicant } = useAppSelector(selectUserDetails);

  const handleLanguageChange = () => {
    dispatch(modifyFormState(""));
  };

  i18n.on("languageChanged", handleLanguageChange);

  if (localStorage.getItem("user_token")) {
    dispatch(login());
    dispatch(storeUserDetails());
    isApplicant &&
      GetProfile().then((res: any) => {
        dispatch(setProfile(res.data));
      });
  }

  return (
    <React.Suspense fallback={<>Hello World</>}>
      <div className={`App`}>
        <RouterProvider router={MainRouter()} />
      </div>
    </React.Suspense>
  );
}

export default App;
