import axios from "axios";
import { User } from "../types/user";

export const SetPassword = (userCred: Partial<User>, token: string) => {
  return axios.post("account/ChangePassword", {
    password: userCred.password,
    confirmPassword: userCred.password,
    token,
  });
};

export const ResetPassword = (userCred: Partial<User>) => {
  return axios.post("account/ForgetPassword", {
    email: userCred.email,
  });
};

export const Login = (userCred: Partial<User>) => {
  return axios.post("account/login", {
    email: userCred.email,
    password: userCred.password,
  });
};
export const Register = (userCred: User) => {
  return axios.post("account/register", {
    name: userCred.name,
    email: userCred.email,
    password: userCred.password,
    phoneNumber: userCred.phoneNumber,
  });
};
export const Verification = (userCred: Partial<User>) => {
  return axios.patch("account/active", {
    email: userCred.email,
    code: userCred.verification,
  });
};
