import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetArbitrationResults } from "services/arbitrator.service";
import { ArbitrationForms } from "modules/arbitrator/ArbitrationForms";
import { useAppDispatch } from "store/hooks";
import { storePublicationDetails } from "store/states/publicationDetailsSlice";

const ArbitrationResults = () => {
  const dispatch = useAppDispatch();
  const [formsData, setFormsData] = useState<any>([]);
  const [currentArbitratorIdx, setCurrentArbitratorIdx] = useState<any>(0);
  const params = useParams();

  useEffect(() => {
    params.id &&
      GetArbitrationResults(params.id).then((res) => {
        setFormsData(res.data);
      });
  }, []);

  useEffect(() => {
    dispatch(
      storePublicationDetails({
        arbitrationForms: formsData[currentArbitratorIdx]?.forms || [],
      })
    );
  }, [dispatch, formsData, currentArbitratorIdx]);

  useEffect(() => {
    setCurrentArbitratorIdx(0);
  }, [params.id]);

  return (
    <div className="container">
      <Breadcrumb>
        {!!formsData.length && (
          <select
            className="filter-select"
            name=""
            id=""
            onChange={(e) => setCurrentArbitratorIdx(e.target.value)}
          >
            {formsData.map((f: any, idx: number) => (
              <option key={idx} value={idx}>
                {f.userArbitratorName}
              </option>
            ))}
          </select>
        )}
      </Breadcrumb>
      <ArbitrationForms key={currentArbitratorIdx} />
    </div>
  );
};

export default ArbitrationResults;
