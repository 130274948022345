import FilesUploader from "components/FilesUploader";
import { Popup } from "components/Popup/Popup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pushAlert, selectAlert } from "store/states/alertSlice";

import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { Attachment } from "types/publication";

interface CommonPopupProps {
  setShowCommonPopup: (type: boolean) => void;
  showCommonPopup: boolean;
  userAction: any;
  attachments?: (files: Attachment[]) => void;
}
export const CommonPopup = (props: CommonPopupProps) => {
  const [comment, setComment] = useState("");
  const [attachmentsLength, setAttachmentsLength] = useState(0);
  const dispatch = useAppDispatch();
  const { onAcceptBody } = useAppSelector(selectAlert);
  const { setShowCommonPopup, showCommonPopup, userAction, attachments } =
    props;

  const { t, i18n } = useTranslation();
  const { role } = useAppSelector(selectUserDetails);
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const closePopup = () => {
    setShowCommonPopup(false);
    pushAlert({
      onAcceptBody: { ...onAcceptBody, comment: "" },
    });
  };
  return (
    <Popup
      onClose={() => closePopup()}
      showPopup={showCommonPopup}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowCommonPopup(false);
          dispatch(
            pushAlert({
              show: true,
            })
          );
          closePopup();
        }}
        className="modern-form"
      >
        <h2> {`${t("comments_for")}${t(role)}`}</h2>

        <div className="main-form__input-container">
          <textarea
            placeholder={t("Write_comments")}
            name="comment"
            onChange={(e) => {
              dispatch(
                pushAlert({
                  onAcceptBody: { ...onAcceptBody, comment: e.target.value },
                })
              );
              setComment(e.target.value);
            }}
          ></textarea>
          {["Attachments", "Attachment"].includes(userAction.type) && (
            <FilesUploader
              uploadPath={`PublishingFeedbacks/Publishings/${publicationDetails.id}/files`}
              deletePath={`PublishingFeedbacks/file`}
              forceShow={true}
              singleUpload={userAction.type === "Attachment"}
              deleteType="byURL"
              attachments={(files) => {
                if (attachments) attachments(files);
                setAttachmentsLength(files.length);
              }}
            />
          )}
        </div>
        <div className="modern-form__footer">
          <button disabled={!(comment.length || attachmentsLength)}>
            {i18n.language === "en" ? userAction.labelEN : userAction.labelAR}
          </button>
        </div>
      </form>
    </Popup>
  );
};
