import FilesUploader from "components/FilesUploader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormPanel } from "../../../../../components/FormPanel";
import { useAppDispatch, useAppSelector } from "store/hooks";
import countries from "../../../../../assets/countries.json";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { InfoBookAuthor } from "./common/InfoBookAuthor";
import InputNumber from "components/InputNumber";
import DatePicker from "react-datepicker";
import convertDateToFormat from "../../../../../utils/convertDateToFormat";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
export const ScientificLetter = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <>
      <FormPanel title={t("info_origin_scientific")}>
        <div className="col-2">
          <div className="main-form__input-container">
            <label>
              {t("msg_title_msg_lang")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("msg_title_msg_lang")}
              type="text"
              name="titleChosenLanguage"
              value={publicationDetails.titleChosenLanguage}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("msg_title_ar")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("msg_title_ar")}
              type="text"
              name="arabicTitle"
              value={publicationDetails.arabicTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="col-2">
          <div className="main-form__input-container">
            <label>
              {t("academic_certificate_lang")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("academic_certificate_lang")}
              type="text"
              name="certificateName"
              value={publicationDetails.certificateName}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("name_scientific_degree_ar")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("name_scientific_degree_ar")}
              type="text"
              name="arabicCertificateName"
              value={publicationDetails.arabicCertificateName}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>

        <div className="col-5">
          <div className="main-form__input-container">
            <label>
              {t("universtly")} {t("giving_msg")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("universtly")}
              type="text"
              name="university"
              value={publicationDetails.university}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("country")}
              <span className="required-input">*</span>
            </label>
            <select
              disabled={publicationDetails.isViewMode}
              placeholder={t("country")}
              name="country"
              value={publicationDetails.country}
              onChange={(e) => {
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                );
                setSelectedCountry(e.target.value);
              }}
            >
              {countries.map((e, index) => (
                <option key={index}>{e.country}</option>
              ))}
            </select>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("city")}
              <span className="required-input">*</span>
            </label>
            <select
              disabled={publicationDetails.isViewMode}
              placeholder={t("city")}
              name="city"
              value={publicationDetails.city}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            >
              {countries
                .filter((obj) => obj.country === selectedCountry)[0]
                ?.city.map((c, index) => (
                  <option key={index} value={c}>
                    {c}
                  </option>
                ))}
            </select>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("grade_date")}
              <span className="required-input">*</span>
            </label>
            <DatePicker
              disabled={publicationDetails.isViewMode}
              placeholderText={t("grade_date")}
              name="graduationDate"
              value={convertDateToFormat(
                publicationDetails.graduationDate || new Date()
              )}
              onChange={(date) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "graduationDate",
                    val: date,
                  })
                )
              }
              dateFormat={"dd/MM/yyyy"}
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("num_page_msg")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("num_page_msg")}
              name="pagesNumber"
              value={publicationDetails.pagesNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("msg_content")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("msg_content")}
            name="topic"
            value={publicationDetails.topic}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("msg_content_des")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("msg_content_des")}
            name="briefSummary"
            value={publicationDetails.briefSummary}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("msg_important")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("msg_important")}
            name="importance"
            value={publicationDetails.importance}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>{t("copy_original_msg")}</label>
          <FilesUploader
            uploadPath={`Publishings/${publicationDetails.id}/attachments`}
            deletePath="publishings/attachments"
            attachmentType="Letter_Copy"
            initialAttachments={publicationDetails.attachments["Letter_Copy"]}
          />
        </div>
        <div className="main-form__input-container">
          <label>{t("Recommendation_arbitrators_msg")}</label>
          <FilesUploader
            uploadPath={`Publishings/${publicationDetails.id}/attachments`}
            deletePath="publishings/attachments"
            attachmentType="Letter_Recommendation"
            initialAttachments={
              publicationDetails.attachments["Letter_Recommendation"]
            }
          />
        </div>
      </FormPanel>
      <InfoBookAuthor />
      <AcknowledgmentRightProperty />
    </>
  );
};
