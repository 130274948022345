import axios, { AxiosInstance } from "axios";
import React, { DragEvent, useEffect, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { DeleteAttachment } from "services/attachments.service";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { useAppSelector } from "store/hooks";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { Attachment } from "types/publication";

interface FileUploadProps {
  uploadPath: string;
  deletePath?: string;
  deleteType?: "byId" | "byURL";
  onUpload?: (files: File[]) => void;
  singleUpload?: boolean;
  attachmentType?:
    | "Draft_Book_Copy"
    | "Draft_Chapter_Copy"
    | "Attachment_COPY"
    | "Search_Origin"
    | "Letter_Copy"
    | "Letter_Recommendation"
    | "Attachments_Type";
  forceShow?: boolean;
  forceHide?: boolean;
  initialAttachments?: Attachment[];
  attachments?: (files: Attachment[]) => void;
  allowedExtensions?: string[];
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "api/",
  timeout: 5000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("user_token")}`,
  },
});
const FilesUploader = (props: React.PropsWithChildren<FileUploadProps>) => {
  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { isApplicant } = useAppSelector(selectUserDetails);

  const {
    deletePath,
    uploadPath,
    deleteType = "byId",
    singleUpload,
    attachmentType,
    forceShow = false,
    forceHide = false,
    initialAttachments,
    attachments,
    onUpload,
    allowedExtensions = [
      ".docx",
      " .doc",
      " .pdf",
      " .pptx",
      " .ppt",
      " .xlsx",
      " .xls",
      " .indd ",
      " .psd ",
      " .mobi ",
      "  .epub ",
      " .rtf",
    ],
  } = props;
  const [uploadedFilesLinks, setUploadedFilesLinks] = useState<Attachment[]>(
    []
  );
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    attachments && attachments(uploadedFilesLinks);
  }, [uploadedFilesLinks.length, attachments?.length]);

  useEffect(() => {
    setUploadedFilesLinks(initialAttachments || []);
  }, [initialAttachments]);

  // handle drag events
  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileUpload(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const handleFileUpload = (selectedFiles: FileList) => {
    const files = new FormData();
    Array.from(selectedFiles).forEach((file, i) => {
      files.append("files", file, file.name);
    });
    files.append("type", attachmentType || "string");
    axiosInstance.post(uploadPath || "publishings/files", files).then((res) => {
      setUploadedFilesLinks([...uploadedFilesLinks, ...res.data]);
      onUpload && onUpload([...uploadedFilesLinks, ...res.data]);
    });
  };

  const deleteAttachmentById = async (id: number) => {
    await DeleteAttachment(`${deletePath}/${id}`);
    const updatedList = uploadedFilesLinks.filter(
      (link: any) => link.id !== id
    );
    setUploadedFilesLinks(updatedList);
  };
  const deleteAttachmentByURL = async (url: string) => {
    await DeleteAttachment(`${deletePath}?url=${url}`);
    const updatedList = uploadedFilesLinks.filter(
      (link: any) => link.fileUrl !== url
    );
    setUploadedFilesLinks(updatedList);
  };

  const showUploader =
    !forceHide &&
    (!(publicationDetails.isViewMode || isApplicant) ||
      (!publicationDetails.isViewMode && isApplicant) ||
      forceShow);
  return (
    <div
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      {!!showUploader && !(singleUpload && uploadedFilesLinks.length === 1) && (
        <>
          <input
            autoComplete="off"
            ref={inputRef}
            accept={allowedExtensions.join(",")}
            type="file"
            id="input-file-upload"
            multiple={!singleUpload ?? true}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <div>
              <p>{t("Drag_and_drop_your_file_here_or")}</p>
              <button
                className="upload-button"
                onClick={onButtonClick}
                type="button"
              >
                {t("Upload_File")}
              </button>
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </>
      )}

      {!!uploadedFilesLinks?.length && (
        <ul id="uploaded-files">
          {uploadedFilesLinks.map((link: any, i: number) => (
            <li key={link.fileUrl}>
              <a href={baseURL + link.fileUrl} target="_blank" rel="noreferrer">
                {i + 1}- {link.fileName}
              </a>

              {!!showUploader && deletePath && (
                <button
                  className="btn delete"
                  type="button"
                  onClick={() =>
                    deleteType === "byId"
                      ? deleteAttachmentById(link.id)
                      : deleteAttachmentByURL(link.fileUrl)
                  }
                >
                  {t("remove")}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilesUploader;
