import { useEffect, useState } from "react";
import { Breadcrumb } from "../../../../components/Breadcrumb/Breadcrumb";
import { Popup } from "../../../../components/Popup/Popup";
import { useTranslation } from "react-i18next";
import {
  AddArbitrator,
  GetArbitrators,
  RemoveArbitrator,
  UpdateArbitrator,
} from "../../../../services/arbitrator.service";
import { Arbitrator } from "../../../../types/arbitrator";
import countries from "../../../../assets/countries.json";
import { GetFields } from "services/fields.service";
import {
  AddProposedArbitrators,
  DeleteProposedArbitrators,
  GetProposedArbitrators,
  UpdateProposedArbitrators,
} from "services/proposed_arbitrators.service";
import { GetSpecializations } from "services/specializations.service";
import { GetReports } from "services/report.service";
export const Arbitrators = () => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("Saudi Arabia");
  const [selectedCity, setSelectedCity] = useState<any>("Riyadh");
  const pathCondation = window.location.pathname.slice(1).split("/")[1];
  const arbitratorTemp: Partial<Arbitrator> = {
    userEmail: "",
    userPhoneNumber: "",
    fullNameArabic: "",
    fullNameEnglish: "",
    destinationCollege: "",
    destinationSection: "",
    degree: "",
    exactScientificSpecialization: "",
    scientificSpecialization: "1",
    scientificRank: "",
    poBox: "",
    postalCode: "",
    city: selectedCity,
    country: selectedCountry,
    userFieldId: "",
  };
  const [arbitrator, setArbitrator] =
    useState<Partial<Arbitrator>>(arbitratorTemp);
  const [arbitrators, setArbitrators] = useState<Arbitrator[]>([]);
  const SelectedproposalArbitratorTemp = {
    id: 0,
    doerId: 0,
    isActive: true,
    isDeleted: true,
    email: "",
    phoneNumber: "",
    fullNameArabic: "",
    fullNameEnglish: "",
    destinationCollege: "",
    destinationSection: "",
    degree: "",
    scientificSpecialization: "1",
    exactScientificSpecialization: "",
    scientificRank: "",
    poBox: "",
    postalCode: "",
    city: selectedCity,
    country: selectedCountry,
    userId: 0,
    fieldId: 0,
  };
  const [selectedProposalArbitrators, setSelectedProposalArbitrators] =
    useState(SelectedproposalArbitratorTemp);
  const [specializationsList, setSpecializationsList] = useState<any>([]);
  const [proposalArbitrators, setProposalArbitrators] = useState([]);
  useEffect(() => {
    if (pathCondation === "arbitrators") {
      getArbitrators();
    } else if (pathCondation === "proposed_arbitrators") {
      getProposedArbitrators();
    }
    getFields();
    GetSpecializations().then((res) => {
      setSpecializationsList(res.data);
    });
  }, [pathCondation]);

  const getArbitrators = () => {
    GetArbitrators().then((response) => {
      setArbitrators(response.data);
      setArbitrator(arbitratorTemp);
      setShowAddEditPopup(false);
      setShowRemovePopup(false);
    });
  };
  const getProposedArbitrators = () => {
    GetProposedArbitrators().then((response) => {
      setProposalArbitrators(response.data);
      setSelectedProposalArbitrators({
        ...selectedProposalArbitrators,
        fieldId: response.data[0]["id"],
      });
      setShowAddEditPopup(false);
      setShowRemovePopup(false);
    });
  };

  const getFields = () => {
    GetFields().then((response) => {
      setFields(response.data);
    });
  };

  const renderArbitratorsArray = () => {
    return arbitrators.map((arbitrator, i) => (
      <tr key={arbitrator.id}>
        <td>{i + 1}</td>
        <td>{arbitrator.fullNameArabic}</td>
        <td>{arbitrator.scientificSpecialization}</td>
        <td>{arbitrator.userFieldName}</td>
        <td>
          <button
            className="btn"
            onClick={() => {
              setIsEditMode(false);
              setIsViewMode(true);
              setShowAddEditPopup(true);
              setArbitrator(arbitrator);
            }}
          >
            {t("view")}
          </button>
        </td>
        <td>
          <button
            className="btn edit"
            onClick={() => {
              setShowAddEditPopup(true);
              setIsEditMode(true);
              setIsViewMode(false);
              setArbitrator(arbitrator);
            }}
          >
            {t("Edit")}
          </button>
        </td>
        <td>
          <button
            className="btn delete"
            onClick={() => {
              setShowRemovePopup(true);
              setArbitrator(arbitrator);
            }}
          >
            {t("delete")}
          </button>
        </td>
      </tr>
    ));
  };

  const renderProposalArbitratorsArray = () => {
    return proposalArbitrators.map((ele: any, i) => (
      <tr key={ele.id}>
        <td>{i + 1}</td>
        <td>{ele.fullNameArabic}</td>
        <td>{ele.scientificSpecialization}</td>
        <td>{ele.fieldName}</td>
        <td>
          <button
            className="btn"
            onClick={() => {
              setIsEditMode(false);
              setIsViewMode(true);
              setShowAddEditPopup(true);
              setSelectedProposalArbitrators(selectedProposalArbitrators);
              setSelectedProposalArbitrators(ele);
            }}
          >
            {t("view")}
          </button>
        </td>
        <td>
          <button
            className="btn edit"
            onClick={() => {
              setIsEditMode(true);
              setShowAddEditPopup(true);
              setIsViewMode(false);
              setSelectedProposalArbitrators(selectedProposalArbitrators);
              setSelectedProposalArbitrators(ele);
            }}
          >
            {t("Edit")}
          </button>
        </td>
        <td>
          <button
            className="btn delete"
            onClick={() => {
              setSelectedProposalArbitrators(selectedProposalArbitrators);
              setShowRemovePopup(true);
              setSelectedProposalArbitrators(ele);
            }}
          >
            {t("delete")}
          </button>
        </td>
      </tr>
    ));
  };

  const renderArbitrators = () => {
    return pathCondation === "arbitrators"
      ? renderArbitratorsArray()
      : renderProposalArbitratorsArray();
  };

  const renderConditionFormButton = () => {
    const proposalValues = Object.values(selectedProposalArbitrators);
    const isAnyproposalValueEmpty = proposalValues.some(
      (value) => value === ""
    );

    const Values = Object.values(arbitrator);
    const isAnyValueEmpty = Values.some((value) => value === "");

    if (isEditMode) {
      return (
        <button
          disabled={
            pathCondation === "arbitrators"
              ? isAnyValueEmpty
              : isAnyproposalValueEmpty
          }
        >
          {t("Edit")}
        </button>
      );
    } else if (isViewMode) {
      return <button>{t("close")}</button>;
    } else {
      return (
        <button
          disabled={
            pathCondation === "arbitrators"
              ? isAnyValueEmpty
              : isAnyproposalValueEmpty
          }
        >
          {t("add")}
        </button>
      );
    }
  };

  // Form Listen & Submit
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (pathCondation === "arbitrators") {
      setArbitrator({
        ...arbitrator,
        [event.target.name]: event.target.value,
      });
    } else if (pathCondation === "proposed_arbitrators") {
      setSelectedProposalArbitrators({
        ...selectedProposalArbitrators,
        [event.target.name]: event.target.value,
      });
    }
  };

  const conditionalSubmit = (event: any) => {
    event.preventDefault();
    if (isEditMode) {
      if (pathCondation === "arbitrators") {
        UpdateArbitrator(arbitrator.id, arbitrator).then(() => {
          getArbitrators();
        });
      } else if (pathCondation === "proposed_arbitrators") {
        UpdateProposedArbitrators(
          selectedProposalArbitrators.id,
          selectedProposalArbitrators
        ).then(() => {
          getProposedArbitrators();
          setSelectedProposalArbitrators(selectedProposalArbitrators);
        });
      }
    } else if (isViewMode) {
      setShowAddEditPopup(false);
    } else {
      if (pathCondation === "arbitrators") {
        AddArbitrator(arbitrator).then(() => {
          getArbitrators();
        });
      } else if (pathCondation === "proposed_arbitrators") {
        AddProposedArbitrators(selectedProposalArbitrators).then(() => {
          getProposedArbitrators();
        });
      }
    }
  };

  return (
    <div className="container">
      <Breadcrumb>
        <div className="col-2">
          <button
            onClick={() => {
              setArbitrator({
                ...arbitratorTemp,
                userFieldId: fields[0]["id"],
              });
              setSelectedProposalArbitrators({
                ...SelectedproposalArbitratorTemp,
                fieldId: fields[0]["id"],
              });
              setIsEditMode(false);
              setIsViewMode(false);
              setShowAddEditPopup(true);
            }}
          >
            {t("add_arbitrators")}
          </button>
          <button
            onClick={() =>
              GetReports("arbitrators/export", {}, "Arbitrators.xlsx")
            }
          >
            {t("export_arbitrators")}
          </button>
        </div>
      </Breadcrumb>
      <div className="table-responsive">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("list")}</th>
              <th> {t("arbitrator_name")}</th>
              <th>{t("Scientific_specialization")} </th>
              <th>{t("field")}</th>
              <th colSpan={3}></th>
            </tr>
          </thead>
          <tbody>
            {arbitrators.length || proposalArbitrators.length ? (
              renderArbitrators()
            ) : (
              <tr>
                <td colSpan={12}>{t("no_data_show")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowAddEditPopup(false);
          setSelectedCountry("Saudi Arabia");
          setSelectedCity("Riyadh");
        }}
        showPopup={showAddEditPopup}
        widePopup={true}
      >
        <form
          autoComplete="off"
          onSubmit={conditionalSubmit}
          className="modern-form"
        >
          <h2>{t("arbitrator")}</h2>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("full_name_arabic")}</label>
              <input
                autoComplete="off"
                type="text"
                name="fullNameArabic"
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.fullNameArabic
                    : selectedProposalArbitrators.fullNameArabic
                }
                onChange={handleFormChange}
                placeholder={t("full_name_arabic")}
                autoFocus
                disabled={isViewMode}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("full_name_en")}</label>
              <input
                autoComplete="off"
                type="text"
                name="fullNameEnglish"
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.fullNameEnglish
                    : selectedProposalArbitrators.fullNameEnglish
                }
                onChange={handleFormChange}
                placeholder={t("full_name_en")}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("phone")}</label>
              <input
                autoComplete="off"
                type="text"
                name={
                  pathCondation === "arbitrators"
                    ? "userPhoneNumber"
                    : "phoneNumber"
                }
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.userPhoneNumber
                    : selectedProposalArbitrators.phoneNumber
                }
                onChange={handleFormChange}
                placeholder={t("phone")}
              />
            </div>
            <div className="modern-form__input-container">
              <label> {t("email")}</label>
              <input
                autoComplete="off"
                type="text"
                name={pathCondation === "arbitrators" ? "userEmail" : "email"}
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.userEmail
                    : selectedProposalArbitrators.email
                }
                onChange={handleFormChange}
                placeholder={t("email")}
              />
            </div>
          </div>
          <div className="col-2">
            {pathCondation === "arbitrators" && (
              <div className="modern-form__input-container">
                <label> {t("password")}</label>
                <input
                  autoComplete="off"
                  type="password"
                  name="password"
                  disabled={isViewMode}
                  value={arbitrator.password}
                  onChange={handleFormChange}
                  placeholder={t("password")}
                />
              </div>
            )}
          </div>
          <div className="col-3">
            <div className="modern-form__input-container">
              <label>{t("facluty")}</label>
              <input
                autoComplete="off"
                name="destinationCollege"
                disabled={isViewMode}
                placeholder={t("facluty")}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.destinationCollege
                    : selectedProposalArbitrators.destinationCollege
                }
                onChange={handleFormChange}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("section")}</label>
              <input
                autoComplete="off"
                name="destinationSection"
                disabled={isViewMode}
                placeholder={t("section")}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.destinationSection
                    : selectedProposalArbitrators.destinationSection
                }
                onChange={handleFormChange}
                id=""
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("academic_Qualifications")}</label>
              <input
                autoComplete="off"
                name="degree"
                placeholder={t("academic_Qualifications")}
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.degree
                    : selectedProposalArbitrators.degree
                }
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("exact_scientific_specialization")}</label>
              <input
                autoComplete="off"
                name="exactScientificSpecialization"
                placeholder={t("exact_scientific_specialization")}
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.exactScientificSpecialization
                    : selectedProposalArbitrators.exactScientificSpecialization
                }
                onChange={handleFormChange}
                id=""
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("degree")}</label>
              <input
                autoComplete="off"
                name="scientificRank"
                disabled={isViewMode}
                placeholder={t("degree")}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.scientificRank
                    : selectedProposalArbitrators.scientificRank
                }
                onChange={handleFormChange}
                id=""
              />
            </div>
          </div>
          <div className="col-4">
            <div className="modern-form__input-container">
              <label>{t("postal")}</label>
              <input
                autoComplete="off"
                name="poBox"
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.poBox
                    : selectedProposalArbitrators.poBox
                }
                onChange={handleFormChange}
                type="text"
                placeholder={t("postal")}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("postal_code")}</label>
              <input
                autoComplete="off"
                name="postalCode"
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.postalCode
                    : selectedProposalArbitrators.postalCode
                }
                onChange={handleFormChange}
                type="text"
                placeholder={t("postal_code")}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("country")}</label>
              <select
                name="country"
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.country
                    : selectedProposalArbitrators.country
                }
                onChange={(e) => {
                  handleFormChange(e);
                  const selectedCountry = e.target.value;
                  setSelectedCountry(selectedCountry);

                  const selectedCity = countries.filter(
                    (obj) => obj.country === selectedCountry
                  )[0].city[0];
                  setSelectedCity(selectedCity);
                  setArbitrator((prevArbitrator) => ({
                    ...prevArbitrator,
                    country: selectedCountry,
                    city: selectedCity,
                  }));
                  setSelectedProposalArbitrators(
                    (prevSelectedProposalArbitrators) => ({
                      ...prevSelectedProposalArbitrators,
                      country: selectedCountry,
                      city: selectedCity,
                    })
                  );
                }}
              >
                {countries.map((e, i) => (
                  <option key={i}>{e.country}</option>
                ))}
              </select>
            </div>
            <div className="modern-form__input-container">
              <label>{t("city")}</label>
              <select
                name="city"
                disabled={isViewMode}
                placeholder={t("city")}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.city
                    : selectedProposalArbitrators.city
                }
                onChange={(e) => {
                  handleFormChange(e);
                  const selectedCity = e.target.value;
                  setSelectedCity(selectedCity);

                  setArbitrator((prevArbitrator) => ({
                    ...prevArbitrator,
                    city: selectedCity,
                  }));
                  setSelectedProposalArbitrators(
                    (prevSelectedProposalArbitrators) => ({
                      ...prevSelectedProposalArbitrators,
                      country: selectedCountry,
                      city: selectedCity,
                    })
                  );
                }}
              >
                {countries
                  .filter((obj) => obj.country === selectedCountry)[0]
                  ?.city.map((c, index) => (
                    <option key={index} value={c}>
                      {c}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("field")}</label>
              <select
                name={
                  pathCondation === "arbitrators" ? "userFieldId" : "fieldId"
                }
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.userFieldId
                    : selectedProposalArbitrators.fieldId
                }
                disabled={isViewMode}
                onChange={handleFormChange}
              >
                {fields.map((field) => (
                  <option key={field["id"]} value={field["id"]}>
                    {field["name"]}
                  </option>
                ))}
              </select>
            </div>
            <div className="modern-form__input-container">
              <label>{t("Scientific_specialization")}</label>
              <select
                name="scientificSpecialization"
                disabled={isViewMode}
                value={
                  pathCondation === "arbitrators"
                    ? arbitrator.scientificSpecialization
                    : selectedProposalArbitrators.scientificSpecialization
                }
                onChange={handleFormChange}
                id=""
              >
                {specializationsList.map((specialization: any) => (
                  <option
                    key={specialization["id"]}
                    value={specialization["id"]}
                  >
                    {specialization["name"]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modern-form__footer">
            {renderConditionFormButton()}
          </div>
        </form>
      </Popup>
      <Popup
        onClose={() => {
          setShowRemovePopup(false);
        }}
        showPopup={showRemovePopup}
      >
        <div className="popup__remove">
          <p>
            {t("delete_arbitrators_surely")}
            <b>
              {pathCondation === "arbitrators"
                ? arbitrator.fullNameArabic
                : selectedProposalArbitrators.fullNameArabic}
            </b>
          </p>
          <div>
            <button
              className="btn normal"
              onClick={() => {
                setShowRemovePopup(false);
              }}
            >
              {t("close")}
            </button>
            <button
              className="btn delete"
              onClick={() =>
                pathCondation === "arbitrators"
                  ? RemoveArbitrator(arbitrator.id ?? 0, arbitrator).then(() =>
                      getArbitrators()
                    )
                  : DeleteProposedArbitrators(
                      selectedProposalArbitrators.id ?? 0,
                      selectedProposalArbitrators
                    ).then(() => getProposedArbitrators())
              }
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
