import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false, // Remove legend
    },
    filler: {
      propagate: true,
    },
    shadow: {
      enabled: true,
      color: "rgba(0, 0, 0, 0.2)", // Shadow color
      blur: 10, // Shadow blur value
      offsetY: 10, // Vertical offset of the shadow
    },
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  scales: {
    x: {
      //   display: false, // Remove x-axis
    },
    y: {
      //   display: false, // Remove y-axis
      beginAtZero: true,
    },
  },
};

const LinesCard = (props: {
  data: Array<Array<number>>;
  mainTitle: string;
  specializationName: any;
}) => {
  const { data, mainTitle, specializationName } = props;
  const colors = ["#ffeb3b", "#f9943b", "#65acf0"];
  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: data.map((item, index) => {
      return {
        label: specializationName[index],
        data: item,
        borderColor: colors[index],
        borderWidth: 1.5,
        fill: true,
        pointRadius: 1,
      };
    }),
  };
  return (
    <div className="analysis-card lines">
      <h4>{mainTitle}</h4>
      <div
        className="lines-content"
        style={{ position: "relative", height: "200px", width: "100%" }}
      >
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export default LinesCard;
