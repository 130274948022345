import { FormPanel } from "components/FormPanel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import {
  addPublishingCompetitor,
  modifyPublicationDetails,
  removePublishingCompetitor,
  selectPublicationDetails,
} from "../../../../../../store/states/publicationDetailsSlice";
import InputNumber from "components/InputNumber";
import DatePicker from "react-datepicker";
import convertDateToFormat from "../../../../../../utils/convertDateToFormat";
export const ContentReport = () => {
  const dispatch = useAppDispatch();
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel title={`${t("Nature_report")}${formType} ${t("and_isotopes")}`}>
      <div className="main-form__radio-container">
        <label>{`${t("posted_before_question")} ${formType} ${t(
          "or_parts_been_previously"
        )}`}</label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isPostedBefore"
            value="1"
            onChange={(e) => {
              dispatch(
                modifyPublicationDetails({
                  key: "isPostedBefore",
                  val: e.target.value,
                })
              );
              ["previousPublisherName", "previousPublishingDate"].forEach(
                (key) => dispatch(modifyPublicationDetails({ key, val: "" }))
              );
              [
                "previousPublishingEditionsNumber",
                "previousPublishingDemandVolume",
              ].forEach((key) =>
                dispatch(modifyPublicationDetails({ key, val: 0 }))
              );
            }}
            checked={!!+publicationDetails.isPostedBefore}
            type="radio"
          />

          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isPostedBefore"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "isPostedBefore",
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.isPostedBefore}
            type="radio"
          />
          {t("no")}
        </label>
      </div>
      {!!+publicationDetails.isPostedBefore && (
        <>
          <div className="col-5">
            <div className="main-form__input-container">
              <label>
                {t("Publisher_name")} <span className="required-input">*</span>
              </label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                placeholder={t("Publisher_name")}
                type="text"
                name="previousPublisherName"
                value={publicationDetails.previousPublisherName}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "previousPublisherName",
                      val: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>
                {t("Year_Publication")}
                <span className="required-input">*</span>
              </label>

              <DatePicker
                disabled={publicationDetails.isViewMode}
                placeholderText={t("Year_Publication")}
                name="previousPublishingDate"
                value={convertDateToFormat(
                  publicationDetails.previousPublishingDate || new Date()
                )}
                onChange={(date) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "previousPublishingDate",
                      val: date,
                    })
                  )
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>
                {t("end_date_copyright")}
                <span className="required-input">*</span>
              </label>
              <DatePicker
                disabled={publicationDetails.isViewMode}
                placeholderText={t("end_date_copyright")}
                name="previousPublishingEndDate"
                value={convertDateToFormat(
                  publicationDetails.previousPublishingEndDate || new Date()
                )}
                onChange={(date) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "previousPublishingEndDate",
                      val: date,
                    })
                  )
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>
                {t("editions_number_for")}
                {formType}
                <span className="required-input">*</span>
              </label>
              <InputNumber
                disabled={publicationDetails.isViewMode}
                placeholder={`${t("editions_number_for")}${formType}`}
                name="previousPublishingEditionsNumber"
                value={publicationDetails.previousPublishingEditionsNumber}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "previousPublishingEditionsNumber",
                      val: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>
                {t("volume_requests_post")}
                <span className="required-input">*</span>
              </label>
              <InputNumber
                disabled={publicationDetails.isViewMode}
                placeholder={t("volume_requests_post")}
                name="previousPublishingDemandVolume"
                value={publicationDetails.previousPublishingDemandVolume}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "previousPublishingDemandVolume",
                      val: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("reasons_Republishing")}{" "}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={t("reasons_Republishing")}
              name="reasonsRepublishing"
              value={publicationDetails.reasonsRepublishing}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "reasonsRepublishing",
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
        </>
      )}

      {formType !== t("book_translation") && (
        <div className="main-form__radio-container">
          <label>{t("version_provided_augmented")}</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="isRevisedOrExpanded"
              value="1"
              onChange={(e) => {
                dispatch(
                  modifyPublicationDetails({
                    key: "isRevisedOrExpanded",
                    val: e.target.value,
                  })
                );
                dispatch(
                  modifyPublicationDetails({
                    key: "revisedOrExpandedDetails",
                    val: "",
                  })
                );
              }}
              checked={!!+publicationDetails.isRevisedOrExpanded}
              type="radio"
            />
            {t("yes")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="isRevisedOrExpanded"
              value="0"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "isRevisedOrExpanded",
                    val: e.target.value,
                  })
                )
              }
              checked={!+publicationDetails.isRevisedOrExpanded}
              type="radio"
            />
            {t("no")}
          </label>
        </div>
      )}
      {!!+publicationDetails.isRevisedOrExpanded && (
        <div className="main-form__input-container">
          <label>
            {t("rate_increase")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("rate_increase")}
            name="revisedOrExpandedDetails"
            value={publicationDetails.revisedOrExpandedDetails}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "revisedOrExpandedDetails",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      )}

      <div className="main-form__radio-container">
        <label>
          {t("is_the")}
          {formType} {t("Similar_store")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasMatch"
            value="1"
            onChange={(e) => {
              dispatch(
                modifyPublicationDetails({
                  key: "hasMatch",
                  val: e.target.value,
                })
              );
              dispatch(removePublishingCompetitor({ removeId: 0 }));
            }}
            checked={!!+publicationDetails.hasMatch}
            type="radio"
          />
          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasMatch"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "hasMatch",
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.hasMatch}
            type="radio"
          />
          {t("no")}
        </label>
      </div>
      {!!+publicationDetails.hasMatch && (
        <>
          {publicationDetails.publishingCompetitors.map((_: any, i: number) => (
            <FormPanel
              key={i}
              staticPanel={true}
              title={`${t("similar_num")} "${i + 1}"`}
              headerButton={
                i ? (
                  <button
                    className="btn delete"
                    type="button"
                    onClick={(event) =>
                      dispatch(removePublishingCompetitor({ removeId: i }))
                    }
                  >
                    {t("delete")}
                  </button>
                ) : (
                  ""
                )
              }
            >
              <div className="col-4">
                <div className="main-form__input-container">
                  <label>
                    {t("title")} {formType}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    placeholder={`${t("title_for")}${formType}`}
                    type="text"
                    name="title"
                    value={publicationDetails.publishingCompetitors[i].title}
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "title",
                          val: e.target.value,
                          dynamicFormKey: "publishingCompetitors",
                          i,
                        })
                      )
                    }
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("publisher")}
                    <span className="required-input">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    disabled={publicationDetails.isViewMode}
                    placeholder={t("publisher")}
                    type="text"
                    name="publisher"
                    value={
                      publicationDetails.publishingCompetitors[i].publisher
                    }
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "publisher",
                          val: e.target.value,
                          dynamicFormKey: "publishingCompetitors",
                          i,
                        })
                      )
                    }
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("Publisher_date")}{" "}
                    <span className="required-input">*</span>
                  </label>

                  <DatePicker
                    disabled={publicationDetails.isViewMode}
                    placeholderText={t("Publisher_date")}
                    name="publishingDate"
                    value={convertDateToFormat(
                      publicationDetails.publishingCompetitors[i]
                        .publishingDate || new Date()
                    )}
                    onChange={(date) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "publishingDate",
                          val: date,
                          dynamicFormKey: "publishingCompetitors",
                          i,
                        })
                      )
                    }
                    dateFormat={"dd/MM/yyyy"}
                  />
                </div>
                <div className="main-form__input-container">
                  <label>
                    {t("editions_number_for")}
                    {formType}
                    <span className="required-input">*</span>
                  </label>
                  <InputNumber
                    disabled={publicationDetails.isViewMode}
                    placeholder={`${t("editions_number_for")}${formType}`}
                    name="editionsNumber"
                    value={
                      publicationDetails.publishingCompetitors[i].editionsNumber
                    }
                    onChange={(e) =>
                      dispatch(
                        modifyPublicationDetails({
                          key: "editionsNumber",
                          val: e.target.value,
                          dynamicFormKey: "publishingCompetitors",
                          i,
                        })
                      )
                    }
                  />
                </div>
              </div>
              <div className="main-form__input-container">
                <label>
                  {t("What_characterizes_the")}
                  {formType} {t("Proposed_publication_this")}
                  {formType}؟<span className="required-input">*</span>
                </label>
                <textarea
                  disabled={publicationDetails.isViewMode}
                  placeholder={`${t("What_characterizes_the")} ${formType} ${t(
                    "Proposed_publication_this"
                  )} ${formType}؟`}
                  name="difference"
                  value={publicationDetails.publishingCompetitors[i].difference}
                  onChange={(e) =>
                    dispatch(
                      modifyPublicationDetails({
                        key: "difference",
                        val: e.target.value,
                        dynamicFormKey: "publishingCompetitors",
                        i,
                      })
                    )
                  }
                ></textarea>
              </div>
            </FormPanel>
          ))}
          <div className="text-center">
            <button
              className="btn"
              type="button"
              onClick={() => dispatch(addPublishingCompetitor())}
            >
              {t("add")}
            </button>
          </div>
        </>
      )}
    </FormPanel>
  );
};
