// import {
//   faTrademark,
//   faShuffle,
//   faFileSignature,
//   faGavel,
//   faCheckDouble,
//   faBraille,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import "./Home.scss";
// import scientificFields from "../../assets/images/home/scientific-fields.png";
// import research from "../../assets/images/home/research.png";
// import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <div id="home">
      <div id="intro">
        <div>
          <h1>
            {t("welcome")} {t("at")} {t("university_special_ar")}
            <span className="animated-title"> {t("king_saud")} </span>
            {t("university_special_en")} {t("publication")}
          </h1>
          <p>{t("home_translate")}</p>
          <NavLink className="btn" to={"/about"}>
            {t("read_more")}
          </NavLink>
        </div>
      </div>

      {/* <div id="read-more">
        <div className="container">
          <ul>
            <li>
              <FontAwesomeIcon icon={faTrademark} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Intellectual_rights")}
              </NavLink>
            </li>
            <li>
              <FontAwesomeIcon icon={faShuffle} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Publication_tracks")}
              </NavLink>
            </li>
            <li>
              <FontAwesomeIcon icon={faFileSignature} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Publishing_contracts")}
              </NavLink>
            </li>
            <li>
              <FontAwesomeIcon icon={faGavel} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Scientific_arbitration")}
              </NavLink>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckDouble} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Linguistic_correction")}
              </NavLink>
            </li>
            <li>
              <FontAwesomeIcon icon={faBraille} />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <NavLink className="btn menu-item" to={"/about"}>
                {t("Review_matching")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="shorts-image container">
        <div>
          <h2>{t("Scientific_publishing_fields")}</h2>
          <ul>
            <li>{t("Scientific_publishing_fields_first_list")}</li>
            <li>{t("Scientific_publishing_fields_second_list")}</li>
            <li>{t("Scientific_publishing_fields_third_list")}</li>
          </ul>
          <NavLink className="btn" to={"/about"}>
            {t("read_more")}
          </NavLink>
        </div>
        <img src={scientificFields} alt="" />
      </div>
      <div className="recent-ads">
        <h3> {t("Latest_posting_ads")} </h3>
        <h4>
          {t("quality_of_education")} , {t("Scientific_Research")} ,{" "}
          {t("leading_businesses")}
        </h4>
        <div className="container">
          <ul className="recent-ads__ad-container">
            <li>
              <div className="recent-ads__img"></div>
              <div>
                <h4> {t("Islamic_studies_religions")} </h4>
                <span> {t("Submission_starts_after")} </span>
                <ul>
                  <li>
                    <span>{t("second")}</span>0
                  </li>
                  <li>
                    <span>{t("minute")}</span>0
                  </li>
                  <li>
                    <span>{t("hour")}</span>0
                  </li>
                  <li>
                    <span>{t("day")}</span>0
                  </li>
                </ul>
                <button className="btn">{t("submit")}</button>
                <span>ا{t("Deadline_submission")}: 20/10/2023</span>
              </div>
            </li>
            <li>
              <div className="recent-ads__img"></div>
              <div>
                <h4> {t("Social_administrative_sciences")} </h4>
                <span> {t("Submission_starts_after")}</span>
                <ul>
                  <li>
                    <span>{t("second")}</span>0
                  </li>
                  <li>
                    <span>{t("minute")}</span>0
                  </li>
                  <li>
                    <span>{t("hour")}</span>0
                  </li>
                  <li>
                    <span>{t("day")}</span>0
                  </li>
                </ul>
                <button className="btn">{t("submit")}</button>
                <span>ا{t("Deadline_submission")}: 20/10/2023</span>
              </div>
            </li>
            <li>
              <div className="recent-ads__img"></div>
              <div>
                <h4> {t("Languages_literatures")} </h4>
                <span>{t("Submission_starts_after")}</span>
                <ul>
                  <li>
                    <span>{t("second")}</span>0
                  </li>
                  <li>
                    <span>{t("minute")}</span>0
                  </li>
                  <li>
                    <span>{t("hour")}</span>0
                  </li>
                  <li>
                    <span>{t("day")}</span>0
                  </li>
                </ul>
                <button className="btn">{t("submit")}</button>
                <span>ا{t("Deadline_submission")}: 20/10/2023</span>
              </div>
            </li>
            <li>
              <div className="recent-ads__img"></div>
              <div>
                <h4> {t("Natural_applied_sciences")} </h4>
                <span>{t("Submission_starts_after")}</span>
                <ul>
                  <li>
                    <span>{t("second")}</span>0
                  </li>
                  <li>
                    <span>{t("minute")}</span>0
                  </li>
                  <li>
                    z<span>{t("hour")}</span>0
                  </li>
                  <li>
                    <span>{t("day")}</span>0
                  </li>
                </ul>
                <button className="btn">{t("submit")}</button>
                <span>ا{t("Deadline_submission")}: 20/10/2023</span>
              </div>
            </li>
          </ul>
        </div>
        <NavLink to={"/"} className="btn normal">
          {t("All_ads")}
        </NavLink>
      </div>
      <div className="shorts-image container">
        <div>
          <h2> {t("Publication_general_rules")} </h2>
          <ul>
            <li>{t("Publication_general_rules_first_list")}</li>
            <li>{t("Publication_general_rules_second_list")}</li>
            <li>{t("Publication_general_rules_third_list")}</li>
          </ul>
          <NavLink className="btn" to={"/about"}>
            {t("read_more")}
          </NavLink>
        </div>
        <img src={research} alt="" />
      </div> */}
    </div>
  );
};
