import { FormPanel } from "components/FormPanel";
import { ChangeEvent, Fragment, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SubmitArbitration,
  SubmitPublishingArbitrationCriterias,
} from "services/arbitrator.service";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";
import {
  ArbitrationFormCategory,
  ArbitrationFormQuetsion,
  Attachment,
  IArbitrationForm,
} from "types/publication";
import "./ArbitrationForms.scss";
import FilesUploader from "components/FilesUploader";
import { pushAlert } from "store/states/alertSlice";
import InputNumber from "components/InputNumber";
import { GetReports } from "services/report.service";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const DECISION_SECTIONS: ArbitrationFormCategory[] = [
  {
    category:
      "التقييم العام لمستوى الكتاب بناءً على الدرجات المستحقة في الجدول أعلاه",
    questions: [
      {
        contentAR: "ممتاز (91-100).",
        contentEN: "",
        type: "Radio",
        answer: 100,
      },
      {
        contentAR: "جيد جدًا (81-90).",
        contentEN: "",
        type: "Radio",
        answer: 90,
      },
      {
        contentAR: "جيد (71-80).",
        contentEN: "",
        type: "Radio",
        answer: 80,
      },
      {
        contentAR: "ضعيف (1-70).",
        contentEN: "",
        type: "Radio",
        answer: 70,
      },
    ],
  },
  {
    category: "ملاحظات إضافية",
    questions: [
      {
        contentAR: "",
        contentEN: "",
        type: "Text2",
        answer: "",
      },
    ],
  },
  {
    category: "التوصية النهائية بخصوص مدى صلاحية الكتاب للنشر",
    questions: [
      {
        contentAR: "صالح للنشر",
        contentEN: "",
        type: "Radio2",
        answer: "Publishable",
      },
      {
        contentAR: "صالح للنشر بعد إجراء التعديلات المطلوبة",
        contentEN: "",
        type: "Radio2",
        answer: "Modifications",
      },
      {
        contentAR: "غير صالح للنشر",
        contentEN: "",
        type: "Radio2",
        answer: "Unpublishable",
      },
    ],
  },
];

export const ArbitrationForms = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isScientificCommitteeMember, isArbitrator } =
    useAppSelector(selectUserDetails);

  const isViewMode = location.pathname.includes("arbitration_result");
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const [arbitrationFormList, setArbitrationFormList] = useState<
    IArbitrationForm[]
  >([]);
  const [canSubmitQualtiyList, setCanSubmitQualtiyList] = useState<boolean[]>(
    []
  );
  const [formsMissingFields, setFormsMissingFields] = useState([] as string[]);
  const [currentArbitrationFormIdx, setCurrentArbitrationFormIdx] = useState(0);
  const [formsEvaluation, setFormsEvaluation] = useState<number[]>([]);
  const params = useParams();
  const [arbitrationQuality, setArbitrationQuality] = useState({
    accuracy: 0,
    delivery: 0,
    detailedReport: 0,
    comment: "",
  });

  useEffect(() => {
    const tempList = publicationDetails.arbitrationForms.map(
      (a: any) => a.canSubmitQualtiy
    );

    setCanSubmitQualtiyList(tempList);

    const formsWithDecision = publicationDetails.arbitrationForms?.map(
      (form: any) => {
        return {
          ...form,
          categories: [...form.categories, ...DECISION_SECTIONS],
        };
      }
    );
    setArbitrationFormList(formsWithDecision);
    checkFormEvaluation(
      currentArbitrationFormIdx,
      publicationDetails.arbitrationForms
    );
  }, [publicationDetails.arbitrationForms]);

  useEffect(() => {
    const errors = [];
    if (arbitrationFormList[currentArbitrationFormIdx]) {
      if (
        !arbitrationFormList[currentArbitrationFormIdx]?.finalRecommendation
      ) {
        errors.push("التوصية النهائية");
      }
      for (const category of arbitrationFormList[currentArbitrationFormIdx]
        .categories) {
        for (const question of category.questions) {
          if (!question.answer && question.contentAR) {
            errors.push(question.contentAR);
          }
        }
      }

      setFormsMissingFields(errors);
    }
  }, [arbitrationFormList]);

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    question: ArbitrationFormQuetsion,
    fIdx: number,
    catIdx: number,
    qIndex: number,
    type?: string
  ) => {
    const val = e.target.value;
    if (+val > 100 || +val < 0) return;
    let tempList = arbitrationFormList.map((form, i) =>
      i !== fIdx
        ? form
        : {
            ...form,
            categories: form.categories.map((cat, j) =>
              j !== catIdx
                ? cat
                : {
                    ...cat,
                    questions: cat.questions.map((q, k) =>
                      k === qIndex ? { ...question, answer: val } : q
                    ),
                  }
            ),
          }
    );

    if (type === "Number") checkFormEvaluation(fIdx, tempList);
    setArbitrationFormList(tempList);
  };
  const checkFormEvaluation = (fIdx: number, tempList: IArbitrationForm[]) => {
    if (!tempList) return;
    const questions =
      tempList[fIdx]?.categories.filter(
        (cat) => cat.category === "التقييم العام"
      )[0]?.questions || [];
    let sum = 0;
    for (const q of questions) {
      sum += q.answer ? +q.answer : 0;
    }

    let tempFormsEvaluation = [...formsEvaluation];
    tempFormsEvaluation[fIdx] = sum / questions.length;
    setFormsEvaluation(tempFormsEvaluation);
  };
  const handleFinalRecommendation = (
    key: "finalRecommendation" | "requiredModifications" | "attachments",
    fIdx: number,
    val: string | Attachment[]
  ) => {
    setArbitrationFormList((oldValues) =>
      oldValues.map((v, i) => (i === fIdx ? { ...v, [key]: val } : v))
    );
  };
  const submitArbitration = () => {
    SubmitArbitration(publicationDetails.id, arbitrationFormList).then(() => {
      dispatch(
        pushAlert({
          type: "success",
        })
      );
      navigate(-1);
    });
  };
  const printArbitrationResults = () => {
    if (params.id) {
      GetReports(
        `PublishingFinalArbitrations/publishings/${+params.id}/export`,
        {},
        "arbitration_report.pdf"
      ).then(() => {
        dispatch(
          pushAlert({
            type: "success",
          })
        );
      });
    }
  };
  const handleArbitrationQualityFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = e.target.value;
    const name = e.target.name;
    if (+val > 100 || +val < 0) return;
    setArbitrationQuality({ ...arbitrationQuality, [name]: val });
  };
  const renderMissingFormInputs = () => (
    <ul>
      {formsMissingFields.slice(0, 10).map((f) => (
        <li key={f}>{f}</li>
      ))}
      {formsMissingFields.length > 10 && (
        <li> متبقي ايضاً {formsMissingFields.length - 10} حقول أخرى</li>
      )}
    </ul>
  );
  return (
    <>
      <div className="arbitrations-form main-form">
        {arbitrationFormList.map((form, fIdx) => (
          <div key={form.formId}>
            {currentArbitrationFormIdx === fIdx && (
              <FormPanel staticPanel={true} title={form.formDescription}>
                {form.categories.map((cat, catIdx) => (
                  <FormPanel
                    staticPanel={true}
                    key={catIdx}
                    title={cat.category}
                  >
                    <table className="basic-table" cellSpacing={0}>
                      <tbody>
                        {cat.questions.map((q, qIndex) => (
                          <Fragment key={qIndex}>
                            <tr>
                              {(q.contentAR || q.contentEN) && (
                                <td colSpan={q.type === "Text" ? 2 : 1}>
                                  {q.type === "Number"
                                    ? q.contentAR + " (بحد أقصى 100 درجة)"
                                    : q.contentAR}
                                </td>
                              )}

                              {q.type !== "Text" && (
                                <td>
                                  <div className="main-form__radio-container">
                                    {q.type === "YesNo" && (
                                      <>
                                        <label>
                                          <input
                                            autoComplete="off"
                                            type="radio"
                                            value="1"
                                            name={`q_${fIdx}_${catIdx}_${qIndex}`}
                                            onChange={(e) =>
                                              handleFormChange(
                                                e,
                                                q,
                                                fIdx,
                                                catIdx,
                                                qIndex
                                              )
                                            }
                                            checked={q.answer === "1"}
                                            disabled={isViewMode}
                                          />
                                          {t("yes")}
                                        </label>
                                        <label>
                                          <input
                                            autoComplete="off"
                                            type="radio"
                                            value="0"
                                            name={`q_${fIdx}_${catIdx}_${qIndex}`}
                                            onChange={(e) =>
                                              handleFormChange(
                                                e,
                                                q,
                                                fIdx,
                                                catIdx,
                                                qIndex
                                              )
                                            }
                                            checked={q.answer === "0"}
                                            disabled={isViewMode}
                                          />
                                          {t("no")}
                                        </label>
                                      </>
                                    )}
                                    {q.type === "Number" && (
                                      <InputNumber
                                        value={+(q.answer || -1)}
                                        onChange={(e) =>
                                          handleFormChange(
                                            e,
                                            q,
                                            fIdx,
                                            catIdx,
                                            qIndex,
                                            "Number"
                                          )
                                        }
                                        disabled={isViewMode}
                                      />
                                    )}
                                    {q.type === "Radio" && (
                                      <>
                                        <label>
                                          <input
                                            autoComplete="off"
                                            type="radio"
                                            name={`q_${fIdx}_${catIdx}`}
                                            checked={
                                              (!isNaN(formsEvaluation[fIdx]) &&
                                                formsEvaluation[fIdx]) <=
                                              (q.answer || 0)
                                            }
                                            onChange={(e) => {
                                              e.preventDefault();
                                            }}
                                            disabled={true}
                                            value={q.answer}
                                          />
                                          {t("yes")}
                                        </label>
                                      </>
                                    )}
                                    {q.type === "Text2" && (
                                      <textarea
                                        value={
                                          arbitrationFormList[fIdx]
                                            .requiredModifications || ""
                                        }
                                        disabled={isViewMode}
                                        name={`requiredModifications`}
                                        onChange={(e) =>
                                          handleFinalRecommendation(
                                            "requiredModifications",
                                            fIdx,
                                            e.target.value
                                          )
                                        }
                                      ></textarea>
                                    )}
                                    {q.type === "Radio2" && (
                                      <>
                                        <label>
                                          <input
                                            autoComplete="off"
                                            type="radio"
                                            name={`finalRecommendation`}
                                            onChange={(e) =>
                                              handleFinalRecommendation(
                                                "finalRecommendation",
                                                fIdx,
                                                q.answer + ""
                                              )
                                            }
                                            value={q.answer}
                                            disabled={isViewMode}
                                            checked={
                                              q.answer ===
                                              form.finalRecommendation
                                            }
                                          />
                                          {t("yes")}
                                        </label>
                                      </>
                                    )}
                                  </div>
                                </td>
                              )}
                            </tr>

                            {q.type === "Text" && (
                              <tr>
                                <td colSpan={2}>
                                  <div className="main-form__input-container">
                                    <textarea
                                      value={q.answer || ""}
                                      disabled={isViewMode}
                                      onChange={(e) =>
                                        handleFormChange(
                                          e,
                                          q,
                                          fIdx,
                                          catIdx,
                                          qIndex
                                        )
                                      }
                                    ></textarea>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </FormPanel>
                ))}
                {isScientificCommitteeMember && canSubmitQualtiyList[fIdx] && (
                  <FormPanel
                    staticPanel={true}
                    title={`جودة ${form.formDescription}`}
                  >
                    <div className="col-3">
                      <div className="modern-form__input-container">
                        <label>{t("fill_form_carefully")}</label>
                        <InputNumber
                          name="accuracy"
                          value={arbitrationQuality.accuracy}
                          onChange={handleArbitrationQualityFormChange}
                          placeholder={t("fill_form_carefully")}
                        />
                      </div>
                      <div className="modern-form__input-container">
                        <label>{t("submit_arbitration_on_time")}</label>
                        <InputNumber
                          name="delivery"
                          value={arbitrationQuality.delivery}
                          onChange={handleArbitrationQualityFormChange}
                          placeholder={t("fill_form_carefully")}
                        />
                      </div>
                      <div className="modern-form__input-container">
                        <label>
                          {t("provide_detailed_report_with_arbitration_form")}
                        </label>
                        <InputNumber
                          name="detailedReport"
                          value={arbitrationQuality.detailedReport}
                          onChange={handleArbitrationQualityFormChange}
                          placeholder={t("fill_form_carefully")}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="modern-form__input-container">
                        <label>{t("comment")}</label>
                        <textarea
                          name="comment"
                          value={arbitrationQuality.comment}
                          onChange={handleArbitrationQualityFormChange}
                          placeholder={t("comment")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="modern-form__footer">
                      <button
                        type="button"
                        onClick={() => {
                          SubmitPublishingArbitrationCriterias({
                            publishingFinalArbitrationId:
                              form.publishingFinalArbitrationId,
                            ...arbitrationQuality,
                          }).then(() => {
                            const tempCanSubmitQualtiyList = [
                              ...canSubmitQualtiyList,
                            ];
                            tempCanSubmitQualtiyList[fIdx] = false;
                            setCanSubmitQualtiyList(tempCanSubmitQualtiyList);
                            setArbitrationQuality({
                              accuracy: 0,
                              delivery: 0,
                              detailedReport: 0,
                              comment: "",
                            });
                            dispatch(
                              pushAlert({
                                type: "success",
                                msgAR: `${t(
                                  "send_arbitration_quality_results"
                                )} - ${form.formDescription} ${t(
                                  "successfully"
                                )}`,
                                msgEN: `Sending arbitrantion quality - ${form.formDescription} succeeded`,
                              })
                            );
                          });
                        }}
                      >
                        {t("send_arbitration_quality_results")} -{" "}
                        {form.formDescription}
                      </button>
                    </div>
                  </FormPanel>
                )}

                <FilesUploader
                  uploadPath={`publishings/${publicationDetails.id}/files`}
                  deletePath="publishings/file"
                  deleteType="byURL"
                  forceShow={isArbitrator}
                  forceHide={location.pathname.includes("arbitration_result")}
                  attachments={(files) =>
                    handleFinalRecommendation("attachments", fIdx, files)
                  }
                  initialAttachments={form.attachments}
                />
              </FormPanel>
            )}
          </div>
        ))}

        {!!arbitrationFormList.length && (
          <div className="main-form__footer col-2">
            <div className="nav_buttons">
              {currentArbitrationFormIdx < arbitrationFormList.length - 1 && (
                <button
                  type="button"
                  disabled={!!formsMissingFields.length}
                  onClick={() => {
                    setCurrentArbitrationFormIdx(currentArbitrationFormIdx + 1);
                  }}
                >
                  {t("next")}
                </button>
              )}

              {currentArbitrationFormIdx > 0 && (
                <button
                  type="button"
                  disabled={!!formsMissingFields.length}
                  onClick={() => {
                    setCurrentArbitrationFormIdx(currentArbitrationFormIdx - 1);
                  }}
                >
                  {t("previous")}
                </button>
              )}

              {!isViewMode && (
                <button
                  disabled={
                    currentArbitrationFormIdx <
                      arbitrationFormList.length - 1 ||
                    !!formsMissingFields.length
                  }
                  type="button"
                  onClick={submitArbitration}
                >
                  {t("send_arbitration_results")}
                </button>
              )}
              {isViewMode && (
                <button type="button" onClick={printArbitrationResults}>
                  {t("print_arbitration_results")}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {formsMissingFields.length > 0 && (
        <div className="arbitrations-form__errors">
          <h4>يوجد حقول مفقودة</h4>
          {renderMissingFormInputs()}
        </div>
      )}
    </>
  );
};
