import { FormPanel } from "components/FormPanel";
import { Popup } from "components/Popup/Popup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";

interface CriteriaPopupProps {
  setShowCriteriaPopup: (type: boolean) => void;
  showCriteriaPopup: boolean;
}

const calcFinalResult = (criteria: any) => {
  let finalResult = 0;
  for (const c of criteria) {
    finalResult +=
      (c.accuracy * 50) / 100 +
      (c.delivery * 30) / 100 +
      (c.detailedReport * 20) / 100;
  }
  return (finalResult / criteria.length).toFixed(2);
};
export const CriertiaPopup = (props: CriteriaPopupProps) => {
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { t } = useTranslation();
  const { setShowCriteriaPopup, showCriteriaPopup } = props;
  return (
    <Popup
      onClose={() => {
        setShowCriteriaPopup(false);
      }}
      showPopup={showCriteriaPopup}
      widePopup={true}
    >
      <div className="comments-container">
        {publicationDetails.publishingArbitrationCriterias?.map((c: any, i) => (
          <FormPanel
            title={`${t(c.arbitrationFormName)}`}
            staticPanel={true}
            key={c.scientificCommitteeMemberId + i}
          >
            <div className={`main-form__input-container`}>
              <table className="basic-table">
                <thead>
                  <tr>
                    <th>{t("arbitrator_name")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{c.arbitratorName}</td>
                  </tr>
                </tbody>
              </table>
              {c.criterias.map((cr: any, i: number) => (
                <>
                  <div className="col-3" key={i}>
                    <div className="modern-form__input-container">
                      <label>{t("Precision")}</label>
                      <input type="text" disabled value={cr.accuracy} />
                    </div>
                    <div className="modern-form__input-container">
                      <label>{t("delivery")}</label>
                      <input type="text" disabled value={cr.delivery} />
                    </div>
                    <div className="modern-form__input-container">
                      <label>{t("details")}</label>
                      <input type="text" disabled value={cr.detailedReport} />
                    </div>
                    <div className="modern-form__input-container">
                      <label>{t("result")}</label>
                      <input
                        type="text"
                        disabled
                        value={
                          (
                            (cr.accuracy * 50) / 100 +
                            (cr.delivery * 30) / 100 +
                            (cr.detailedReport * 20) / 100
                          ).toFixed(2) + "%"
                        }
                      />
                    </div>
                    <div className="modern-form__input-container">
                      <label>{t("member")}</label>
                      <input
                        type="text"
                        disabled
                        value={cr.scientificCommitteeMemberName}
                      />
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="modern-form__input-container">
                      <label>{t("comment")}</label>
                      <textarea disabled value={cr.comment}></textarea>
                    </div>
                  </div>
                </>
              ))}
              <table className="basic-table">
                <thead>
                  <tr>
                    <th>{t("the_final_result")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{calcFinalResult(c.criterias)}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </FormPanel>
        ))}
      </div>
    </Popup>
  );
};
