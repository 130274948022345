import "./styles.scss";
export const ProgressBar = () => {
  return (
    <div className="progress-bar linear-progress primary linear-progress--animation-ready linear-progress--indeterminate">
      <div aria-hidden="true" className="linear-progress__buffer">
        <div className="linear-progress__buffer-bar"></div>
        <div className="linear-progress__buffer-dots"></div>
      </div>
      <div
        aria-hidden="true"
        className="linear-progress__bar linear-progress__primary-bar"
      >
        <span className="linear-progress__bar-inner"></span>
      </div>
      <div
        aria-hidden="true"
        className="linear-progress__bar linear-progress__secondary-bar"
      >
        <span className="linear-progress__bar-inner"></span>
      </div>
    </div>
  );
};
