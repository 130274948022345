import React from "react";

const Divider = () => {
  return (
    <div className="no-print">
      <br />
      <hr />
      <br />
    </div>
  );
};

export default Divider;
