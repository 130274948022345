import {
  GetArbitrationQuestions,
  GetArbitrationQuestionsByFormName,
  UpdateArbitrationQuestions,
} from "services/arbitrator.service";
import { useEffect, useState } from "react";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import "./ArbitrationQuestions.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ArbitrationForms } from "modules/arbitrator/ArbitrationForms";

export const ArbitrationQuestions = () => {
  const { t } = useTranslation();
  const [questionsList, setQuestionsList] = useState<any>([]);
  const [modifiedQuestionsList, setModifiedQuestionsList] = useState<any>([]);
  const [formsList, setFormsList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFormId, setSelectedFormId] = useState("0");
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    GetArbitrationQuestions().then((res) => setQuestionsList(res.data));
    GetArbitrationQuestionsByFormName([]).then((k) => setFormsList(k.data));
  }, []);

  const handleFormChange = (event: any, q: any) => {
    q["contentAR"] = event.target.value;
    const tempList = [
      ...modifiedQuestionsList.filter((question: any) => question.id !== q.id),
      q,
    ];
    setModifiedQuestionsList(tempList);
  };

  const renderQuestionsList = () => {
    const filterdQuestionList = questionsList.filter(
      (q: any) =>
        q.contentAR.includes(searchTerm) &&
        (+selectedFormId ? q.arbitrationFormId === +selectedFormId : true)
    );

    const renderArray = [];
    for (let i = 0; i < filterdQuestionList.length; i += 2) {
      renderArray.push(
        <tr key={i}>
          <td>
            <input
              autoComplete="off"
              type="text"
              value={filterdQuestionList[i].contentAR}
              disabled={isEditMode}
              onChange={(event) =>
                handleFormChange(event, filterdQuestionList[i])
              }
            />
          </td>

          {i + 1 < filterdQuestionList.length && (
            <td>
              <input
                autoComplete="off"
                type="text"
                value={filterdQuestionList[i + 1].contentAR}
                disabled={isEditMode}
                onChange={(event) =>
                  handleFormChange(event, filterdQuestionList[i + 1])
                }
              />
            </td>
          )}
        </tr>
      );
    }
    return renderArray;
  };
  const renderSelectedForm = () => {
    return formsList.map((formObj: any, i: number) => (
      <option key={i} value={formObj.id}>
        {formsList[i].description}
      </option>
    ));
  };

  return (
    <div className="container">
      <Breadcrumb>
        <div className="breadcrumb__filters">
          <div className="search-box">
            <input
              autoComplete="off"
              type="search"
              placeholder="search here"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <select
              onChange={(e) => setSelectedFormId(e.target.value)}
              className="filter-select"
            >
              <option value="0">{t("all")}</option> {renderSelectedForm()}
            </select>
            <button
              type="button"
              onClick={() => {
                setIsEditMode(!isEditMode);
                !isEditMode &&
                  UpdateArbitrationQuestions(modifiedQuestionsList);
              }}
              className="btn"
            >
              {isEditMode ? `${t("Edit")}` : `${t("save")}`}
            </button>
          </div>
        </div>
      </Breadcrumb>
      <div className="arbitration-questions">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th colSpan={2}>{t("Modifying_arbitrators_questions")}</th>
            </tr>
          </thead>
          <tbody>
            {questionsList.length ? (
              renderQuestionsList()
            ) : (
              <tr>
                <td>{t("no_data_show")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
