import { useEffect, useState } from "react";
import { GetCharts, GetReports } from "services/report.service";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import LineCard from "./LineCard";
import LinesCard from "./LinesChart";
import DoughnutCard from "./doughnutCard";
import "./analysis.scss";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

export function Analysis() {
  const [charts, setCharts] = useState<any>("");
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    GetCharts().then((res) => {
      setCharts(res.data);
    });
  }, []);

  const handleDownload = (chartName: any, startDate?: any, endDate?: any) => {
    const data = JSON.stringify({
      reportCode: chartName,
      from: startDate && startDate,
      to: endDate && endDate,
    });
    GetReports("report/chartexcel", data, `${chartName}.xlsx`);
  };

  const renderLineCards = (
    startIndex: number,
    endIndex: number
  ): JSX.Element[] => {
    return charts.countCharts
      .slice(startIndex, endIndex)
      .map((chart: any, i: number) => (
        <div key={i} className="chart-box">
          <LineCard
            mainTitle={t(chart.name)}
            subTitle={"مقارنة بـ آخر اسبوع"}
            percentage={
              chart.changePercentage >= 0
                ? chart.changePercentage.toFixed()
                : -chart.changePercentage.toFixed()
            }
            value={chart.totalCount}
            data={chart.data}
            growth={chart.changePercentage > 0 ? "1" : "-1"}
          />
          <button
            type="button"
            className="btn"
            onClick={() => {
              handleDownload(chart.name, selectedStartDate, selectedEndDate);
            }}
          >
            {t("download")}
          </button>
        </div>
      ));
  };

  const renderDoughnutCard = (chartTitle: string, chartData: any) => {
    return (
      <div>
        <DoughnutCard
          mainTitle={t(chartTitle)}
          ydata={chartData.yAxis}
          xdata={chartData.xAxis}
        />
      </div>
    );
  };

  if (charts) {
    const threeDimensionChartsData = charts["threeDimensionCharts"][0]["data"];
    const linesChartData: any = Object.values(threeDimensionChartsData);
    const specializationName: any = Object.keys(threeDimensionChartsData);
    return (
      <div className="container analysis">
        <Breadcrumb>
          <div className="breadcrumb__filters">
            <div className="box_one">
              <label>{t("from")}</label>
              <DatePicker
                className="filter-select"
                placeholderText="dd/mm/yyyy"
                selected={selectedStartDate}
                onChange={(date) => setSelectedStartDate(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="box_tow">
              <label> {t("to")}</label>
              <DatePicker
                className="filter-select"
                placeholderText="dd/mm/yyyy"
                selected={selectedEndDate}
                onChange={(date) => setSelectedEndDate(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <button
              type="button"
              className="btn"
              onClick={() => {
                handleDownload("all", selectedStartDate, selectedEndDate);
              }}
            >
              {t("download_chart")}
            </button>
          </div>
        </Breadcrumb>
        <div className={"col-4"}>{renderLineCards(0, 4)}</div>
        <div className="mid-block">
          <LinesCard
            mainTitle={t(charts["threeDimensionCharts"][0]["name"])}
            data={linesChartData}
            specializationName={specializationName}
          />
        </div>
        <div className="col-3">
          <div>{renderLineCards(4, 6)}</div>
          {renderDoughnutCard(
            charts.twoDimensionCharts[0]["chartCode"],
            charts["twoDimensionCharts"][0]
          )}
          <div>{renderLineCards(6, 8)}</div>
        </div>
      </div>
    );
  } else {
    return <div className="container"> {t("no_data_show")}</div>;
  }
}
