import FilesUploader from "components/FilesUploader";
import { FormPanel } from "components/FormPanel";
import { Popup } from "components/Popup/Popup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";

interface CommentsPopupProps {
  setShowCommentsPopup: (type: boolean) => void;
  showCommentsPopup: boolean;
}

export const CommentsPopup = (props: CommentsPopupProps) => {
  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { setShowCommentsPopup, showCommentsPopup } = props;
  return (
    <Popup
      widePopup={true}
      onClose={() => {
        setShowCommentsPopup(false);
      }}
      showPopup={showCommentsPopup}
    >
      <ul className="comments-map">
        <li>{t("editor")}</li>
        <li>{t("vice_manger")}</li>
        <li>{t("marketing_director")}</li>
        <li>{t("marketing_officer")}</li>
        <li>{t("first_arbitrators")}</li>
        <li>{t("manger")}</li>
      </ul>
      <div className="comments-container">
        {publicationDetails.publishingFeedbacks?.map((commentEl, i) => (
          <FormPanel
            title={`${t("comments_for")} ${t(commentEl.userRole)}`}
            staticPanel={true}
            key={commentEl.id}
          >
            <div className={`main-form__input-container ${commentEl.userRole}`}>
              <table className="basic-table">
                <thead>
                  <tr>
                    <th>{t("Commentator")}</th>
                    <th>{t("action")}</th>
                    <th>{t("date")}</th>
                    <th>{t("time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{commentEl.userName}</td>
                    <td>{commentEl.status}</td>
                    <td>
                      {new Date(commentEl.createdAt).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(commentEl.createdAt).toLocaleTimeString()}
                    </td>
                  </tr>
                </tbody>
              </table>
              <textarea
                name="comment"
                disabled
                value={commentEl.comment}
              ></textarea>
              {!!commentEl.attachments.length && (
                <FilesUploader
                  uploadPath={`/`}
                  deletePath={`/`}
                  forceHide={true}
                  initialAttachments={commentEl.attachments}
                />
              )}
            </div>
          </FormPanel>
        ))}
      </div>
    </Popup>
  );
};
