import logo from "../assets/images/logo.png";
import logoW from "../assets/images/logo_w.png";
import eNLogo from "../assets/images/en_logo.png";
import eNLogoW from "../assets/images/en_logo_w.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
export const Logo = () => {
  const { i18n } = useTranslation();
  const themeCtx = useContext(ThemeContext);
  return (
    <>
      {i18n.language === "ar" ? (
        themeCtx.theme === "light" ? (
          <img id="logo" src={logo} alt="King Saud University's Logo" />
        ) : (
          <img id="logo" src={logoW} alt="King Saud University's Logo" />
        )
      ) : themeCtx.theme === "light" ? (
        <img id="logo" src={eNLogo} alt="King Saud University's Logo" />
      ) : (
        <img id="logo" src={eNLogoW} alt="King Saud University's Logo" />
      )}
    </>
  );
};
