import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  // maintainAspectRatio: false,
  responsive: true,
  aspectRatio: 1.5,
  cutoutPercentage: 70,
  plugins: {
    legend: {
      display: false, // Remove legend
    },
    filler: {
      propagate: true,
    },
    shadow: {
      enabled: true,
      color: "rgba(0, 0, 0, 0.2)", // Shadow color
      blur: 10, // Shadow blur value
      offsetY: 10, // Vertical offset of the shadow
    },
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  scales: {
    x: {
      display: false, // Remove x-axis
    },
    y: {
      display: false, // Remove y-axis
      beginAtZero: true,
    },
  },
};

const DoughnutCard = (props: { ydata: any; xdata: any; mainTitle: string }) => {
  const { ydata, xdata, mainTitle } = props;
  const borderColor = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];
  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];
  const chartData = {
    labels: xdata.axisData,
    datasets: [
      {
        label: ydata.axisName,
        data: ydata.axisData,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        borderWidth: 1.5,
        fill: true,
        pointRadius: 1,
      },
    ],
  };
  return (
    <div className="analysis-card lines">
      <h4>{mainTitle}</h4>
      <div className="lines-content">
        <Doughnut options={options} data={chartData} />
      </div>
    </div>
  );
};

export default DoughnutCard;
