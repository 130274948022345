import { useEffect, useState } from "react";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Popup } from "../../../components/Popup/Popup";
import "../Applications/Applications.scss";
import {
  EditArbitrationForm,
  GetArbitrationFormsList,
} from "services/arbitration_forms_list.service";

export const ArbitrationFormsList = () => {
  const [formsList, setFormsList] = useState<any>([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedFormForUpdate, setSelectedFormForUpdate] = useState({
    name: "",
    id: 0,
    description: "",
    type: "",
  });

  useEffect(() => {
    GetArbitrationFormsList().then((res) => setFormsList(res.data));
  }, []);

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedFormForUpdate({
      ...selectedFormForUpdate,
      [event.target.name]: event.target.value,
    });
  };

  const { t } = useTranslation();
  return (
    <div className="container">
      <Breadcrumb />
      <div className="arbitration-forms-list">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("number")}</th>
              <th>{t("form_number")}</th>
              <th>{t("arbitration_forms")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formsList.map((ele: any) => (
              <tr key={ele.id}>
                <td>{ele.id}</td>
                <td>{ele.name}</td>
                <td>{ele.description}</td>
                <td>
                  <button
                    className="btn edit"
                    onClick={() => {
                      setShowEditPopup(true);
                      setSelectedFormForUpdate({
                        name: ele.name,
                        description: ele.description,
                        id: ele.id,
                        type: ele.type,
                      });
                    }}
                  >
                    {t("Edit")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowEditPopup(false);
        }}
        showPopup={showEditPopup}
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            EditArbitrationForm(
              selectedFormForUpdate.id,
              selectedFormForUpdate
            ).then(() => {
              GetArbitrationFormsList().then((res) => setFormsList(res.data));
              setShowEditPopup(false);
            });
          }}
          className="modern-form"
        >
          <h2>{t("edit_form")}</h2>
          <div className="modern-form__input-container">
            <label>{t("form_name")}</label>
            <input
              autoComplete="off"
              type="text"
              name="description"
              value={selectedFormForUpdate.description}
              placeholder={t("form_name")}
              onChange={handleFormChange}
              autoFocus
            />
          </div>
          <div className="modern-form__footer">
            <button> {t("save")}</button>
          </div>
        </form>
      </Popup>
    </div>
  );
};
