import React from "react";
import { FormPanel } from "../../../../../components/FormPanel";
import { useTranslation } from "react-i18next";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { InfoBookAuthor } from "./common/InfoBookAuthor";
import FilesUploader from "components/FilesUploader";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const ScientificResearch = () => {
  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const dispatch = useAppDispatch();
  return (
    <>
      <FormPanel title={t("info_desired_search")}>
        <div className="col-3">
          <div className="main-form__input-container">
            <label>
              {t("search_title_ar")} <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("search_title_ar")}
              type="text"
              name="arabicTitle"
              value={publicationDetails.arabicTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("search_title_en")} <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("search_title_en")}
              type="text"
              name="englishTitle"
              value={publicationDetails.englishTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("Research_summary_ar")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("Research_summary_ar")}
            name="arabicSummary"
            value={publicationDetails.arabicSummary}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("Research_summary_en")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("Research_summary_en")}
            name="englishSummary"
            value={publicationDetails.englishSummary}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>

        <div className="main-form__input-container">
          <label>
            {t("Keywords_ar")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("Keywords_ar")}
            name="arabicKeywords"
            value={publicationDetails.arabicKeywords}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("Keywords_en")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("Keywords_en")}
            name="englishKeywords"
            value={publicationDetails.englishKeywords}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("copy_original_search")}{" "}
            <span className="required-input">*</span>
          </label>
          <FilesUploader
            uploadPath={`Publishings/${publicationDetails.id}/attachments`}
            deletePath="publishings/attachments"
            attachmentType="Search_Origin"
            initialAttachments={publicationDetails.attachments["Search_Origin"]}
          />
        </div>
      </FormPanel>
      <InfoBookAuthor />
      <AcknowledgmentRightProperty />
    </>
  );
};
