import { useEffect, useState } from "react";
import { EditBookType, GetBookType } from "../../../services/book_type.service";
import "../BookType/BookType.scss";
import { Breadcrumb } from "./../../../components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Popup } from "./../../../components/Popup/Popup";

export const BookType = () => {
  const [bookTypeList, setBookTypeList] = useState<any>([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedTypeForUpdate, setSelectedTypeForUpdate] = useState({
    name: "",
    id: 0,
  });

  useEffect(() => {
    GetBookType().then((res) => setBookTypeList(res.data));
  }, []);

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedTypeForUpdate({
      ...selectedTypeForUpdate,
      [event.target.name]: event.target.value,
    });
  };

  const { t } = useTranslation();
  return (
    <div className="container">
      <Breadcrumb />
      <div className="type-list">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("number")}</th>
              <th>{t("name")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bookTypeList.map((ele: any) => (
              <tr key={ele.id}>
                <td>{ele.id}</td>
                <td>{ele.name}</td>
                <td>
                  <button
                    className="btn edit"
                    onClick={() => {
                      setShowEditPopup(true);
                      setSelectedTypeForUpdate({
                        name: ele.name,
                        id: ele.id,
                      });
                    }}
                  >
                    {t("Edit")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowEditPopup(false);
        }}
        showPopup={showEditPopup}
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            EditBookType(selectedTypeForUpdate.id, selectedTypeForUpdate).then(
              () => {
                GetBookType().then((res) => setBookTypeList(res.data));
                setShowEditPopup(false);
              }
            );
          }}
          className="modern-form"
        >
          <h2>{t("edit_field")}</h2>
          <div className="modern-form__input-container">
            <label>{t("book_type")}</label>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={selectedTypeForUpdate.name}
              placeholder={t("book_type")}
              onChange={handleFormChange}
              autoFocus
            />
          </div>
          <div className="modern-form__footer">
            <button> {t("save")}</button>
          </div>
        </form>
      </Popup>
    </div>
  );
};
