import { Logo } from "./Logo";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="no-print">
      <div className="container">
        <div id="quick-link">
          <Logo />
          {/* <ul>
            <li>{t("about_us")}</li>
            <li>
              <a href="/">{t("foundation")}</a>
            </li>
            <li>
              <a href="/">{t("Vision_mission_target")}</a>
            </li>
            <li>
              <a href="/">{t("CEO_speech")}</a>
            </li>
            <li>
              <a href="/">{t("Our_staff")}</a>
            </li>
            <li>
              <a href="/">{t("careers")}</a>
            </li>
          </ul>
          <ul>
            <li>{t("services")}</li>
            <li>
              <a href="/">{t("Publishing_services")}</a>
            </li>
            <li>
              <a href="/">{t("Printing_services")}</a>
            </li>
            <li>
              <a href="/">{t("author_services")}</a>
            </li>
            <li>
              <a href="/">{t("Training services")}</a>
            </li>
          </ul> */}
          <div id="contact">
            <h3>{t("contact_with_us")}</h3>
            <p>{t("Contact_through_following_communication_platforms")}</p>
            <ul>
              <li>
                <strong>{t("postal")}</strong> 22480، {t("Riyadh")} 11495{" "}
                {t("ksa")}
              </li>
              <li>
                <strong>{t("phone")}:</strong> 966114672865
              </li>
              <li>
                <strong>{t("fax")}:</strong> 966114672604
              </li>
              <li>
                <strong>{t("email")}: </strong>
                <a href="mailto:ksupress@ksu.edu.sa">ksupress@ksu.edu.sa</a>
              </li>
              <li>
                <strong>{t("technical_support")}: </strong>
                <a href="support@ksupress.ksu.edu.sa">
                  support@ksupress.ksu.edu.sa
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="social">
          <p>{t("King_Saud_University_Rights")}</p>
        </div>
      </div>
    </footer>
  );
};
