import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "../../../../../store/states/publicationDetailsSlice";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { ContractFavOptions } from "./common/ContractFavOptions";
import { OtherPublications } from "./common/OtherPublications";
import { InfoBookAuthor } from "./common/InfoBookAuthor";
import { ProposalDetails } from "./common/ProposalDetails";
import { TargetAudiance } from "./common/TargetAudiance";
import { RateOfBookCompleted } from "./common/RateOfBookCompleted";
import { ContentReport } from "./common/ContentReport";
import { ProposalInfo } from "./common/proposalInfo";
export const CommonForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);

  return (
    <>
      <ProposalDetails />
      <RateOfBookCompleted />
      <TargetAudiance />
      <ContentReport />
      {!publicationDetails.startArbitration && <InfoBookAuthor />}
      <ProposalInfo />
      {!publicationDetails.startArbitration && (
        <>
          <OtherPublications />
          <ContractFavOptions />
          <AcknowledgmentRightProperty />
        </>
      )}
    </>
  );
};
