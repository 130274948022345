import { Popup } from "components/Popup/Popup";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pushAlert, selectAlert } from "store/states/alertSlice";
import { selectPublicationDetails } from "../../../../store/states/publicationDetailsSlice";
import { selectUserDetails } from "../../../../store/states/userDetailsSlice";
import InputNumber from "components/InputNumber";

interface MatchingFormProps {
  setShowMatchingForm: (type: boolean) => void;
  showMatchingForm: boolean;
  matchingFormDetails: any;
  handleMatchingFormDetailsChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
export const MatchingFormPopup = (props: MatchingFormProps) => {
  const dispatch = useAppDispatch();
  const { onAcceptBody } = useAppSelector(selectAlert);
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { isEditor } = useAppSelector(selectUserDetails);

  const {
    setShowMatchingForm,
    showMatchingForm,
    matchingFormDetails,
    handleMatchingFormDetailsChange,
  } = props;

  const { t } = useTranslation();
  const closePopup = () => {
    setShowMatchingForm(false);
    pushAlert({
      onAcceptBody: { ...onAcceptBody, comment: "" },
    });
  };
  return (
    <Popup
      onClose={() => closePopup()}
      showPopup={showMatchingForm}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowMatchingForm(false);
          dispatch(
            pushAlert({
              show: true,
              onAcceptBody: {
                ...onAcceptBody,
                matchingForm: matchingFormDetails,
              },
            })
          );
          closePopup();
        }}
        className="modern-form"
      >
        <h2>{isEditor ? "إضافة" : "عرض"} نموذج المطابقة</h2>
        {!isEditor && (
          <>
            <hr />
            <br />
            <label htmlFor="">
              إسم المحرر: {publicationDetails?.publishingMatchingForm?.userName}
            </label>
            <br />
            <br />
          </>
        )}
        <hr />
        <br />
        <div className="main-form__radio-container">
          <div className="col-2 f-box">
            <label>
              <input
                autoComplete="off"
                onChange={handleMatchingFormDetailsChange}
                name="notesStatus"
                value="Full"
                type="radio"
                checked={
                  isEditor
                    ? matchingFormDetails?.notesStatus === "Full"
                    : publicationDetails?.publishingMatchingForm
                        ?.notesStatus === "Full"
                }
              />
              تم تعديل جميع الملاحظات
            </label>
            <label>
              <input
                autoComplete="off"
                onChange={handleMatchingFormDetailsChange}
                name="notesStatus"
                value="Partial"
                type="radio"
                checked={
                  isEditor
                    ? matchingFormDetails?.notesStatus === "Partial"
                    : publicationDetails?.publishingMatchingForm
                        ?.notesStatus === "Partial"
                }
              />
              تم تعديل بعض الملاحظات
            </label>
          </div>
        </div>
        <hr />
        <br />
        <div className="main-form__input-container">
          <label htmlFor="">ترك الملاحظات التالية</label>
          <textarea
            disabled={!isEditor}
            placeholder={t("Write_comments")}
            name="ignoredNotes"
            value={
              isEditor
                ? matchingFormDetails?.ignoredNotes
                : publicationDetails?.publishingMatchingForm?.ignoredNotes
            }
            onChange={handleMatchingFormDetailsChange}
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label htmlFor="">ابدى تعليقه على التالي</label>
          <textarea
            disabled={!isEditor}
            placeholder={t("Write_comments")}
            name="authorComment"
            value={
              isEditor
                ? matchingFormDetails?.authorComment
                : publicationDetails?.publishingMatchingForm?.authorComment
            }
            onChange={handleMatchingFormDetailsChange}
          ></textarea>
        </div>
        <hr />
        <br />
        <div className="col-1">
          <div className="main-form__input-container">
            <label>عدد الملاحظات الإجمالي</label>
            <InputNumber
              onChange={handleMatchingFormDetailsChange}
              value={
                isEditor
                  ? matchingFormDetails?.notesCount
                  : publicationDetails?.publishingMatchingForm?.notesCount
              }
              disabled={!isEditor}
              name="notesCount"
              placeholder="عدد الملاحظات الإجمالي"
            />
          </div>
          <div className="main-form__input-container">
            <label>عدد الملاحظات المعدلة</label>
            <InputNumber
              onChange={handleMatchingFormDetailsChange}
              value={
                isEditor
                  ? matchingFormDetails?.modifiedNotes
                  : publicationDetails?.publishingMatchingForm?.modifiedNotes
              }
              disabled={!isEditor}
              name="modifiedNotes"
              placeholder="عدد الملاحظات المعدلة"
            />
          </div>
          <div className="main-form__input-container">
            <label>عدد الملاحظات غير المعدلة مع ذكر السبب</label>
            <InputNumber
              onChange={handleMatchingFormDetailsChange}
              value={
                isEditor
                  ? matchingFormDetails?.notModifiedNotesWithReasons
                  : publicationDetails?.publishingMatchingForm
                      ?.notModifiedNotesWithReasons
              }
              disabled={!isEditor}
              name="notModifiedNotesWithReasons"
              placeholder="عدد الملاحظات غير المعدلة مع ذكر السبب"
            />
          </div>
          <div className="main-form__input-container">
            <label>عدد الملاحظات غير المعدلة بدون ذكر السبب</label>
            <InputNumber
              onChange={handleMatchingFormDetailsChange}
              value={
                isEditor
                  ? matchingFormDetails?.notModifiedNotesWithoutReasons
                  : publicationDetails?.publishingMatchingForm
                      ?.notModifiedNotesWithoutReasons
              }
              disabled={!isEditor}
              name="notModifiedNotesWithoutReasons"
              placeholder="عدد الملاحظات غير المعدلة بدون ذكر السبب"
            />
          </div>
        </div>
        {isEditor && (
          <div className="modern-form__footer">
            <button>ارسال نموذج المطابقة</button>
          </div>
        )}
      </form>
    </Popup>
  );
};
