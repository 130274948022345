import axios from "axios";
import { Arbitrator } from "../types/arbitrator";
import { IArbitrationForm } from "types/publication";

export const AddArbitrator = (arbitrator: Partial<Arbitrator>) => {
  return axios.post("arbitrators", { ...arbitrator });
};

export const GetArbitrators = (fieldId = "") => {
  return axios.get(`arbitrators?fieldId=${fieldId}`);
};

export const RemoveArbitrator = (id: number, obj: any) => {
  return axios.delete(`arbitrators/${id}`, obj);
};
export const UpdateArbitrator = (id: any, obj: any) => {
  return axios.put(`arbitrators/${id}`, obj);
};

export const GetArbitrationResults = (id: string) => {
  return axios.get(`PublishingFinalArbitrations/Publishings/${id}`);
};

export const SubmitPublishingArbitrationCriterias = (criteria: any) => {
  return axios.post(`PublishingArbitrationCriterias`, criteria);
};
export const SubmitArbitration = (
  id: number,
  arbitrationForm: IArbitrationForm[]
) => {
  return axios.post(
    `PublishingFinalArbitrations/Publishings/${id}`,
    arbitrationForm
  );
};

export const GetArbitrationQuestionsByFormName = (id: []) => {
  return axios.get(`ArbitrationForms/${id}`);
};
export const GetArbitrationQuestions = () => {
  return axios.get("ArbitrationQuestions");
};
export const UpdateArbitrationQuestions = (questionsList: []) => {
  return axios.put("ArbitrationQuestions", questionsList);
};
