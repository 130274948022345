import { createBrowserRouter, Navigate, redirect } from "react-router-dom";

import Layout from "./components/Layout";
import { PublicationsList } from "./modules/pages/PublicationsList/PublicationsList";
import { Arbitrators } from "./modules/applicant/pages/Arbitrators/Arbitrators";
import { Profile } from "./pages/Profile";
import { AddPublication } from "./modules/applicant/pages/Publications/AddPublication";
import { ManageUsers } from "./modules/admin/ManageUsers";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Auth } from "./pages/Auth/Auth";
import { ThanksLetter } from "./modules/applicant/ThanksLetter";
import { ArbitrationQuestions } from "modules/admin/ArbitrationQuestions";

import { useAppSelector } from "./store/hooks";
import { selectUserDetails } from "./store/states/userDetailsSlice";
import { selectAuth } from "./store/states/authSlice";
import ArbitrationResults from "modules/pages/ArbitrationResults";
import Archive from "modules/archive_employee/Archive";
import ArchiveList from "./modules/archive_employee/ArchiveList/index";
import { Fields } from "./modules/admin/Fields/index";
import { Specializations } from "./modules/admin/Specializations/index";
import { BookType } from "./modules/admin/BookType/index";
import { Applications } from "./modules/admin/Applications/index";
import { ArbitrationFormsList } from "modules/admin/ArbitrationFormsList";
import { Analysis } from "modules/applicant/pages/Analysis";
import { ApplicationAttachments } from "modules/pages/ApplicationAttachments";
import { AuthorsAcc } from "modules/admin/AuthorsAcc";
import NotFound from "pages/NotFound/NotFound";

function MainRouter() {
  const {
    isApplicant,
    isArbitrator,
    isAdmin,
    isEditor,
    isManager,
    isViceManager,
    isMarketingDirector,
    isMarketingOfficer,
    isSecretarialEmployee,
    isScientificCommitteeDecided,
    isScientificCommitteeMember,
    isProofreader,
    isArtDirector,
    isArchiveEmployee,
  } = useAppSelector(selectUserDetails);
  const isAuth = useAppSelector(selectAuth);

  return createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        { path: "", element: <Home /> },
        { path: "about", element: <About /> },
        {
          path: "",
          loader: () => {
            if (!isAuth) throw redirect("/auth");
            return null;
          },
          children: [
            {
              path: "applicant",
              loader: () => {
                if (!isApplicant) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "profile",
                  element: <Profile />,
                },
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "add", element: <AddPublication /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                { path: "thanks", element: <ThanksLetter /> },
                {
                  path: "proposed_arbitrators",
                  element: <Arbitrators />,
                },
              ],
            },
            {
              path: "arbitrator",
              loader: () => {
                if (!isArbitrator) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                    {
                      path: "view_attachments",
                      element: <ApplicationAttachments />,
                    },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "admin",
              loader: () => {
                if (!isAdmin) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "manage_users",
                  element: <ManageUsers />,
                },
                {
                  path: "analysis",
                  element: <Analysis />,
                },
                {
                  path: "arbitration_questions",
                  element: <ArbitrationQuestions />,
                },
                {
                  path: "book_type",
                  element: <BookType />,
                },
                {
                  path: "applications",
                  element: <Applications />,
                },
                {
                  path: "arbitration_forms_list",
                  element: <ArbitrationFormsList />,
                },
                {
                  path: "fields",
                  element: <Fields />,
                },
                {
                  path: "specializations",
                  element: <Specializations />,
                },
                {
                  path: "authors_accounts",
                  element: <AuthorsAcc />,
                },
                {
                  path: "arbitrators",
                  element: <Arbitrators />,
                },
                {
                  path: "proposed_arbitrators",
                  element: <Arbitrators />,
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "editor",
              loader: () => {
                if (!isEditor) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "manager",
              loader: () => {
                if (!isManager) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "vice_manager",
              loader: () => {
                if (!isViceManager) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "analysis",
                  element: <Analysis />,
                },
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "marketing_director",
              loader: () => {
                if (!isMarketingDirector) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "marketing_officer",
              loader: () => {
                if (!isMarketingOfficer) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "secretarial_employee",
              loader: () => {
                if (!isSecretarialEmployee) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "proofreader",
              loader: () => {
                if (!isProofreader) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                    {
                      path: "view_attachments",
                      element: <ApplicationAttachments />,
                    },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "art_director",
              loader: () => {
                if (!isArtDirector) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                    {
                      path: "view_attachments",
                      element: <ApplicationAttachments />,
                    },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "scientific_committee_decided",
              loader: () => {
                if (!isScientificCommitteeDecided) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "scientific_committee_member",
              loader: () => {
                if (!isScientificCommitteeMember) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "publications",
                  children: [
                    { path: "", element: <PublicationsList /> },
                    { path: "view", element: <AddPublication /> },
                  ],
                },
                {
                  path: "arbitration_result/:id",
                  element: <ArbitrationResults />,
                },
              ],
            },
            {
              path: "archive_employee",
              loader: () => {
                // if (!isArchiveEmployee) throw redirect("/auth");
                return null;
              },
              children: [
                {
                  path: "analysis",
                  element: <Analysis />,
                },
                {
                  path: "archive",
                  element: <ArchiveList />,
                },
                {
                  path: "archive/:archiveId",
                  element: <Archive />,
                },
                {
                  path: "archive/add",
                  element: <Archive />,
                },
                {
                  path: "publications",
                  children: [{ path: "view", element: <AddPublication /> }],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "auth",
      element: isAuth ? <Navigate to={"/"} /> : <Auth />,
    },
    {
      path: "auth/forget_password",
      element: isAuth ? <Navigate to={"/"} /> : <Auth />,
    },
    {
      path: "auth/set_password",
      element: isAuth ? <Navigate to={"/"} /> : <Auth />,
    },
    { path: "*", element: <NotFound /> },
  ]);
}
export default MainRouter;
