import axios from "axios";
import { User } from "../types/user";

export const AddUser = (user: Partial<User>) => {
  return axios.post("users", { ...user });
};

export const GetUserRoles = () => {
  return axios.get("users/roles");
};

export const GetUsers = (role = "", fieldId = "") => {
  return axios.get(`users?role=${role}&fieldId=${fieldId}`);
};
export const RemoveUser = (id: number) => {
  return axios.delete(`users/${id}`);
};
export const EditUsers = (id: any, user: any) => {
  return axios.put(`Users/${id}`, user);
};

export const AssignNewEditor = (editorId: number, applicationId: number) => {
  return axios.patch(`publishings/${applicationId}/editor`, {
    editorId,
  });
};

export const EnableDisableUser = (user: Partial<User>) => {
  return axios.patch(`users/${user.id}/ToggleActivate`, {
    ...user,
    isActive: !user.isActive,
  });
};
