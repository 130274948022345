import axios from "axios";

export const GetCharts = () => {
  return axios.get("AnalyticsChart/statistics");
};

export const GetReports = (url: string, data: any, fileName: string) => {
  return axios
    .post(url, data, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};
