import { RootState } from "..";
import { IProfile } from "./../../types/profile";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IProfile = {
  id: 0,
  doerId: 0,
  isActive: false,
  isDeleted: false,
  fullName: "",
  academicQualification: "",
  generalScientificSpecialization: "",
  scientificRank: "",
  otherScientificRank: "",
  exactScientificSpecialization: "",
  jobStatus: "",
  destinationUniversity: "",
  destinationCollege: "",
  destinationSection: "",
  destinationName: "",
  destinationCountry: "",
  destinationCity: "",
  destinationPOBox: "",
  destinationPostalCode: "",
  destinationPhoneNumber: "",
  destinationOtherPhoneNumber: "",
  destinationEmail: "",
  otherPhoneNumber: "",
  buildingNumber: "",
  poBox: "",
  postalCode: "",
  streetName: "",
  city: "",
  country: "",
  cvUrl: "",
  userId: 0,
  userName: "",
  userEmail: "",
  userPhoneNumber: "",
  userProfileUrl: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IProfile>) => {
      return action.payload;
    },
    editProfileInput: (state: any, action) => {
      const updatedFields = action.payload;
      Object.keys(updatedFields).forEach((key) => {
        state[key] = updatedFields[key];
      });
    },
    setProfileImage: (state, action: PayloadAction<string>) => {
      state.userProfileUrl = action.payload;
    },
  },
});

export const selectProfile = (state: RootState) => state.profile;
export const { setProfile, editProfileInput, setProfileImage } =
  profileSlice.actions;
export default profileSlice.reducer;
