import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NotFound.scss";
const NotFound = () => {
  const { t } = useTranslation();
  return (
    <section className="errorPage">
      <div className="errorPage-container">
        <div className="text">
          <h1>{t("Page_Not_Found")}</h1>
          <p>{t("Page_Not_Found_des")}</p>
          <ul className="menu">
            <li>
              <Link to="/">{t("Go_Home_Page")}</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
