import {
  faBook,
  faFile,
  faList,
  faUser,
  faChartSimple,
  faUserEdit,
  faUsers,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";

export const MENU_LIKNS = {
  Author: [
    {
      url: "/applicant/profile",
      icon: faUser,
      title: "profile",
    },
    {
      url: "/applicant/publications",
      icon: faBook,
      title: "publication_requests",
    },
    {
      url: "/applicant/proposed_arbitrators",
      icon: faUsers,
      title: "proposed_arbitrators",
    },
  ],
  Arbitrator: [
    {
      url: "/arbitrator/publications",
      icon: faBook,
      title: "arbitration_requests",
    },
  ],
  Admin: [
    {
      url: "/admin/publications",
      icon: faBook,
      title: "publication_requests",
    },
    {
      url: "/admin/manage_users",
      icon: faUserEdit,
      title: "manage_users",
    },
    {
      url: "/admin/analysis",
      icon: faChartSimple,
      title: "analysis",
    },
    {
      url: "/admin/arbitration_questions",
      icon: faBook,
      title: "arbitration_questions",
    },
    {
      url: "/admin/applications",
      icon: faFile,
      title: "applications",
    },
    {
      url: "/admin/arbitration_forms_list",
      icon: faFile,
      title: "arbitration_forms_list",
    },
    {
      url: "/admin/fields",
      icon: faList,
      title: "fields",
    },
    {
      url: "/admin/specializations",
      icon: faList,
      title: "Specializations",
    },
    {
      url: "/admin/book_type",
      icon: faBook,
      title: "book_type",
    },
    {
      url: "/admin/authors_accounts",
      icon: faUsersGear,
      title: "Authors",
    },
    {
      url: "/admin/arbitrators",
      icon: faUsersGear,
      title: "arbitrators",
    },
    {
      url: "/admin/proposed_arbitrators",
      icon: faUsersGear,
      title: "proposed_arbitrators",
    },
  ],
  Editor: [
    {
      url: "/editor/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  Manager: [
    {
      url: "/manager/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Vice Manager": [
    {
      url: "/vice_manager/analysis",
      icon: faChartSimple,
      title: "analysis",
    },
    {
      url: "/vice_manager/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Marketing Director": [
    {
      url: "/marketing_director/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Marketing Officer": [
    {
      url: "/marketing_officer/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Secretarial Employee": [
    {
      url: "/secretarial_employee/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Scientific Committee Decided": [
    {
      url: "/scientific_committee_decided/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Scientific Committee Member": [
    {
      url: "/scientific_committee_member/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  Proofreader: [
    {
      url: "/proofreader/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Art director": [
    {
      url: "/art_director/publications",
      icon: faBook,
      title: "publication_requests",
    },
  ],
  "Archive employee": [
    {
      url: "/archive_employee/analysis",
      icon: faChartSimple,
      title: "analysis",
    },
    {
      url: "/archive_employee/archive",
      icon: faBook,
      title: "archive",
    },
  ],
};
