import FilesUploader from "components/FilesUploader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormPanel } from "../../components/FormPanel";
import countries from "../../assets/countries.json";
import { UpdateProfile } from "../../services/profile.service";
import userAvatar from "./../../assets/images/avatar.png";
import "./Profile.scss";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import {
  editProfileInput,
  selectProfile,
  setProfileImage,
} from "store/states/profileSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
export const Profile = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const userDetails = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const updateProfile = () => {
    UpdateProfile(userDetails);
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    dispatch(editProfileInput({ [name]: value }));
  };

  const handleImageUpload = (fileUrl: string) => {
    dispatch(setProfileImage(fileUrl));
  };

  return (
    <div className="container">
      <Breadcrumb>
        <button
          type="button"
          onClick={() => {
            setIsEditMode(!isEditMode);
            isEditMode && updateProfile();
          }}
          className="btn"
        >
          {!isEditMode ? `${t("Edit")}` : `${t("save")}`}
        </button>
      </Breadcrumb>
      <div className="profile">
        <div className="profile__summary">
          <div className="prfile__pic">
            <img
              src={
                userDetails.userProfileUrl
                  ? baseURL + userDetails.userProfileUrl
                  : userAvatar
              }
              alt=""
            />
          </div>
          <div className="profile__summary__content">
            <div className="user">{userDetails.fullName}</div>
            <div className="info">{userDetails.destinationCountry}</div>
          </div>
        </div>
        <div className="main-form">
          {isEditMode && (
            <div className="main-form__input-container">
              <label>{t("profile-pic")}</label>
              <FilesUploader
                uploadPath="users/uploadProfile"
                singleUpload={true}
                deletePath="/"
                allowedExtensions={[".png", ".jpg", ".jpeg"]}
                onUpload={(e: any) => {
                  handleImageUpload(e[0]["fileUrl"]);
                }}
              />
            </div>
          )}
          <FormPanel title={t("personality_info")} staticPanel={true}>
            <div className="col-2">
              <div className="main-form__input-container">
                <label>{t("name")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="fullName"
                  value={userDetails.fullName}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("full_name_arabic")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("General_scientific_specialization")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="generalScientificSpecialization"
                  value={userDetails.generalScientificSpecialization}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("General_scientific_specialization")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("exact_scientific_specialization")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="exactScientificSpecialization"
                  value={userDetails.exactScientificSpecialization}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("exact_scientific_specialization")}
                />
              </div>
              <div className="col-2">
                <div className="main-form__input-container">
                  <label>{t("academic_Qualifications")}</label>
                  <input
                    autoComplete="off"
                    name="academicQualification"
                    placeholder={t("academic_Qualifications")}
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="main-form__input-container">
                  <label>{t("Scientific_specialization")}</label>
                  <input
                    autoComplete="off"
                    name="scientificRank"
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                    placeholder={t("Scientific_position")}
                  />
                </div>
              </div>
            </div>
          </FormPanel>
          <FormPanel title={t("experience")} staticPanel={true}>
            <div className="col-3">
              <div className="main-form__input-container">
                <label>{t("universtly")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationUniversity"
                  value={userDetails.destinationUniversity}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("universtly")}
                />
              </div>
              <div className="col-2">
                <div className="main-form__input-container">
                  <label>{t("facluty")}</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="destinationCollege"
                    value={userDetails.destinationCollege}
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                    placeholder={t("facluty")}
                  />
                </div>
                <div className="main-form__input-container">
                  <label>{t("section")}</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="destinationSection"
                    value={userDetails.destinationSection}
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                    placeholder={t("section")}
                  />
                </div>
              </div>
              <div className="main-form__input-container">
                <label>{t("name_entity")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationName"
                  value={userDetails.destinationName}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("name_entity")}
                />
              </div>
              <div className="main-form__input-container">
                <label> {t("country")}</label>
                <select
                  name="destinationCountry"
                  value={userDetails.destinationCountry}
                  onChange={(e) => {
                    handleFormChange(e);
                    setSelectedCountry(e.target.value);
                  }}
                  disabled={!isEditMode}
                >
                  {countries.map((e, index) => (
                    <option key={index} value={e.country}>
                      {e.country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="main-form__input-container">
                <label>{t("city")} </label>
                <select
                  name="destinationCity"
                  value={userDetails.destinationCity}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("city")}
                >
                  {countries
                    .filter((obj) => obj.country === selectedCountry)[0]
                    ?.city.map((c, index) => (
                      <option key={index} value={c}>
                        {c}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-2">
                <div className="main-form__input-container">
                  <label>{t("postal")}</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="destinationPOBox"
                    value={userDetails.destinationPOBox}
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                    placeholder={t("postal")}
                  />
                </div>
                <div className="main-form__input-container">
                  <label>{t("postal_code")}</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="destinationPostalCode"
                    value={userDetails.destinationPostalCode}
                    onChange={handleFormChange}
                    disabled={!isEditMode}
                    placeholder={t("postal_code")}
                  />
                </div>
              </div>
              <div className="main-form__input-container">
                <label>{t("phone")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationPhoneNumber"
                  value={userDetails.destinationPhoneNumber}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("phone")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("another_phone")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationOtherPhoneNumber"
                  value={userDetails.destinationOtherPhoneNumber}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("another_phone")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("email")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationEmail"
                  value={userDetails.destinationEmail}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("email")}
                />
              </div>
            </div>
          </FormPanel>
          <FormPanel title={t("contact_info")} staticPanel={true}>
            <div className="col-4">
              <div className="main-form__input-container">
                <label>{t("email")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="userEmail"
                  value={userDetails.userEmail}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("email")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("phone")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="userPhoneNumber"
                  value={userDetails.userPhoneNumber}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("phone")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("another_phone")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="userPhoneNumber"
                  value={userDetails.userPhoneNumber}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("another_phone")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("building_number")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="buildingNumber"
                  value={userDetails.buildingNumber}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("building_number")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("postal")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="poBox"
                  value={userDetails.poBox}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("postal")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("postal_code")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="PostalCode"
                  value={userDetails.postalCode}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("postal_code")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("st_name")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="streetName"
                  value={userDetails.streetName}
                  onChange={handleFormChange}
                  disabled={!isEditMode}
                  placeholder={t("st_name")}
                />
              </div>
            </div>
            <div className="main-form__input-container">
              <label>{t("resume")}</label>
              <FilesUploader
                uploadPath="authors/uploadCV"
                singleUpload={true}
                initialAttachments={[
                  {
                    fileName: "السيرة الذاتية",
                    fileUrl: userDetails.cvUrl,
                    type: "test",
                  },
                ]}
              />
            </div>
          </FormPanel>
        </div>
      </div>
    </div>
  );
};
