/* eslint-disable */
import { useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { FormPanel } from "components/FormPanel";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import countries from "../../../assets/countries.json";

import {
  AddArchive,
  GetArchiveInfo,
  UpdateArchiveInfo,
} from "./../../../services/archive_employee.service";
import FilesUploader from "components/FilesUploader";
import InputNumber from "components/InputNumber";
// import DatePicker from "react-datepicker";
import DatePicker from "@deskpro/react-datepicker-hijri";
import moment from "moment-hijri";

import convertDateToFormat from "utils/convertDateToFormat";

const Archive = (props: any) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const urlParams = useParams();
  const archiveId = urlParams.archiveId;
  const location = useLocation();
  const navigate = useNavigate();
  const [archiveInfo, setArchiveInfo] = useState<any>({
    id: archiveId,
    fileNumber: "",
    modifiedFileNumber: "",
    decisionSide: "",
    contractType: "",
    publishingDecisionNumber: "",
    publishingDecisionDate: new Date(),
    publishingDecisionAttachment: "",
    rightsEndDate: new Date(),
    bookTitle: "",
    partsNumber: "",
    bookType: "",
    foreignBookTitle: "",
    foreignAuthor: "",
    originalPublisherName: "",
    foreignPublishingYear: new Date(),
    foreignOriginalISBN: "",
    country: "",
    city: "",
    translatorName: "",
    foreignPublisherContractAttachment: "",
    editorName: "",
    manuscriptTitle: "",
    manuscriptAuthor: "",
    manuscriptDate: new Date(),
    manuscriptSource: "",
    transcriberName: "",
    investigatorName: "",
    authorName: "",
    publisherName: "",
    generalObjectiveClassification: "",
    bookUse: "",
    courseNumberCode: "",
    courseName: "",
    collegeDepartment: "",
    hijriPublishingDate: new Date(),
    gregorianPublishingDate: new Date(),
    issueNumber: "",
    editionNumber: "",
    printedcopiesNumber: "",
    isbn: "",
    deweyDecimalClassification: "",
    nationalLibraryDepositNumber: "",
    marketingClassification: "",
    actualPagesNumber: "",
    pageNumberWithoutFirsts: "",
    smackItemNumber: "",
    periodInventory: "",
    availableBalance: "",
    libraryLetterISBNPage: "",
    depositCertificate: "",
    rejectionForm: "",
    intellectualPropertyRightsAcknowledgmentAttachment: "",
    translatoAuthorAccreditationForm: "",
    languageContentReviewReport: "",
    technicalApprovalCard: "",
    bookInDesignAttachment: "",
    bookPDFAttachment: "",
    bookWordAttachment: "",
    notes: "",
    marketingClassificationCode: "",
    weight: "",
    cost: "",
    price: "",
    priceIncludesTax: "",
    size: "",
    color: "",
    bookIntroduction: "",
    bookChaptersIntroduction: "",
    priceWithoutTax: "",
    smackPeriodStock: "",
    availableBalancePrintedQuantityPeriodStock: "",
    other: "",
  });
  useEffect(() => {
    getArchiveInfo();
  }, []);
  const getArchiveInfo = () => {
    archiveId &&
      GetArchiveInfo(archiveId).then((res) => {
        setArchiveInfo(res.data);
      });
  };

  const { t } = useTranslation();
  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setArchiveInfo({
      ...archiveInfo,
      [event.target.name]: event.target.value,
    });
  };
  const UpdateArchive = () => {
    UpdateArchiveInfo(archiveId, archiveInfo)
      .then(() => getArchiveInfo())
      .then(() => {
        navigate(-1);
      });
  };
  return (
    <div className="container">
      <Breadcrumb />
      <div className="main-form">
        <FormPanel title={t("basic_info")} staticPanel={true}>
          <div className="col-4">
            <div className="main-form__input-container">
              <label>{t("file_number")}</label>
              <input
                autoComplete="off"
                type="text"
                name="fileNumber"
                onChange={handleFormChange}
                value={archiveInfo.fileNumber}
                placeholder={t("file_number")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Modified_file_number")}</label>
              <InputNumber
                name="modifiedFileNumber"
                onChange={handleFormChange}
                value={archiveInfo.modifiedFileNumber}
                placeholder={t("Modified_file_number")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("decision_side")}</label>
              <input
                autoComplete="off"
                type="text"
                name="decisionSide"
                onChange={handleFormChange}
                value={archiveInfo.decisionSide}
                placeholder={t("decision_side")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("contract_type")}</label>
              <input
                autoComplete="off"
                type="text"
                name="contractType"
                onChange={handleFormChange}
                value={archiveInfo.contractType}
                placeholder={t("contract_type")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Publication_resolution_numbe")}</label>
              <InputNumber
                name="publishingDecisionNumber"
                onChange={handleFormChange}
                value={archiveInfo.publishingDecisionNumber}
                placeholder={t("Publication_resolution_numbe")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("date_publication_decision")}</label>
              {false ? (
                <DatePicker
                  name="publishingDecisionDate"
                  value={convertDateToFormat(
                    archiveInfo.publishingDecisionDate || new Date()
                  )}
                  onChange={(date: any, event: any) =>
                    setArchiveInfo({
                      ...archiveInfo,
                      publishingDecisionDate: date,
                    })
                  }
                  dateFormat={"dd/MM/yyyy"}
                  calendar="hijri"
                />
              ) : (
                <DatePicker
                  name="publishingDecisionDate"
                  selected={moment()}
                  // value={convertDateToFormat(
                  //   archiveInfo.publishingDecisionDate || new Date()
                  // )}
                  // onChange={(date: any, event: any) =>
                  //   setArchiveInfo({
                  //     ...archiveInfo,
                  //     publishingDecisionDate: date,
                  //   })
                  // }
                  // dateFormat={"dd/MM/yyyy"}
                  calendar="hijri"
                />
              )}
            </div>
            <div className="main-form__input-container">
              <label>{t("rights_end_date")}</label>
              <DatePicker
                value={convertDateToFormat(
                  archiveInfo.rightsEndDate || new Date()
                )}
                name="rightsEndDate"
                onChange={(date: any, event: any) =>
                  setArchiveInfo({
                    ...archiveInfo,
                    rightsEndDate: date,
                  })
                }
                placeholderText={t("rights_end_date")}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("book_title")}</label>
              <input
                autoComplete="off"
                type="text"
                name="bookTitle"
                onChange={handleFormChange}
                value={archiveInfo.bookTitle}
                placeholder={t("book_title")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("num_parts")}</label>
              <InputNumber
                name="partsNumber"
                onChange={handleFormChange}
                value={archiveInfo.partsNumber}
                placeholder={t("num_parts")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("book_type")}</label>
              <input
                autoComplete="off"
                type="text"
                name="bookType"
                onChange={handleFormChange}
                value={archiveInfo.bookType}
                placeholder={t("book_type")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("en_book_title")}</label>
              <input
                autoComplete="off"
                type="text"
                name="foreignBookTitle"
                onChange={handleFormChange}
                value={archiveInfo.foreignBookTitle}
                placeholder={t("en_book_title")}
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>{t("Attach_publishing_decision")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
        </FormPanel>
        <FormPanel title={t("basic_info")} staticPanel={true}>
          <div className="col-4">
            <div className="main-form__input-container">
              <label>{t("author_foreign_book")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("author_foreign_book")}
                name="foreignAuthor"
                onChange={handleFormChange}
                value={archiveInfo.foreignAuthor}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("publisher_name_original")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("publisher_name_original")}
                name="originalPublisherName"
                onChange={handleFormChange}
                value={archiveInfo.originalPublisherName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("year_publication_foreign_book")}</label>
              <DatePicker
                value={convertDateToFormat(
                  archiveInfo.foreignPublishingYear || new Date()
                )}
                name="foreignPublishingYear"
                onChange={(date: any, event: any) =>
                  setArchiveInfo({
                    ...archiveInfo,
                    foreignPublishingYear: date,
                  })
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("ISBN_foreign_original")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("ISBN_foreign_original")}
                name="foreignOriginalISBN"
                onChange={handleFormChange}
                value={archiveInfo.foreignOriginalISBN}
              />
            </div>
            <div className="main-form__input-container">
              <label> {t("country")}</label>
              <select
                name="country"
                onChange={(e) => {
                  handleFormChange(e);
                  setSelectedCountry(e.target.value);
                }}
                value={archiveInfo.country}
              >
                {countries.map((e, i) => (
                  <option key={i}>{e.country}</option>
                ))}
              </select>
            </div>
            <div className="main-form__input-container">
              <label>{t("city")} </label>
              <select
                name="city"
                onChange={handleFormChange}
                value={archiveInfo.city}
              >
                {countries
                  .filter((obj) => obj.country === selectedCountry)[0]
                  ?.city.map((c, index) => (
                    <option key={index} value={c}>
                      {c}
                    </option>
                  ))}
              </select>
            </div>
            <div className="main-form__input-container">
              <label>{t("Translator_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Translator_name")}
                name="translatorName"
                onChange={handleFormChange}
                value={archiveInfo.translatorName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Editor_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder=""
                name="editorName"
                onChange={handleFormChange}
                value={archiveInfo.editorName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("manuscript_title")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("manuscript_title")}
                name="manuscriptTitle"
                onChange={handleFormChange}
                value={archiveInfo.manuscriptTitle}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Name_manuscript_author")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Name_manuscript_author")}
                name="manuscriptAuthor"
                onChange={handleFormChange}
                value={archiveInfo.manuscriptAuthor}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Manuscript_date")}</label>
              <DatePicker
                value={convertDateToFormat(
                  archiveInfo.manuscriptDate || new Date()
                )}
                name="manuscriptDate"
                onChange={(date: any, event: any) =>
                  setArchiveInfo({
                    ...archiveInfo,
                    manuscriptDateionDate: date,
                  })
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>{t("Attach_contract_publisher")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
        </FormPanel>
        <FormPanel title={t("basic_info")} staticPanel={true}>
          <div className="col-4">
            <div className="main-form__input-container">
              <label>{t("Manuscript_src")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Manuscript_src")}
                name="manuscriptSource"
                onChange={handleFormChange}
                value={archiveInfo.manuscriptSource}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("transcriber_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("transcriber_name")}
                name="transcriberName"
                onChange={handleFormChange}
                value={archiveInfo.transcriberName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("arbitrator_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("arbitrator_name")}
                name="investigatorName"
                onChange={handleFormChange}
                value={archiveInfo.investigatorName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Publisher_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Publisher_name")}
                name="publisherName"
                onChange={handleFormChange}
                value={archiveInfo.publisherName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("author_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("author_name")}
                name="authorName"
                onChange={handleFormChange}
                value={archiveInfo.authorName}
              />
            </div>
            <div className="main-form__input-container">
              <label> {t("country")}</label>
              <select name="Country"></select>
            </div>
            <div className="main-form__input-container">
              <label>{t("city")} </label>
              <select name="City"></select>
            </div>
            <div className="main-form__input-container">
              <label>{t("Thematic_classification")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Thematic_classification")}
                name="generalObjectiveClassification"
                onChange={handleFormChange}
                value={archiveInfo.generalObjectiveClassification}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("book_used")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("book_used")}
                name="bookUse"
                onChange={handleFormChange}
                value={archiveInfo.bookUse}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Course_number_code")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Course_number_code")}
                name="courseNumberCode"
                onChange={handleFormChange}
                value={archiveInfo.courseNumberCode}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Course_name")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("Course_name")}
                name="courseName"
                onChange={handleFormChange}
                value={archiveInfo.courseName}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("facluty")}</label>
              <input
                autoComplete="off"
                type="text"
                placeholder={t("facluty")}
                name="collegeDepartment"
                onChange={handleFormChange}
                value={archiveInfo.collegeDepartment}
              />
            </div>
          </div>
        </FormPanel>
        <FormPanel title={t("basic_info")} staticPanel={true}>
          <div className="col-4">
            <div className="main-form__input-container">
              <label>{t("Year_publication_Hijri")}</label>
              <DatePicker
                value={convertDateToFormat(
                  archiveInfo.hijriPublishingDate || new Date()
                )}
                name="hijriPublishingDate"
                onChange={(date: any, event: any) =>
                  setArchiveInfo({
                    ...archiveInfo,
                    hijriPublishingDatete: date,
                  })
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Year_publication_AD")}</label>

              <DatePicker
                placeholderText={t("Year_publication_AD")}
                value={convertDateToFormat(
                  archiveInfo.gregorianPublishingDate || new Date()
                )}
                name="gregorianPublishingDate"
                onChange={(date: any, event: any) =>
                  setArchiveInfo({
                    ...archiveInfo,
                    gregorianPublishingDate: date,
                  })
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("issue_number")}</label>
              <InputNumber
                placeholder={t("issue_number")}
                name="issueNumber"
                onChange={handleFormChange}
                value={archiveInfo.issueNumber}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("edition_number")}</label>
              <InputNumber
                placeholder={t("edition_number")}
                name="editionNumber"
                onChange={handleFormChange}
                value={archiveInfo.editionNumber}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Number_copies_printed")}</label>
              <InputNumber
                placeholder={t("Number_copies_printed")}
                name="printedcopiesNumber"
                onChange={handleFormChange}
                value={archiveInfo.printedcopiesNumber}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("ISPN")}</label>
              <InputNumber
                placeholder={t("ISPN")}
                name="isbn"
                onChange={handleFormChange}
                value={archiveInfo.isbn}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Dewey_Decimal_Classification")}</label>
              <InputNumber
                placeholder={t("Dewey_Decimal_Classification")}
                name="deweyDecimalClassification"
                onChange={handleFormChange}
                value={archiveInfo.deweyDecimalClassification}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Libraryt_deposit_number")}</label>
              <InputNumber
                placeholder={t("Libraryt_deposit_number")}
                name="nationalLibraryDepositNumber"
                onChange={handleFormChange}
                value={archiveInfo.nationalLibraryDepositNumber}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Marketing_classification")}</label>
              <InputNumber
                placeholder={t("Marketing_classification")}
                name="marketingClassification"
                onChange={handleFormChange}
                value={archiveInfo.marketingClassification}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("page_num")}</label>
              <InputNumber
                placeholder={t("page_num")}
                name="actualPagesNumber"
                onChange={handleFormChange}
                value={archiveInfo.actualPagesNumber}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("page_num_within_first")}</label>
              <InputNumber
                placeholder={t("page_num_within_first")}
                name="pageNumberWithoutFirsts"
                onChange={handleFormChange}
                value={archiveInfo.pageNumberWithoutFirsts}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("smak")}</label>
              <InputNumber
                placeholder={t("smak")}
                name="smackItemNumber"
                onChange={handleFormChange}
                value={archiveInfo.smackItemNumber}
              />
            </div>
          </div>
        </FormPanel>
        <FormPanel title={t("Marketing_data")} staticPanel={true}>
          <div className="col-3">
            <div className="main-form__input-container">
              <label>{t("period_smak")}</label>
              <InputNumber
                placeholder={t("period_smak")}
                name="periodInventory"
                onChange={handleFormChange}
                value={archiveInfo.periodInventory}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Available_balance")}</label>
              <InputNumber
                placeholder={t("Available_balance")}
                name="availableBalance"
                onChange={handleFormChange}
                value={archiveInfo.availableBalance}
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>{t("Library_letter")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("Certificate_deposit")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("Rejection_form")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("Acknowledgment_intellectual_property_rights")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
        </FormPanel>
        <FormPanel title={t("archive_data")} staticPanel={true}>
          <div className="col-4">
            <div className="main-form__input-container">
              <label>{t("Marketing_classification_code")}</label>
              <input
                autoComplete="off"
                type="text"
                name="marketingClassificationCode"
                onChange={handleFormChange}
                value={archiveInfo.marketingClassificationCode}
                placeholder={t("Marketing_classification_code")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Weight_grams")}</label>
              <InputNumber
                name="weight"
                onChange={handleFormChange}
                value={archiveInfo.weight}
                placeholder={t("Weight_grams")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("Cost_price")}</label>
              <InputNumber
                name="coast"
                onChange={handleFormChange}
                value={archiveInfo.cost}
                placeholder={t("Cost_price")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("selling_price")}</label>
              <InputNumber
                name="price"
                onChange={handleFormChange}
                value={archiveInfo.price}
                placeholder={t("selling_price")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("price_includes_tax")}</label>
              <InputNumber
                name="priceIncludesTax"
                onChange={handleFormChange}
                value={archiveInfo.priceIncludesTax}
                placeholder={t("price_includes_tax")}
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("notes")}</label>
              <input
                autoComplete="off"
                type="text"
                name="notes"
                onChange={handleFormChange}
                value={archiveInfo.notes}
                placeholder={t("notes")}
              />
            </div>
          </div>
          <div className="main-form__input-container">
            <label>{t("Translator_author_accreditation_form")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("Language_content_review_report")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("Technical_accreditation_card")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("book_ingesign")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("book_pdf")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
          <div className="main-form__input-container">
            <label>{t("book_word")}</label>
            <FilesUploader uploadPath="/" deletePath="/" />
          </div>
        </FormPanel>
        <FormPanel title={t("Marketing_data")} staticPanel={true}>
          <div className="col-2">
            <div className="col-2">
              <div className="main-form__input-container">
                <label>{t("size")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="size"
                  onChange={handleFormChange}
                  value={archiveInfo.size}
                  placeholder={t("size")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("color")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="color"
                  onChange={handleFormChange}
                  value={archiveInfo.color}
                  placeholder={t("color")}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="main-form__input-container">
                <label>{t("selling_price_does_not_include_tax")}</label>
                <InputNumber
                  name="priceWithoutTax"
                  onChange={handleFormChange}
                  value={archiveInfo.priceWithoutTax}
                  placeholder={t("selling_price_does_not_include_tax")}
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("Stock_period_according_smak")}</label>
                <InputNumber
                  name="smackPeriodStock"
                  onChange={handleFormChange}
                  value={archiveInfo.smackPeriodStock}
                  placeholder={t("Stock_period_according_smak")}
                />
              </div>
            </div>
            <div className="main-form__input-container">
              <label>{t("Introduction_book")}</label>
              <textarea
                name="bookIntroduction"
                onChange={handleFormChange}
                value={archiveInfo.bookIntroduction}
                placeholder={t("Introduction_book")}
              ></textarea>
            </div>
            <div className="main-form__input-container">
              <label>{t("Synopsis_book_chapters")}</label>
              <textarea
                name="bookChaptersIntroduction"
                onChange={handleFormChange}
                value={archiveInfo.bookChaptersIntroduction}
                placeholder={t("Synopsis_book_chapters")}
              ></textarea>
            </div>
          </div>
        </FormPanel>
        <div className="main-form__footer">
          <button
            type="button"
            className="btn"
            onClick={() =>
              archiveId
                ? UpdateArchive()
                : AddArchive(archiveInfo).then(() => {
                    navigate(-1);
                  })
            }
          >
            {t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Archive;
