import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  Competitor,
  PublicationForm,
  PublishingAuthor,
  PublishingOther,
  PublishingSimilar,
} from "../../types/publication";

// Define the initial state using that type
const initialState: PublicationForm = {
  titleChosenLanguage: "",
  specialistsOpinions: "",
  websiteRatings: "",
  religionConflict: "",
  politicsConflict: "",
  certificateName: "",
  originalPublisherName: "",
  originalPublisherCity: "",
  originalEditionNumber: 0,
  originalPublishingYear: new Date(),
  arabicCertificateName: "",
  university: "",
  country: "",
  city: "",
  briefSummary: "",
  graduationDate: new Date(),
  importance: "",
  originalBookPagesNumber: 0,
  acknowledgement: "single",
  additionalPublicationsType: "",
  applicationId: "",
  applicationName: "",
  approvedCopiesNumber: 0,
  arabicTitle: "",
  arbitrationForms: [],
  attachments: {},
  attachmentsType: [],
  authorHasAnotherPublications: 0,
  authorHasBooksWithSameTitle: 0,
  authoringLanguage: "arabic",
  originalAuthorName: "",
  bookGotRevision: 0,
  bookHasAlternative: 0,
  chaptersNumber: 0,
  completionRate: 0,
  contentsList: "",
  contractNotes: "",
  contractOptions: "بيع الحقوق كاملة لمدة غير محددة",
  contractType: "بيع الحقوق كاملة لمدة غير محددة",
  copiesNumber: 0,
  eighthCopy: "",
  englishTitle: "",
  arabicSummary: "",
  englishSummary: "",
  arabicKeywords: "",
  englishKeywords: "",
  expectedExpiryDate: new Date(),
  expectedOrderVolume: 0,
  expectedOrderVolumeNumber: 0,
  fieldId: "",
  fifthCopy: "",
  firstCopy: "",
  firstInvestigatorCity: "",
  firstInvestigatorCollege: "",
  firstInvestigatorCountry: "",
  firstInvestigatorEmail: "",
  firstInvestigatorName: "",
  firstInvestigatorOtherPhoneNumber: "",
  firstInvestigatorPOBox: "",
  firstInvestigatorPhoneNumber: "",
  firstInvestigatorPostalCode: "",
  firstInvestigatorScientificRank: "",
  firstInvestigatorScientificSpecialization: "",
  firstInvestigatorUniversity: "",
  fourthCopy: "",
  goal: " مرجع علمي",
  hasArbitrators: 0,
  hasAttachments: 0,
  hasMatch: 0,
  hasOtherPublishings: 0,
  hasSameTopic: 0,
  id: 0,
  investigatorCity: "",
  investigatorCollege: "",
  investigatorCountry: "",
  investigatorEmail: "",
  investigatorName: "",
  investigatorOtherPhoneNumber: "",
  investigatorPOBox: "",
  investigatorPhoneNumber: "",
  investigatorPostalCode: "",
  investigatorScientificRank: "",
  investigatorScientificSpecialization: "",
  investigatorUniversity: "",
  isBookCompleted: "مكتمل",
  isComplete: 1,
  isPostedBefore: 0,
  isRelatedToAuthorSpecialization: 1,
  isRevisedOrExpanded: 0,
  isViewMode: false,
  language: "",
  mainIdea: "",
  manuscriptAuthor: "",
  manuscriptPartsNumber: 0,
  manuscriptTitle: "",
  ninthCopy: "",
  otherLanguageTitle: "",
  otherTargetAudience: "",
  otherTypes: "",
  otherGoal: "",
  organizerBrief: "",
  pagesNumber: 0,
  partsNumber: 0,
  previousInvestigatorName: "",
  previousPublisherName: "",
  previousPublishingDate: new Date(),
  previousPublishingDemandVolume: 0,
  previousPublishingEditionsNumber: 0,
  previousPublishingEndDate: new Date(),
  proposalId: "",
  publishingArbitrationCriterias: [],
  publishingArbitrators: [],
  publishingAuthors: [],
  publishingCompetitors: [],
  publishingFeedbacks: [],
  publishingMatchingForm: {},
  finalVersion: "",
  publishingCandidateArbitrators: [],
  publishingOthers: [],
  publishingReasonRejects: [],
  publishingSimilars: [],
  reasonsRepublishing: "",
  remainDaysForCompletion: 0,
  revisedOrExpandedDetails: "",
  revisionRate: "",
  sampleDescription: "",
  scientificMethod: "",
  scientificStanding: "",
  scientificValue: "",
  secondCopy: "",
  seventhCopy: "",
  similarAndDifferentWithSuggest: "",
  sixthCopy: "",
  specializationId: 0,
  startArbitration: false,
  subjectInAuthorExpertiseArea: 1,
  targetAudience: [],
  tenthCopy: "",
  thirdCopy: "",
  topic: "",
  writingReasons: "",
};

const publishingCompetitor: Competitor = {
  id: 0,
  title: "",
  publisher: "",
  editionsNumber: 0,
  difference: "",
  publishingDate: new Date(),
};
const publishingAuthors: PublishingAuthor = {
  id: 0,
  name: "",
  side: "",
  department: "",
  degree: "",
  scientificSpecialization: "",
  university: "",
  college: "",
  city: "",
  postbox: "",
  postalCode: "",
  country: "Saudi Arabia",
  mobileNumber: "",
  landlineNumber: "",
  email: "",
  attachments: [],
};
const authorOtherPublicationSameTitle: PublishingSimilar = {
  id: 0,
  title: "",
  publishingPlace: "",
  publishingDate: new Date(),
  publisher: "",
};

const publishingOther: PublishingOther = {
  id: 0,
  title: "",
  publisher: "",
  publishingPlace: "",
  publishingDate: new Date(),
  type: "مؤلف",
};

const publicationDetailsSlice = createSlice({
  name: "publicationDetails",
  initialState,
  reducers: {
    storePublicationDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    modifyPublicationDetails: (state, action) => {
      const key: string = action.payload.key;
      let val = action.payload.val;
      const dynamicFormKey:
        | "publishingCompetitors"
        | "publishingAuthors"
        | "publishingSimilars" = action.payload.dynamicFormKey;
      const i = action.payload.i;
      if (key === "fieldId") {
        return {
          ...state,
          [key]: val,
          specializationId: 0,
          proposalId: "",
          applicationId: "",
        };
      } else if (key === "specializationId") {
        return {
          ...state,
          [key]: val,
          proposalId: "",
          applicationId: "",
        };
      } else if (key === "proposalId") {
        return {
          ...state,
          [key]: val,
          applicationId: "",
        };
      } else if (key === "hasAttachments") {
        if (val === 0) {
          return {
            ...state,
            hasAttachments: 0,
            attachmentsType: [],
          };
        } else {
          return {
            ...state,
            hasAttachments: 1,
            attachmentsType: ["بنك للأسئلة"],
          };
        }
      } else if (dynamicFormKey && typeof i === "number") {
        const modifiedArray = state[dynamicFormKey].map((v: any, idx: number) =>
          idx === i ? { ...v, [key.split("_")[0]]: val } : v
        );
        return {
          ...state,
          [dynamicFormKey]: modifiedArray,
        };
      } else if (key === "targetAudience" || key === "attachmentsType") {
        // checkbox state
        if (
          key === "targetAudience" &&
          val === "تعليم ما قبل الجامعه" &&
          !state[key].includes("تعليم ما قبل الجامعه")
        ) {
          state[key] = [val];
        } else {
          state[key].includes(val)
            ? (state[key] = state[key].filter((el) => el !== val))
            : state[key].push(val);
        }
        return;
      } else if (key === "publishingArbitrators") {
        state[key].filter(
          (a) => a.proposedArbitratorId === val.proposedArbitratorId
        ).length
          ? (state[key] = state[key].filter(
              (el) => el.proposedArbitratorId !== val.proposedArbitratorId
            ))
          : state[key].push(val);
        return;
      }

      if (key === "isPostedBefore") {
        if (val === 0) {
          return {
            ...state,
            previousPublisherName: "",
            previousPublishingDate: new Date(),
            previousPublishingEndDate: new Date(),
            previousPublishingEditionsNumber: 0,
            previousPublishingDemandVolume: 0,
            isPostedBefore: +val,
          };
        }
      }

      if (key === "isRevisedOrExpanded") {
        if (val === 0) {
          return {
            ...state,
            isRevisedOrExpanded: 0,
            revisedOrExpandedDetails: "",
          };
        }
      }
      return {
        ...state,
        [key]: val,
      };
    },
    resetPublicationDetails: (state) => {
      return initialState;
    },

    // Start Manage Dynamic Form

    // 1 - Publishing Competitors
    addPublishingCompetitor: (state) => {
      return {
        ...state,
        publishingCompetitors: [
          ...state.publishingCompetitors,
          publishingCompetitor,
        ],
      };
    },
    removePublishingCompetitor: (state, action) => {
      return {
        ...state,
        publishingCompetitors: [
          ...state.publishingCompetitors.filter(
            (_, i: number) => i !== action.payload.removeId
          ),
        ],
      };
    },
    // 2 - Publishing Authorss

    addPublishingAuthor: (state) => {
      return {
        ...state,
        publishingAuthors: [...state.publishingAuthors, publishingAuthors],
      };
    },
    removePublishingAuthor: (state, action) => {
      return {
        ...state,
        publishingAuthors: [
          ...state.publishingAuthors.filter(
            (_, i: number) => i !== action.payload.removeId
          ),
        ],
      };
    },

    // 3 - Author Has Books With Same Title

    addOtherPublicationSameTitle: (state) => {
      return {
        ...state,
        publishingSimilars: [
          ...state.publishingSimilars,
          authorOtherPublicationSameTitle,
        ],
      };
    },
    removeOtherPublicationSameTitle: (state, action) => {
      return {
        ...state,
        publishingSimilars: [
          ...state.publishingSimilars.filter(
            (_, i: number) => i !== action.payload.removeId
          ),
        ],
      };
    },

    // 4 - Publishing Others

    addPublishingOther: (state) => {
      return {
        ...state,
        publishingOthers: [...state.publishingOthers, publishingOther],
      };
    },
    removePublishingOther: (state, action) => {
      return {
        ...state,
        publishingOthers: [
          ...state.publishingOthers.filter(
            (_, i: number) => i !== action.payload.removeId
          ),
        ],
      };
    },
    // End Manage Dynamic Form

    toggleViewMode: (state, action) => {
      return { ...state, isViewMode: action.payload };
    },
  },
});

export const selectPublicationDetails = (state: RootState) =>
  state.publicationDetails;
export const {
  storePublicationDetails,
  modifyPublicationDetails,
  resetPublicationDetails,
  addPublishingCompetitor,
  removePublishingCompetitor,
  addPublishingAuthor,
  removePublishingAuthor,
  addOtherPublicationSameTitle,
  removeOtherPublicationSameTitle,
  addPublishingOther,
  removePublishingOther,
  toggleViewMode,
} = publicationDetailsSlice.actions;
export default publicationDetailsSlice.reducer;
