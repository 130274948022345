import axios from "axios";

export const GetFields = () => {
  return axios.get("fields");
};

export const AddFields = (obj: any, proposalId: any) => {
  return axios.post("fields", obj, proposalId);
};
export const EditFields = (id: any, field: any, proposalId: any) => {
  return axios.put(`fields/${id}`, field, proposalId);
};
export const DeleteFields = (id: any, field: any) => {
  return axios.delete(`fields/${id}`, field);
};

export const EnableDisableField = (id: any, field: any) => {
  return axios.patch(`fields/${id}/ToggleActivate`, {
    ...field,
    isActive: !field.isActive,
  });
};
