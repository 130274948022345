import { Popup } from "components/Popup/Popup";
import { REJECTION_REASONS } from "../constants";
import { pushAlert, selectAlert } from "store/states/alertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

interface RejectionFormPopupProps {
  setShowRejectionForm: (type: boolean) => void;
  showRejectionForm: boolean;
  setSelectedRejectionReasons: (reasons: string[]) => void;
  selectedRejectionReasons: string[];
}

export const RejectionFormPopup = (props: RejectionFormPopupProps) => {
  const dispatch = useAppDispatch();
  const { onAcceptBody } = useAppSelector(selectAlert);
  const { t } = useTranslation();

  const {
    setShowRejectionForm,
    showRejectionForm,
    setSelectedRejectionReasons,
    selectedRejectionReasons,
  } = props;
  return (
    <Popup
      onClose={() => {
        setShowRejectionForm(false);
      }}
      showPopup={showRejectionForm}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowRejectionForm(false);
          dispatch(
            pushAlert({
              show: true,
            })
          );
        }}
        className="modern-form"
      >
        <h2> {t("Please_choose_reasons_refusal")}</h2>

        <div className="comments-container__arbitratos-selection">
          <div className="table-responsive">
            <table className="basic-table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>{t("serial_number")}</th>
                  <th></th>
                  <th>{t("reason")}</th>
                </tr>
              </thead>
              <tbody>
                {REJECTION_REASONS.map((r, i) => (
                  <tr key={r}>
                    <td>{i + 1}</td>
                    <td>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name=""
                        id=""
                        onClick={() => {
                          const idx = selectedRejectionReasons.indexOf(r);
                          if (idx > -1) {
                            const temp = [...selectedRejectionReasons];
                            temp.splice(idx, 1);
                            setSelectedRejectionReasons(temp);
                          } else {
                            setSelectedRejectionReasons([
                              ...selectedRejectionReasons,
                              r,
                            ]);
                          }
                        }}
                      />
                    </td>
                    <td className="break-td">{r}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="main-form__input-container">
          <textarea
            placeholder={t("Write_comments")}
            name="comment"
            onChange={(e) => {
              dispatch(
                pushAlert({
                  onAcceptBody: { ...onAcceptBody, comment: e.target.value },
                })
              );
            }}
          ></textarea>
        </div>
        <div className="modern-form__footer">
          <button>{t("rej_req")}</button>
        </div>
      </form>
    </Popup>
  );
};
