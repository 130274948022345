import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Breadcrumb.scss";

export const Breadcrumb = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const [urlItems, setUrlItems] = useState<string[]>([]);

  useEffect(() => {
    setUrlItems(window.location.pathname.slice(1).split("/"));
  }, [window.location.pathname]);

  const renderBreadcrumb = () => {
    return (
      <ul className="no-print">
        {urlItems.map((item: string, i: number) => (
          <li key={i}>{t(item)}</li>
        ))}
      </ul>
    );
  };
  return (
    <div className="breadcrumb">
      {renderBreadcrumb()} {children}
    </div>
  );
};
