import React from "react";

export const FormPanel = (
  props: React.PropsWithChildren<{
    title: string;
    staticPanel?: boolean;
    headerButton?: any;
  }>
) => {
  return (
    <div
      className={`main-form__panel ${
        props.staticPanel ? "" : "form-panel-selector hide"
      }`}
    >
      <h3 className={props.headerButton ? "f-box" : ""}>
        {props.title} {props.headerButton}
      </h3>
      {props.children}
    </div>
  );
};
