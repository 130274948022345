import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

// Define a type for the slice state
interface AuthState {
  isAuth: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  isAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_details");
    },
  },
});

export const selectAuth = (state: RootState) => state.auth.isAuth;
export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
