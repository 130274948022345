import { Popup } from "components/Popup/Popup";

import { FormPanel } from "components/FormPanel";
import { useAppSelector } from "store/hooks";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { useTranslation } from "react-i18next";

interface RejectionReasonsPopupProps {
  setShowRejectionReasonsPopup: (type: boolean) => void;
  showRejectionReasonsPopup: boolean;
}

export const RejectionReasonsPopup = (props: RejectionReasonsPopupProps) => {
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { t } = useTranslation();
  const { setShowRejectionReasonsPopup, showRejectionReasonsPopup } = props;
  return (
    <Popup
      onClose={() => {
        setShowRejectionReasonsPopup(false);
      }}
      showPopup={showRejectionReasonsPopup}
    >
      <div className="comments-container">
        {publicationDetails.publishingReasonRejects?.map((r: any, i) => (
          <FormPanel
            title={`${t("member")}: ${t(r.scientificCommitteeMemberName)}`}
            staticPanel={true}
            key={r.scientificCommitteeMemberId + i}
          >
            <div className={`main-form__input-container`}>
              <table className="basic-table">
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{new Date(r.createdAt).toLocaleDateString()}</td>
                    <td>{new Date(r.createdAt).toLocaleTimeString()}</td>
                  </tr>
                </tbody>
              </table>
              <table className="basic-table">
                <thead>
                  <tr>
                    <th colSpan={2}>{t("Reasons_refusal")}</th>
                  </tr>
                </thead>
                <tbody>
                  {r.reasons.map((reason: any) => (
                    <tr key={reason}>
                      <td colSpan={2} className="break-td">
                        {reason}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </FormPanel>
        ))}
      </div>
    </Popup>
  );
};
