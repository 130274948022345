import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true, // Enable responsiveness
  aspectRatio: 1.5,
  plugins: {
    legend: {
      display: false, // Remove legend
    },
    filler: {
      propagate: true,
    },
    shadow: {
      enabled: true,
      color: "rgba(0, 0, 0, 0.2)", // Shadow color
      blur: 10, // Shadow blur value
      offsetY: 10, // Vertical offset of the shadow
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
  scales: {
    x: {
      display: false, // Remove x-axis
    },
    y: {
      display: false, // Remove y-axis
      beginAtZero: true,
    },
  },
};

const LineCard = (props: {
  data: number[];
  growth: "1" | "-1";
  mainTitle: string;
  subTitle: string;
  percentage: number;
  value: number;
}) => {
  const { data, growth, mainTitle, subTitle, percentage, value } = props;

  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Dataset",
        data: data,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(
            0,
            growth === "1" ? "rgb(12 172 91 / 10%)" : "rgb(252 46 32 / 10%)"
          );
          gradient.addColorStop(
            1,
            growth === "1" ? "rgb(12 172 91 / 0%)" : "rgb(252 46 32 / 0%)"
          );
          return gradient;
        },
        borderColor: growth === "1" ? "#0cac5b" : "#fc2e20",
        borderWidth: 2,
        pointRadius: 0,
        fill: "start",
      },
    ],
  };
  return (
    <div className="analysis-card">
      <h4 className="title__analysis-card">{mainTitle}</h4>
      <div className="content">
        <div className="data">
          <div className={`data-numbers ${growth === "1" ? "green" : "red"}`}>
            {value}
            <span>
              <FontAwesomeIcon
                icon={growth === "1" ? faArrowTrendUp : faArrowTrendDown}
              />
              {percentage + "%"}
            </span>
          </div>
          <h6>{subTitle}</h6>
        </div>
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export default LineCard;
