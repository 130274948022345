import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./states/authSlice";
import userDetailsReducer from "./states/userDetailsSlice";
import publicationDetailsReducer from "./states/publicationDetailsSlice";
import loadingReducer from "./states/loadingSlice";
import formTypeReducer from "./states/formTypeSlice";
import alertReducer from "./states/alertSlice";
import profileReducer from "./states/profileSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userDetails: userDetailsReducer,
    publicationDetails: publicationDetailsReducer,
    loading: loadingReducer,
    formType: formTypeReducer,
    alert: alertReducer,
    profile: profileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
