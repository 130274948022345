import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import DatePicker from "react-datepicker";

import { Popup } from "../../../components/Popup/Popup";
import {
  GetPublications,
  RemovePublication,
} from "services/publications.service";
import { Publication } from "../../../types/publication";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { AssignNewEditor, GetUsers } from "services/user.service";
import "../PublicationsList/PublicationsList.scss";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import convertDateToFormat from "../../../utils/convertDateToFormat";
import { selectFormType } from "store/states/formTypeSlice";

export const PublicationsList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const formType = useAppSelector(selectFormType);
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const { t } = useTranslation();
  const [publications, setPublications] = useState<Publication[]>([]);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showArchivedList, setShowArchivedList] = useState(false);
  const [publicationIdToDelete, setPublicationIdToDelete] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDate, setSearchTermDate] = useState<Date | null>(null);
  const [editorsList, setEditorsList] = useState([]);
  const [showAssignEditorPopup, setShowAssignEditorPopup] = useState(false);
  const [modifiedApplicationProps, setModifiedApplicationProps] = useState({
    editorId: 0,
    applicationId: 0,
  });
  let navigate = useNavigate();
  const { role, isApplicant, isManager, isAdmin } =
    useAppSelector(selectUserDetails);
  useEffect(() => {
    getPublications();
  }, [showArchivedList]);
  const { isEditor } = useAppSelector(selectUserDetails);
  const getPublications = () => {
    GetPublications(!showArchivedList).then((response) => {
      setPublications(response.data);
    });
  };

  const getEditors = (publication: Publication) => {
    GetUsers("Editor", publication.fieldId.toString()).then((response) => {
      setEditorsList(response.data);
      setModifiedApplicationProps({
        applicationId: publication.id,
        editorId: response.data[0].id,
      });
    });
  };
  const handleAssignEditor = (e: any) => {
    e.preventDefault();
    AssignNewEditor(
      modifiedApplicationProps.editorId,
      modifiedApplicationProps.applicationId
    ).then(() => {
      setShowAssignEditorPopup(false);
      getPublications();
    });
  };

  const renderPublications = () => {
    let filterdPublicationsList = publications.filter(
      (q: any) =>
        String(q.id).includes(searchTerm) ||
        q.specializationName?.includes(searchTerm) ||
        q.fieldName.includes(searchTerm) ||
        q.proposalName.includes(searchTerm) ||
        q.editorName?.includes(searchTerm)
    );
    if (searchTermDate) {
      filterdPublicationsList = filterdPublicationsList.filter(
        (q) =>
          convertDateToFormat(q.createdAt) ===
          convertDateToFormat(searchTermDate)
      );
    }
    const openApplication = (
      viewMode: Publication["viewMode"],
      id: number,
      isPrintViewMode = false
    ) => {
      const tempRole = role === "Author" ? "applicant" : role;
      navigate(
        `/${tempRole.toLocaleLowerCase().replaceAll(" ", "_")}/publications/${
          viewMode === "Attachments" ? "view_attachments" : "view"
        }?publishingId=${id}&&isPrintViewMode=${isPrintViewMode}`
      );
    };
    return filterdPublicationsList.map((publication) => (
      <tr
        key={publication.id}
        style={isEditor && publication.hasDelay ? { color: "red" } : {}}
      >
        <td>{publication.id}</td>
        <td>
          {publication.applicationName === "ق-4"
            ? publication.manuscriptTitle
            : publication.arabicTitle}
        </td>
        <td>{publication.fieldName}</td>
        <td>{publication.specializationName}</td>
        <td>{publication.proposalName}</td>
        <td>{new Date(publication.createdAt).toLocaleDateString("en-US")}</td>
        <td>{publication.status}</td>
        <td>{publication.editorName}</td>
        <td>
          {publication.status !== "Draft" && (
            <>
              <button
                className="btn normal"
                onClick={() =>
                  openApplication(publication.viewMode, publication.id)
                }
              >
                {t("view")}
              </button>
              <button
                className="btn success"
                onClick={() =>
                  openApplication(publication.viewMode, publication.id, true)
                }
              >
                {t("Review_print")}
              </button>
            </>
          )}

          {isApplicant && (
            <>
              {["Draft", "Editor Updates"].includes(publication.status) && (
                <button
                  className="btn edit"
                  onClick={() => navigate(`add?publishingId=${publication.id}`)}
                >
                  {publication.status === "Draft"
                    ? t("continue")
                    : t("Complete_editor_edits")}
                </button>
              )}
              {["Draft"].includes(publication.status) && (
                <button
                  className="btn delete"
                  onClick={() => {
                    setPublicationIdToDelete(publication.id);
                    setShowRemovePopup(true);
                  }}
                >
                  {t("delete")}
                </button>
              )}
            </>
          )}

          {publication.finalVersion && (
            <button
              className="btn edit"
              onClick={() =>
                window.open(baseURL + publication.finalVersion, "_blank")
              }
            >
              {t("view_final_version")}
            </button>
          )}

          {isManager && (
            <button
              className="btn edit"
              onClick={() => {
                getEditors(publication);
                setShowAssignEditorPopup(true);
              }}
            >
              {t("edit_editor")}
            </button>
          )}
          {(isManager || isAdmin) && (
            <button
              className="btn delete"
              onClick={() => {
                setPublicationIdToDelete(publication.id);
                setShowRemovePopup(true);
              }}
            >
              {t("delete")}
            </button>
          )}
        </td>
        <td>{isEditor && publication.hasDelay && t("request_delayed")}</td>
      </tr>
    ));
  };

  return (
    <div className="container">
      <Breadcrumb>
        <div className="breadcrumb__filters">
          <div className="publication-crumb col-3">
            <input
              autoComplete="off"
              type="search"
              placeholder={t("search_here")}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <DatePicker
              placeholderText="dd/mm/yyyy"
              selected={searchTermDate}
              onChange={(date) => setSearchTermDate(date)}
              dateFormat={"dd/MM/yyyy"}
            />
            {isApplicant ? (
              <NavLink className="as-btn text-center" to="add">
                {t("Add_posting_request")}
              </NavLink>
            ) : (
              <button
                className="btn"
                onClick={() => setShowArchivedList(!showArchivedList)}
              >
                {showArchivedList ? t("list") : t("previous_requests")}
              </button>
            )}
          </div>
        </div>
      </Breadcrumb>
      <div className="table-responsive">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("request_number")} </th>
              <th>{t("book_name")}</th>
              <th>{t("field")}</th>
              <th>{t("Specialization")}</th>
              <th>{t("Suggestion")}</th>
              <th> {t("request_date")}</th>
              <th> {t("request_status")}</th>
              <th>{t("Editor_name")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {publications.length ? (
              renderPublications()
            ) : (
              <tr>
                <td colSpan={9}>{t("no_data_show")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowRemovePopup(false);
        }}
        showPopup={showRemovePopup}
      >
        <div className="popup__remove">
          <p>
            {t("delete_request_surely")}
            <br />
            {t("request_number")} : <b>{publicationIdToDelete}</b>
          </p>
          <div>
            <button
              className="btn normal"
              onClick={() => {
                setShowRemovePopup(false);
              }}
            >
              {t("close")}
            </button>
            <button
              className="btn delete"
              onClick={() => {
                RemovePublication(publicationIdToDelete).then(() => {
                  setShowRemovePopup(false);
                  setPublications(
                    publications.filter(
                      (publication) => publication.id != publicationIdToDelete
                    )
                  );
                });
              }}
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        onClose={() => {
          setShowAssignEditorPopup(false);
        }}
        showPopup={showAssignEditorPopup}
      >
        <form
          autoComplete="off"
          onSubmit={handleAssignEditor}
          className="modern-form"
        >
          <h2>{t("Attach_editor_request")}</h2>
          <div className={"col-1"}>
            <div className="modern-form__input-container">
              <label>{t("role")}</label>
              <select
                name="role"
                value={modifiedApplicationProps.editorId}
                onChange={(e) =>
                  setModifiedApplicationProps({
                    ...modifiedApplicationProps,
                    editorId: +e.target.value,
                  })
                }
              >
                {editorsList.map((editor: any) => (
                  <option key={editor.id} value={editor.id}>
                    {editor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modern-form__footer">
            <button>{t("add")}</button>
          </div>
        </form>
      </Popup>
    </div>
  );
};
