export const REJECTION_REASONS = [
  "المنتج العلمي ليس ضمن المجالات المشمولة في خطة النشر بالدار.",
  "المنتج العلمي لا يُمثل إضافة علمية أو تطبيقية أو ثقافية متميزة في مجاله.",
  "المنتج العلمي غير ذا جدوى اقتصادية.",
  "المنتج العلمي ليس ملتزماً بالأمانة العلمية في النقل والاستشهاد والاقتباس.",
  "لم يجتاز فحص التشابه والانتحال بحسب تقدير اللجنة العلمية.",
  "المنتج العلمي منشوراً أو مقدماً للنشر في الجهة أخرى. ",
  "المنتج العلمي مستلاً من أعمال منشورة سابقاً للمؤلف.",
  "المنتج العلمي لم يجتز التحكيم المتعارف عليه أكاديمياً.",
  "لا تمتلك الدار الحقوق الفكرية للمنتج العلمي، وفق التفصيلات المذكورة في عقد النشر الموقع مع الطرف/ الأطراف ذات العلاقة.",
  "المحتوى العلمي يتعارض مع القيم الدينية والثقافية والاجتماعية والأنظمة المرعية في المملكة العربية السعودية.",
  "صفحات المنتج العلمي تزيد عن 500 صفحة نموذجية (للدار الاستثناء من ذلك في حالات محددة تقتضيها طبيعة المنتج).",
];
