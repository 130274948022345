import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { Popup } from "components/Popup/Popup";
import { useTranslation } from "react-i18next";
import "../Specializations/Specializations.scss";
import {
  AddSpecializations,
  EditSpecialization,
  GetSpecializations,
} from "services/specializations.service";
import { GetFields } from "services/fields.service";
export const Specializations = () => {
  const { t } = useTranslation();
  const [specializationsList, setSpecializationsList] = useState<any>([]);
  const [selectedSpecializationForUpdate, setSelectedSpecializationForUpdate] =
    useState({
      id: 0,
      fieldId: 0,
      name: "",
      category: "",
    });
  const getSpecializations = () => {
    GetSpecializations().then((res) => {
      setSpecializationsList(res.data);
    });
  };

  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedSpecializationForUpdate({
      ...selectedSpecializationForUpdate,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    getSpecializations();
    GetFields().then((res) => setFields(res.data));
  }, []);

  const renderFields = () => {
    return fields.map((ele: any, i: number) => (
      <option key={ele.id} value={ele.id}>
        {ele.name}
      </option>
    ));
  };

  return (
    <div className="container">
      <Breadcrumb>
        <button
          onClick={() => {
            setShowAddEditPopup(true);
            setSelectedSpecializationForUpdate({
              ...selectedSpecializationForUpdate,
              fieldId: fields[0].id,
              name: "",
              category: "",
            });
          }}
        >
          {t("add_specializations")}
        </button>
      </Breadcrumb>
      <div className="specializations-list">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("field")}</th>
              <th>{t("specializations")}</th>
              <th>{t("category")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {specializationsList.map((specialization: any, i: any) => (
              <tr key={i}>
                <td>{specialization.fieldName}</td>
                <td>{specialization.name}</td>
                <td>{specialization.category}</td>
                <td>
                  <button
                    className="btn edit"
                    onClick={() => {
                      setShowAddEditPopup(true);
                      setIsEditMode(true);
                      setSelectedSpecializationForUpdate({
                        id: specialization.id,
                        fieldId: specialization.fieldId,
                        name: specialization.name,
                        category: specialization.category,
                      });
                    }}
                  >
                    {t("Edit")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowAddEditPopup(false);
        }}
        showPopup={showAddEditPopup}
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            if (isEditMode) {
              EditSpecialization(
                selectedSpecializationForUpdate.id,
                selectedSpecializationForUpdate
              ).then(() => {
                getSpecializations();
                setShowAddEditPopup(false);
              });
            } else {
              AddSpecializations().then(() => {
                getSpecializations();
                setShowAddEditPopup(false);
              });
            }
          }}
          className="modern-form"
        >
          <div className="modern-form__input-container">
            <label>{t("specialization")}</label>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={selectedSpecializationForUpdate.name}
              placeholder={t("specialization")}
              onChange={handleFormChange}
              autoFocus
            />
            <label>{t("category")}</label>
            <input
              autoComplete="off"
              type="text"
              name="category"
              value={selectedSpecializationForUpdate.category}
              placeholder={t("category")}
              onChange={handleFormChange}
              autoFocus
            />
          </div>
          <div className="modern-form__input-container">
            <label>{t("field")}</label>
            <select
              name="fieldId"
              onChange={(e) =>
                setSelectedSpecializationForUpdate({
                  ...selectedSpecializationForUpdate,
                  fieldId: +e.target.value,
                })
              }
              placeholder={t("field")}
              autoFocus
            >
              {renderFields()}
            </select>
          </div>
          <div className="modern-form__footer">
            <button>{t("save")}</button>
          </div>
        </form>
      </Popup>
    </div>
  );
};
