import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

// Define a type for the slice state
interface LoadingState {
  requestsList: string[];
}

// Define the initial state using that type
const initialState: LoadingState = {
  requestsList: [],
};

export const loadingSlice = createSlice({
  name: "loading",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    trackPromises: (state, action) => {
      const url = action.payload.url;
      const type = action.payload.type;
      if (type === "add" && !state.requestsList.includes(url)) {
        return { requestsList: [...state.requestsList, url] };
      }
      if (type === "remove" && state.requestsList.includes(url)) {
        return {
          requestsList: state.requestsList.filter(
            (requestURL) => requestURL !== url
          ),
        };
      }

      return state;
    },
  },
});

export const selectLoading = (state: RootState) => state.loading.requestsList;
export const { trackPromises } = loadingSlice.actions;
export default loadingSlice.reducer;
