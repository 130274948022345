import { GetArchiveList } from "services/archive_employee.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import "../ArchiveList/ArchiveList.scss";
import ReactDatePicker from "react-datepicker";
import convertDateToFormat, {
  getDayMonthYear,
} from "utils/convertDateToFormat";

const ArchiveList = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    getArchiveList();
  }, []);
  let navigate = useNavigate();

  const getArchiveList = () => {
    GetArchiveList().then((res) => setList(res.data));
  };
  const { t } = useTranslation();
  const [searchTermDate, setSearchTermDate] = useState<Date | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const openApplication = (id: number) => {
    navigate(`/archive_employee/publications/view?publishingId=${id}`);
  };
  const RenderList = () => {
    let filterdPublicationsList = list.filter(
      (q: any) =>
        !q.bookTitle ||
        q.bookTitle?.toLowerCase()?.includes(searchTerm) ||
        !q.bookType ||
        q.bookType?.toLowerCase()?.includes(searchTerm) ||
        !q.publisherName ||
        q.publisherName?.toLowerCase()?.includes(searchTerm) ||
        !q.publishingId ||
        q.publishingId === searchTerm
    );
    if (searchTermDate) {
      filterdPublicationsList = filterdPublicationsList.filter(
        (q: any) =>
          convertDateToFormat(q.hijriPublishingDate) ===
          convertDateToFormat(searchTermDate)
      );
    }

    return filterdPublicationsList.map((element: any, i) => (
      <tr key={i}>
        <td>{element.publishingId}</td>
        <td>{element.bookTitle}</td>
        <td>{element.bookType}</td>
        <td>{element.publisherName}</td>
        <td>{getDayMonthYear(element.hijriPublishingDate)}</td>
        <td>
          <button
            className='btn edit'
            onClick={() => navigate(`${element.id}`)}
          >
            {t("view_archive")}
          </button>
          {element.publishingId && (
            <button
              className='btn normal'
              onClick={() => openApplication(element.publishingId)}
            >
              {t("view_application")}
            </button>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className='container'>
      <Breadcrumb>
        <div className='breadcrumb__filters'>
          <input
            autoComplete='off'
            type='search'
            placeholder={t("search_here")}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <ReactDatePicker
            placeholderText='dd/mm/yyyy'
            selected={searchTermDate}
            onChange={(date) => setSearchTermDate(date)}
            dateFormat={"dd/MM/yyyy"}
          />
          <Link className='as-btn' to={"add"}>
            {t("add_archive")}
          </Link>
        </div>
      </Breadcrumb>
      <table className='basic-table' cellSpacing={0}>
        <thead>
          <tr>
            <th>{t("publishing_id")}</th>
            <th>{t("book_name")}</th>
            <th>{t("book_type")}</th>
            <th>{t("Publisher_name")}</th>
            <th>{t("Hijri_date")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.length ? (
            RenderList()
          ) : (
            <tr>
              <td colSpan={5}>no data show</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ArchiveList;
