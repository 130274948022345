import Divider from "components/Divider";
import { FormPanel } from "components/FormPanel";
import { Popup } from "components/Popup/Popup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddProposedArbitrators,
  GetProposedArbitrators,
} from "services/proposed_arbitrators.service";
import countries from "../../../../../../assets/countries.json";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { modifyFormState } from "store/states/formTypeSlice";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { Arbitrator } from "types/arbitrator";
export const NomationArbitrators = () => {
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const [arbitrators, setArbitrators] = useState<Arbitrator[]>([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("Saudi Arabia");
  const [selectedCity, setSelectedCity] = useState<any>("Riyadh");
  const SelectedproposalArbitratorTemp = {
    id: 0,
    doerId: 0,
    isActive: true,
    isDeleted: true,
    email: "",
    phoneNumber: "",
    fullNameArabic: "",
    fullNameEnglish: "",
    destinationCollege: "",
    destinationSection: "",
    degree: "",
    scientificSpecialization: publicationDetails.specializationId?.toString(),
    exactScientificSpecialization: "",
    scientificRank: "",
    poBox: "",
    postalCode: "",
    city: selectedCity,
    country: selectedCountry,
    userId: 0,
    fieldId: publicationDetails.fieldId,
  };
  const [selectedProposalArbitrators, setSelectedProposalArbitrators] =
    useState<any>(SelectedproposalArbitratorTemp);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isApplicant } = useAppSelector(selectUserDetails);

  useEffect(() => {
    getArbitrators();
    setSelectedProposalArbitrators({
      ...selectedProposalArbitrators,
      fieldId: publicationDetails.fieldId,
      scientificSpecialization: publicationDetails.specializationId?.toString(),
    });
  }, [publicationDetails.fieldId]);

  const getArbitrators = async () => {
    if (publicationDetails.fieldId) {
      const res = await GetProposedArbitrators(publicationDetails.fieldId);
      setArbitrators(res.data);
    }
  };

  const renderArbitrators = () => {
    const selectedArbitrators = !isApplicant
      ? arbitrators.filter((arbitrator) =>
          publicationDetails.publishingArbitrators.some(
            (selectedArbitrator) =>
              selectedArbitrator.proposedArbitratorId === arbitrator.id
          )
        )
      : arbitrators;
    return selectedArbitrators.map((arbitrator, i) => (
      <tr key={arbitrator.id}>
        <td>{i + 1}</td>
        {isApplicant && (
          <td>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              className="select-arbitrators"
              onChange={(e) =>
                handleFormChange({
                  target: {
                    name: e.target.name,
                    value: {
                      publishingArbitratorId: arbitrator.publishingArbitratorId,
                      proposedArbitratorId: arbitrator.id,
                    },
                  },
                })
              }
              checked={
                !!publicationDetails.publishingArbitrators.filter(
                  (a) => a.proposedArbitratorId === arbitrator.id
                ).length
              }
              name="publishingArbitrators"
              value={arbitrator.id}
              type="checkbox"
            />
          </td>
        )}
        <td>{arbitrator.fullNameArabic}</td>
        <td>{arbitrator.degree}</td>
        <td>{arbitrator.scientificSpecialization}</td>
        <td>{arbitrator.userEmail}</td>
      </tr>
    ));
  };
  const handleFormChange = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | {
          target: {
            name: string;
            value: {
              publishingArbitratorId: number;
              proposedArbitratorId: number;
            };
          };
        },
    dynamicFormKey?:
      | "publishingCompetitors"
      | "publishingAuthors"
      | "publishingSimilars",
    i?: number
  ) => {
    const key = event.target.name;
    const val = event.target.value;
    dispatch(modifyPublicationDetails({ key, val, dynamicFormKey, i }));
    if (key === "applicationId") {
      dispatch(modifyFormState(val));
    }
  };
  const handlePopupChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedProposalArbitrators({
      ...selectedProposalArbitrators,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <FormPanel
        title={t("Nomination_arbitrators")}
        headerButton={
          +publicationDetails.hasArbitrators === 1 && (
            <button
              className="btn"
              type="button"
              onClick={() => {
                setShowAddPopup(true);
                setSelectedProposalArbitrators(SelectedproposalArbitratorTemp);
              }}
            >
              {t("add")}
            </button>
          )
        }
      >
        <div className="main-form__radio-container">
          <label>{t("Nomination_arbitrators_question")}</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              checked={!!+publicationDetails.hasArbitrators}
              type="radio"
              value={1}
              name="hasArbitrators"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
            {t("yes")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              checked={!+publicationDetails.hasArbitrators}
              type="radio"
              value={0}
              name="hasArbitrators"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
            {t("no")}
          </label>
        </div>
        {!!+publicationDetails.hasArbitrators && (
          <div className="table-responsive">
            <table className="basic-table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>{t("list")}</th>
                  {isApplicant && <th>{t("choose")}</th>}
                  <th>{t("arbitrator_name")}</th>
                  <th>{t("Scientific_specialization")}</th>
                  <th>{t("Scientific_specialization")}</th>
                  <th>{t("email")}</th>
                </tr>
              </thead>
              <tbody>
                {arbitrators.length ? (
                  renderArbitrators()
                ) : (
                  <tr>
                    <td colSpan={10}>{t("no_data_show")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </FormPanel>
      {!publicationDetails.startArbitration && (
        <FormPanel title={t("Approval_executive_regulations_publishing")}>
          <div className="main-form__radio-container">
            <div className="regulations_box">
              <div>
                <h4>{t("sub")} 63 :</h4>
                <ul>
                  <li>{t("rule_one")}.</li>
                  <li>{t("rule_tow")}.</li>
                  <li>{t("rule_three")}.</li>
                  <li>{t("rule_four")}.</li>
                  <li>{t("rule_five")}.</li>
                  <li>{t("rule_six")}</li>
                </ul>
              </div>
              <Divider />
              <div>
                <h4>{t("sub")} 73 :</h4>
                <ul>
                  <li>{t("second_rule_one")}.</li>
                  <li>{t("second_rule_tow")}</li>
                  <li>{t("second_rule_three")}</li>
                  <li>{t("second_rule_four")}</li>
                  <li>{t("second_rule_five")}</li>
                  <li>{t("second_rule_six")}</li>
                  <li>{t("second_rule_seven")}</li>
                  <li>{t("second_rule_eight")}</li>
                  <li>{t("second_rule_nine")}</li>
                  <li>{t("second_rule_ten")}</li>
                </ul>
              </div>
            </div>
          </div>
        </FormPanel>
      )}
      <Popup
        onClose={() => {
          setShowAddPopup(false);
          setSelectedCountry("Saudi Arabia");
          setSelectedCity("Riyadh");
        }}
        showPopup={showAddPopup}
        widePopup={true}
      >
        <div className="modern-form">
          <h2>{t("arbitrator")}</h2>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("full_name_arabic")}</label>
              <input
                autoComplete="off"
                type="text"
                name="fullNameArabic"
                value={selectedProposalArbitrators.fullNameArabic}
                onChange={handlePopupChange}
                placeholder={t("full_name_arabic")}
                autoFocus
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("full_name_en")}</label>
              <input
                autoComplete="off"
                type="text"
                name="fullNameEnglish"
                value={selectedProposalArbitrators.fullNameEnglish}
                onChange={handlePopupChange}
                placeholder={t("full_name_en")}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("phone")}</label>
              <input
                autoComplete="off"
                type="text"
                name={"phoneNumber"}
                value={selectedProposalArbitrators.phoneNumber}
                onChange={handlePopupChange}
                placeholder={t("phone")}
              />
            </div>
            <div className="modern-form__input-container">
              <label> {t("email")}</label>
              <input
                autoComplete="off"
                type="text"
                name="email"
                value={selectedProposalArbitrators.email}
                onChange={handlePopupChange}
                placeholder={t("email")}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="modern-form__input-container">
              <label>{t("facluty")}</label>
              <input
                autoComplete="off"
                name="destinationCollege"
                placeholder={t("facluty")}
                value={selectedProposalArbitrators.destinationCollege}
                onChange={handlePopupChange}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("section")}</label>
              <input
                autoComplete="off"
                name="destinationSection"
                placeholder={t("section")}
                value={selectedProposalArbitrators.destinationSection}
                onChange={handlePopupChange}
                id=""
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("academic_Qualifications")}</label>
              <input
                autoComplete="off"
                name="degree"
                placeholder={t("academic_Qualifications")}
                value={selectedProposalArbitrators.degree}
                onChange={handlePopupChange}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="modern-form__input-container">
              <label>{t("exact_scientific_specialization")}</label>
              <input
                autoComplete="off"
                name="exactScientificSpecialization"
                placeholder={t("exact_scientific_specialization")}
                value={
                  selectedProposalArbitrators.exactScientificSpecialization
                }
                onChange={handlePopupChange}
                id=""
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("degree")}</label>
              <input
                autoComplete="off"
                name="scientificRank"
                placeholder={t("degree")}
                value={selectedProposalArbitrators.scientificRank}
                onChange={handlePopupChange}
                id=""
              />
            </div>
          </div>
          <div className="col-4">
            <div className="modern-form__input-container">
              <label>{t("postal")}</label>
              <input
                autoComplete="off"
                name="poBox"
                value={selectedProposalArbitrators.poBox}
                onChange={handlePopupChange}
                type="text"
                placeholder={t("postal")}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("postal_code")}</label>
              <input
                autoComplete="off"
                name="postalCode"
                value={selectedProposalArbitrators.postalCode}
                onChange={handlePopupChange}
                type="text"
                placeholder={t("postal_code")}
              />
            </div>
            <div className="modern-form__input-container">
              <label>{t("country")}</label>
              <select
                name="country"
                value={selectedProposalArbitrators.country}
                onChange={(e) => {
                  handlePopupChange(e);
                  const selectedCountry = e.target.value;
                  setSelectedCountry(selectedCountry);

                  const selectedCity = countries.filter(
                    (obj) => obj.country === selectedCountry
                  )[0].city[0];
                  setSelectedCity(selectedCity);
                  setSelectedProposalArbitrators({
                    ...selectedProposalArbitrators,
                    country: selectedCountry,
                    city: selectedCity,
                  });
                }}
              >
                {countries.map((e, i) => (
                  <option key={i}>{e.country}</option>
                ))}
              </select>
            </div>
            <div className="modern-form__input-container">
              <label>{t("city")}</label>
              <select
                name="city"
                placeholder={t("city")}
                value={selectedProposalArbitrators.city}
                onChange={(e) => {
                  handlePopupChange(e);
                  const selectedCity = e.target.value;
                  setSelectedCity(selectedCity);
                  setSelectedProposalArbitrators({
                    ...selectedProposalArbitrators,
                    country: selectedCountry,
                    city: selectedCity,
                  });
                }}
              >
                {countries
                  .filter((obj) => obj.country === selectedCountry)[0]
                  ?.city.map((c, index) => (
                    <option key={index} value={c}>
                      {c}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="modern-form__footer">
            <button
              type="button"
              disabled={Object.values(selectedProposalArbitrators).some(
                (value) => value === ""
              )}
              onClick={(e) => {
                AddProposedArbitrators(selectedProposalArbitrators).then(() => {
                  setShowAddPopup(false);
                  getArbitrators();
                });
              }}
            >
              {t("add")}
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};
