export const form2 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "mainIdea",
        "scientificValue",
        "scientificMethod",
        "contentsList",
        "pagesNumber",
        "chaptersNumber",
        "partsNumber",
      ],
      textareInputs: [
        "mainIdea_100",
        "scientificValue_100",
        "scientificMethod_50",
      ],
    },
  ],
  4: [{ mainInputs: ["expectedOrderVolume"] }],
  5: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
      isRelatedToAuthorSpecialization: ["revisedOrExpandedDetails"],
    },
  ],
};
export const form3 = form2;
export const form4 = {
  2: [
    {
      mainInputs: [
        "manuscriptTitle",
        "manuscriptAuthor",
        "topic",
        "manuscriptPartsNumber",
        "copiesNumber",
        "approvedCopiesNumber",
      ],
    },
  ],
  3: [
    {
      mainInputs: [
        "firstCopy",
        "secondCopy",
        "thirdCopy",
        "fourthCopy",
        "fifthCopy",
        "sixthCopy",
        "seventhCopy",
        "eighthCopy",
        "ninthCopy",
        "tenthCopy",
      ],
    },
  ],
  4: [
    {
      textareInputs: [
        "mainIdea_50",
        "scientificValue_50",
        "scientificMethod_100",
        "scientificStanding_50",
      ],
      mainInputs: [
        "mainIdea",
        "scientificValue",
        "scientificMethod",
        "contentsList",
        "scientificStanding",
      ],
    },
  ],
  5: [{ mainInputs: ["expectedOrderVolume"] }],
  6: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
      isRelatedToAuthorSpecialization: ["revisedOrExpandedDetails"],
    },
  ],
  9: [{ mainInputs: ["pagesNumber", "chaptersNumber"] }],
};
export const form5 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "contentsList",
        "pagesNumber",
        "mainIdea",
        "scientificValue",
        "scientificMethod",
      ],
      textareInputs: [
        "mainIdea_100",
        "scientificValue_100",
        "scientificMethod_50",
      ],
    },
  ],
  3: [
    {
      mainInputs: [
        "titleChosenLanguage",
        "topic",
        "originalAuthorName",
        "originalPublisherName",
        "originalPublisherCity",
        "originalEditionNumber",
        "originalPublishingYear",
        "originalBookPagesNumber",
        "specialistsOpinions",
        "websiteRatings",
        "religionConflict",
        "politicsConflict",
      ],
    },
  ],
  5: [{ mainInputs: ["expectedOrderVolume"] }],
  6: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
      isRelatedToAuthorSpecialization: ["revisedOrExpandedDetails"],
    },
  ],
};
export const form6 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "mainIdea",
        "scientificValue",
        "scientificMethod",
        "contentsList",
        "pagesNumber",
        "partsNumber",
      ],
      textareInputs: [
        "mainIdea_100",
        "scientificValue_100",
        "scientificMethod_50",
      ],
    },
  ],
  4: [{ mainInputs: ["expectedOrderVolume"] }],
  5: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
    },
  ],
};
export const form7 = form6;

export const form8 = form2;

export const form9 = form2;

export const form10 = form6;

export const form11 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "mainIdea",
        "scientificValue",
        "scientificMethod",
        "contentsList",
        "pagesNumber",
        "partsNumber",
        "topic",
        "otherGoal",
      ],
      textareInputs: [
        "mainIdea_100",
        "scientificValue_100",
        "scientificMethod_50",
      ],
    },
  ],
  4: [{ mainInputs: ["expectedOrderVolume"] }],
  5: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
      isRelatedToAuthorSpecialization: ["revisedOrExpandedDetails"],
    },
  ],
  7: [{ hasSameTopic: ["similarAndDifferentWithSuggest"] }],
};

export const form12 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "mainIdea",
        "scientificValue",
        "scientificMethod",
        "contentsList",
        "pagesNumber",
        "partsNumber",
        "otherGoal",
      ],
      textareInputs: [
        "mainIdea_100",
        "scientificValue_100",
        "scientificMethod_50",
      ],
    },
  ],
  4: [{ mainInputs: ["expectedOrderVolume"] }],
  5: [
    {
      isPostedBefore: [
        "previousPublisherName",
        "previousPublishingDate",
        "previousPublishingEndDate",
        "previousPublishingEditionsNumber",
        "previousPublishingDemandVolume",
        "reasonsRepublishing",
      ],
    },
  ],
  7: [{ hasSameTopic: ["similarAndDifferentWithSuggest"] }],
};

export const form13 = {
  2: [
    {
      mainInputs: [
        "titleChosenLanguage",
        "arabicTitle",
        "certificateName",
        "arabicCertificateName",
        "university",
        "country",
        "city",
        "graduationDate",
        "pagesNumber",
        "topic",
        "briefSummary",
        "importance",
      ],
    },
  ],
};

export const form14 = {
  2: [
    {
      mainInputs: [
        "authoringLanguage",
        "arabicTitle",
        "englishTitle",
        "topic",
        "otherGoal",
        "scientificMethod",
        "scientificValue",
        "contentsList",
        "organizerBrief",
        "pagesNumber",
      ],
      textareInputs: ["topic_100", "scientificValue_100"],
    },
  ],
  4: [{ mainInputs: ["expectedOrderVolume"] }],
};

export const form15 = {
  2: [
    {
      mainInputs: [
        "arabicTitle",
        "englishTitle",
        "arabicSummary",
        "englishSummary",
        "arabicKeywords",
        "englishKeywords",
      ],
    },
  ],
};
