import Divider from "components/Divider";
import { Popup } from "components/Popup/Popup";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pushAlert, selectAlert } from "store/states/alertSlice";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
interface ArbitratorAcceptContractPopupProps {
  setShowArbitratorAcceptContract: (type: boolean) => void;
  showArbitratorAcceptContract: boolean;

  contractBankDetails: any;
  handleBankDetailsChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const ArbitratorAcceptContractPopup = (
  props: ArbitratorAcceptContractPopupProps
) => {
  const dispatch = useAppDispatch();
  const { onAcceptBody } = useAppSelector(selectAlert);

  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const {
    setShowArbitratorAcceptContract,
    showArbitratorAcceptContract,

    contractBankDetails,
    handleBankDetailsChange,
  } = props;
  return (
    <Popup
      onClose={() => {
        setShowArbitratorAcceptContract(false);
      }}
      showPopup={showArbitratorAcceptContract}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowArbitratorAcceptContract(false);
          dispatch(
            pushAlert({
              show: true,
              onAcceptBody: {
                ...onAcceptBody,
                bank: contractBankDetails,
              },
            })
          );
        }}
        className="modern-form accept-contract"
      >
        <h2>{t("arbitrator_contract")}</h2>
        <p>{t("accepter_arbitrator_agreement_contract")}</p>
        <Divider />
        <h4>{t("abide_following")}:</h4>
        <ul>
          <li>{t("arbitration_rule_one")}</li>
          <li>{t("arbitration_rule_tow")}</li>
          <li>{t("arbitration_rule_three")}</li>
        </ul>
        <Divider />
        <p>
          {`
      ${t("amount_will_paid")}: ${
            publicationDetails.publishingActions?.filter(
              (a) => a.action === "Accept contract"
            )[0]?.amount
          } ${t("sar_arbitrator_amount")}`}
        </p>
        <Divider />
        <div className="col-2">
          <div className="main-form__input-container">
            <label>{t("bank_name")}</label>
            <input
              autoComplete="off"
              placeholder={t("bank_name")}
              type="text"
              name="bankName"
              value={contractBankDetails.bankName}
              onChange={handleBankDetailsChange}
            />
          </div>
          <div className="main-form__input-container">
            <label>{t("IBAN")}</label>
            <input
              autoComplete="off"
              placeholder={t("IBAN")}
              type="text"
              name="iban"
              value={contractBankDetails.iban}
              onChange={handleBankDetailsChange}
            />
          </div>
          <div className="main-form__input-container">
            <label>{t("account_name")}</label>
            <input
              autoComplete="off"
              placeholder={t("account_name")}
              type="text"
              name="accountName"
              value={contractBankDetails.accountName}
              onChange={handleBankDetailsChange}
            />
          </div>
          <div className="main-form__input-container">
            <label>{t("swift_code")}</label>
            <input
              autoComplete="off"
              placeholder={t("swift_code")}
              type="text"
              name="swiftCode"
              value={contractBankDetails.swiftCode}
              onChange={handleBankDetailsChange}
            />
          </div>
        </div>
        <div className="modern-form__footer">
          <button>{t("accept_contract")}</button>
        </div>
      </form>
    </Popup>
  );
};
