import axios from "axios";
import { Arbitrator } from "types/arbitrator";
export const AddProposedArbitrators = (arbitrator: Partial<Arbitrator>) => {
  return axios.post("ProposedArbitrators", { ...arbitrator });
};
export const GetProposedArbitrators = (fieldId = "") => {
  return axios.get(`ProposedArbitrators?fieldId=${fieldId}`);
};
export const DeleteProposedArbitrators = (id: any, obj: any) => {
  return axios.delete(`ProposedArbitrators/${id}`, obj);
};
export const UpdateProposedArbitrators = (id: any, obj: any) => {
  return axios.put(`ProposedArbitrators/${id}`, obj);
};
