import { FormPanel } from "components/FormPanel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
const AcknowledgmentRightProperty = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel title={t("Acknowledgment_right_property")}>
      <div className="main-form__checkbox-container">
        <div className="col f-box">
          <label>
            <input
              autoComplete="off"
              name="acknowledgement"
              value="single"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "acknowledgement",
                    val: e.target.value,
                  })
                )
              }
              type="radio"
              checked={publicationDetails.acknowledgement === "single"}
              disabled={publicationDetails.isViewMode}
            />
            {t("Acknowledgment_right_property_accepted")}
          </label>
          <label>
            <input
              autoComplete="off"
              name="acknowledgement"
              value="multi"
              checked={publicationDetails.acknowledgement === "multi"}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "acknowledgement",
                    val: e.target.value,
                  })
                )
              }
              type="radio"
              disabled={publicationDetails.isViewMode}
            />
            {t("Acknowledgment_right_property_self-accepted")}
          </label>
        </div>
      </div>
    </FormPanel>
  );
};

export default AcknowledgmentRightProperty;
