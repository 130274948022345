import { FormPanel } from "../../../../../components/FormPanel";
import { useTranslation } from "react-i18next";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { ContractFavOptions } from "./common/ContractFavOptions";
import { OtherPublications } from "./common/OtherPublications";
import { ProposalDetails } from "./common/ProposalDetails";
import { InfoBookAuthor } from "./common/InfoBookAuthor";
import { TargetAudiance } from "./common/TargetAudiance";
import { RateOfBookCompleted } from "./common/RateOfBookCompleted";
import { ContentReport } from "./common/ContentReport";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import InputNumber from "components/InputNumber";
import DatePicker from "react-datepicker";
import convertDateToFormat from "../../../../../utils/convertDateToFormat";
export const TranslateBook = () => {
  const { t } = useTranslation();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const dispatch = useAppDispatch();

  return (
    <>
      <ProposalDetails />
      <FormPanel title={t("Information_about_translation_book")}>
        <div className="col-2">
          <div className="main-form__input-container">
            <label>
              {t("book_title")} <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("book_title")}
              type="text"
              name="titleChosenLanguage"
              value={publicationDetails.titleChosenLanguage}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("book_content")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("book_content")}
              type="text"
              name="topic"
              value={publicationDetails.topic}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("author/authors_name")} <span className="required-input">*</span>
          </label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            placeholder={t("author/authors_name")}
            name="originalAuthorName"
            value={publicationDetails.originalAuthorName}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="col-5">
          <div className="main-form__input-container">
            <label>
              {t("Publisher_name")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("Publisher_name")}
              type="text"
              name="originalPublisherName"
              value={publicationDetails.originalPublisherName}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("Publisher_city")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("Publisher_city")}
              type="text"
              name="originalPublisherCity"
              value={publicationDetails.originalPublisherCity}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("edition_number")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("edition_number")}
              name="originalEditionNumber"
              value={publicationDetails.originalEditionNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("Year_Publication")}
              <span className="required-input">*</span>
            </label>

            <DatePicker
              disabled={publicationDetails.isViewMode}
              placeholderText={t("Year_Publication")}
              name="originalPublishingYear"
              value={convertDateToFormat(
                publicationDetails.originalPublishingYear || new Date()
              )}
              onChange={(date) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "originalPublishingYear",
                    val: date,
                  })
                )
              }
              dateFormat={"dd/MM/yyyy"}
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("number_pages_book")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("number_pages_book")}
              name="originalBookPagesNumber"
              value={publicationDetails.originalBookPagesNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("readers_opinion_question")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            name="specialistsOpinions"
            value={publicationDetails.specialistsOpinions}
            placeholder={t("readers_opinion_question")}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("readers_rate_question")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("readers_rate_question")}
            name="websiteRatings"
            value={publicationDetails.websiteRatings}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("contradicts_religious_values_question")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("contradicts_religious_values_question")}
            name="religionConflict"
            value={publicationDetails.religionConflict}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("contradicts_Kingdom_political_question")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("contradicts_Kingdom_political_question")}
            name="politicsConflict"
            value={publicationDetails.politicsConflict}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      </FormPanel>
      <RateOfBookCompleted />
      <TargetAudiance />
      <ContentReport />
      <InfoBookAuthor />
      <FormPanel title={t("book_title")}>
        <div className="main-form__radio-container">
          <label>{t("main_sub_question")}</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="subjectInAuthorExpertiseArea"
              value="1"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
              checked={!!+publicationDetails.subjectInAuthorExpertiseArea}
              type="radio"
            />
            {t("yes")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="subjectInAuthorExpertiseArea"
              value="0"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
              checked={!+publicationDetails.subjectInAuthorExpertiseArea}
              type="radio"
            />
            {t("no")}
          </label>
        </div>

        {!+publicationDetails.subjectInAuthorExpertiseArea && (
          <div className="main-form__input-container">
            <label>{t("reason_interest_question")}</label>
            <textarea
              disabled={publicationDetails.isViewMode}
              name="attachmentsType"
              placeholder={t("reason_interest_question")}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
        )}
      </FormPanel>
      <OtherPublications />
      <ContractFavOptions />
      <AcknowledgmentRightProperty />
    </>
  );
};
