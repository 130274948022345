import {
  faCompass,
  faHome,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./UserMenu.scss";
import userAvatar from "./../../assets/images/avatar.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { logout } from "../../store/states/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { User } from "../../types/user";
import { MENU_LIKNS } from "./MenuLinks";

import { selectUserDetails } from "store/states/userDetailsSlice";
import { selectProfile } from "store/states/profileSlice";

export const UserMenu = () => {
  const profileInfo = useAppSelector(selectProfile);
  const { t } = useTranslation();
  const userDetails: Partial<User> = JSON.parse(
    localStorage.getItem("user_details") ?? ""
  );

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isApplicant } = useAppSelector(selectUserDetails);
  useEffect(() => {
    setIsSideMenuOpen(false);
  }, [location]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <span></span>
      <button
        id="toggle-menu"
        onClick={() => {
          setIsSideMenuOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faCompass} />
        {t("menu")}
      </button>
      <div className={`user-menu ${isSideMenuOpen ? "active" : ""}`}>
        <div
          className="user-menu__overlay"
          onClick={() => {
            setIsSideMenuOpen(false);
          }}
        ></div>
        <div className="user-menu__items">
          <div className="user-menu__items__top-icons">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="user-menu__items__user-info">
            <img
              src={
                isApplicant ? baseURL + profileInfo.userProfileUrl : userAvatar
              }
              alt=""
            />
            <h3>{isApplicant ? profileInfo.fullName : userDetails.name}</h3>
            <h4>{userDetails.email}</h4>
            <h4>{t(userDetails.role ?? "Admin")}</h4>
          </div>
          <ul>
            <li>
              <NavLink to="/">
                <>
                  <FontAwesomeIcon icon={faHome} />
                  {t("home")}
                </>
              </NavLink>
            </li>

            {MENU_LIKNS[userDetails.role ?? "Admin"].map((link) => (
              <li key={link.title}>
                <NavLink to={link.url}>
                  <>
                    <FontAwesomeIcon icon={link.icon} />
                    {t(link.title)}
                  </>
                </NavLink>
              </li>
            ))}
            <li>
              <button
                onClick={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              >
                <>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  {t("logout")}
                </>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
