import React from "react";
import { useTranslation } from "react-i18next";
import { FormPanel } from "../../../../../components/FormPanel";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { ContentReport } from "./common/ContentReport";
import { ContractFavOptions } from "./common/ContractFavOptions";
import { OtherPublications } from "./common/OtherPublications";
import { RateOfBookCompleted } from "./common/RateOfBookCompleted";
import { TargetAudiance } from "./common/TargetAudiance";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { InfoBookAuthor } from "./common/InfoBookAuthor";
import { selectFormType } from "store/states/formTypeSlice";
import { ProposalInfo } from "./common/proposalInfo";
import InputNumber from "components/InputNumber";

export const ManuscriptBookForm = () => {
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);

  return (
    <>
      <FormPanel title={t("manuscript_details")}>
        <div className="col-3">
          <div className="main-form__input-container">
            <label>
              {t("manuscript_title")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("manuscript_title")}
              type="text"
              value={publicationDetails.manuscriptTitle}
              name="manuscriptTitle"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "manuscriptTitle",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("author_name")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              value={publicationDetails.manuscriptAuthor}
              placeholder={t("author_name")}
              type="text"
              name="manuscriptAuthor"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "manuscriptAuthor",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("manuscript_content")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("manuscript_content")}
              type="text"
              name="topic"
              value={publicationDetails.topic}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "topic",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="col-3">
          <div className="main-form__input-container">
            <label>
              {t("num_parts")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("num_parts")}
              name="manuscriptPartsNumber"
              value={publicationDetails.manuscriptPartsNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "manuscriptPartsNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("num_copies")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("num_copies")}
              name="copiesNumber"
              value={publicationDetails.copiesNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "copiesNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("approvedCopiesNumber")}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              placeholder={t("approvedCopiesNumber")}
              name="approvedCopiesNumber"
              value={publicationDetails.approvedCopiesNumber}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "approvedCopiesNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      </FormPanel>
      <FormPanel title={t("src_approvedCopiesNumber")}>
        <>
          <div className="main-form__input-container">
            <label>
              {t("firstCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("firstCopy")}
              type="text"
              value={publicationDetails.firstCopy}
              name="firstCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "firstCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("secondCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("secondCopy")}
              type="text"
              value={publicationDetails.secondCopy}
              name="secondCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "secondCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("thirdCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("thirdCopy")}
              type="text"
              value={publicationDetails.thirdCopy}
              name="thirdCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "thirdCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("fourthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("fourthCopy")}
              type="text"
              value={publicationDetails.fourthCopy}
              name="fourthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "fourthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("fifthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("fifthCopy")}
              type="text"
              value={publicationDetails.fifthCopy}
              name="fifthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "fifthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("sixthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("sixthCopy")}
              type="text"
              value={publicationDetails.sixthCopy}
              name="sixthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "sixthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("seventhCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("seventhCopy")}
              type="text"
              value={publicationDetails.seventhCopy}
              name="seventhCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "seventhCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("eighthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("eighthCopy")}
              type="text"
              value={publicationDetails.eighthCopy}
              name="eighthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "eighthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("ninthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("ninthCopy")}
              type="text"
              value={publicationDetails.ninthCopy}
              name="ninthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "ninthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("tenthCopy")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={t("tenthCopy")}
              type="text"
              value={publicationDetails.tenthCopy}
              name="tenthCopy"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "tenthCopy",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </>
      </FormPanel>
      <FormPanel title={t("About_manuscript")}>
        <div className="col-2">
          <div className="main-form__input-container">
            <label>
              {t("main_idea")}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={t("main_idea")}
              value={publicationDetails.mainIdea}
              name="mainIdea"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "mainIdea",
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("Scientific_Value")}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={t("Scientific_Value")}
              value={publicationDetails.scientificValue}
              name="scientificValue"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "scientificValue",
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
        </div>
        <div className="col-2">
          <div className="main-form__input-container">
            <label>
              {t("Scientific_Method")}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={t("Scientific_Method")}
              value={publicationDetails.scientificMethod}
              name="scientificMethod"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "scientificMethod",
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("Contents_List")}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={t("Contents_List")}
              value={publicationDetails.contentsList}
              name="contentsList"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "contentsList",
                    val: e.target.value,
                  })
                )
              }
            ></textarea>
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("Scientific_Standing")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("Scientific_Standing")}
            value={publicationDetails.scientificStanding}
            name="scientificStanding"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "scientificStanding",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__radio-container">
          <label>{`${t("goal_from")} ${formType} `}</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="goal"
              value=" مرجع علمي"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "goal",
                    val: e.target.value,
                  })
                )
              }
              checked={publicationDetails.goal === " مرجع علمي"}
              type="radio"
            />
            {t("Scientific_reference")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="goal"
              value="مقرر دراسي"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "goal",
                    val: e.target.value,
                  })
                )
              }
              checked={publicationDetails.goal === "مقرر دراسي"}
              type="radio"
            />
            {t("course")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="goal"
              value="كتاب ثقافي"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "goal",
                    val: e.target.value,
                  })
                )
              }
              checked={publicationDetails.goal === "كتاب ثقافي"}
              type="radio"
            />
            {t("Cultural_book")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="goal"
              value="أخرى"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "goal",
                    val: e.target.value,
                  })
                )
              }
              checked={
                ![" مرجع علمي", "كتاب ثقافي", "مقرر دراسي"].includes(
                  publicationDetails.goal
                )
              }
              type="radio"
            />
            {t("other")}
          </label>
          {publicationDetails.goal === "أخرى" && (
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                value={publicationDetails.goal}
                name="goal"
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "pagesNumber",
                      val: e.target.value,
                    })
                  )
                }
                type="input"
                placeholder={t("book_target")}
              />
            </label>
          )}
        </div>
      </FormPanel>
      <TargetAudiance />
      <ContentReport />
      <ProposalInfo />
      <RateOfBookCompleted />
      <FormPanel title={t("book_project_achieved")}>
        <div className="col-4">
          <div className="main-form__input-container">
            <label>
              {t("Approximate_page_count")}{" "}
              <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              value={publicationDetails.pagesNumber}
              name="pagesNumber"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "pagesNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("num_chapters")} <span className="required-input">*</span>
            </label>
            <InputNumber
              disabled={publicationDetails.isViewMode}
              value={publicationDetails.chaptersNumber}
              name="chaptersNumber"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "chaptersNumber",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      </FormPanel>
      <InfoBookAuthor />
      <OtherPublications />
      <ContractFavOptions />
      <AcknowledgmentRightProperty />
    </>
  );
};
