import { FormPanel } from "components/FormPanel";
import FilesUploader from "components/FilesUploader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import InputNumber from "components/InputNumber";
import DatePicker from "react-datepicker";
import convertDateToFormat from "../../../../../../utils/convertDateToFormat";
export const RateOfBookCompleted = () => {
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel
      title={`${t("Achievement_rate_of")}${formType} ${t(
        "supplement_attachment"
      )}`}
    >
      <div className="main-form__radio-container">
        <label>
          {t("has_the")}
          {formType} {t("attachment")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasAttachments"
            value="1"
            onChange={(e) => {
              dispatch(
                modifyPublicationDetails({
                  key: "hasAttachments",
                  val: 1,
                })
              );
            }}
            checked={!!+publicationDetails.hasAttachments}
            type="radio"
          />
          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasAttachments"
            value="0"
            onChange={(e) => {
              dispatch(
                modifyPublicationDetails({
                  key: "hasAttachments",
                  val: 0,
                })
              );
            }}
            checked={!+publicationDetails.hasAttachments}
            type="radio"
          />
          {t("no")}
        </label>
      </div>

      {!!+publicationDetails.hasAttachments && (
        <>
          <div className="">
            <div className="table-responsive">
              <table className="basic-table" cellSpacing={0}>
                <thead>
                  <tr>
                    <th colSpan={12}>
                      {t("attachment_type")}{" "}
                      <span className="required-input">*</span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Question_Bank"
                          checked={publicationDetails.attachmentsType.includes(
                            "Question_Bank"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("Question_bank")}
                      </label>
                    </th>

                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Teacher_Book"
                          checked={publicationDetails.attachmentsType.includes(
                            "Teacher_Book"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("book_teacher")}
                      </label>
                    </th>
                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Student_Book"
                          checked={publicationDetails.attachmentsType.includes(
                            "Student_Book"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("book_student")}
                      </label>
                    </th>
                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Case_Studies"
                          checked={publicationDetails.attachmentsType.includes(
                            "Case_Studies"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("Case_studies_scientific_experiments")}
                      </label>
                    </th>
                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Presentations"
                          checked={publicationDetails.attachmentsType.includes(
                            "Presentations"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("Presentations")}
                      </label>
                    </th>
                    <th>
                      <label>
                        <input
                          autoComplete="off"
                          disabled={publicationDetails.isViewMode}
                          name="attachmentsType"
                          value="Other"
                          checked={publicationDetails.attachmentsType.includes(
                            "Other"
                          )}
                          onChange={(e) =>
                            dispatch(
                              modifyPublicationDetails({
                                key: "attachmentsType",
                                val: e.target.value,
                              })
                            )
                          }
                          type="checkbox"
                        />
                        {t("other")}
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <FilesUploader
                        uploadPath={`publishings/${publicationDetails.id}/attachments`}
                        deletePath="publishings/file"
                        attachmentType="Attachments_Type"
                        initialAttachments={
                          publicationDetails.attachments["Attachments_Type"]
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {[t("dictionary"), t("atlas"), t("encyclopedia")].includes(formType) &&
        !!+publicationDetails.hasAttachments && (
          <div className="main-form__radio-container">
            <label>{t("Attachment_type")}</label>
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                name="authoringLanguage"
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "authoringLanguage",
                      val: e.target.value,
                    })
                  )
                }
                type="input"
                placeholder={t("Attachment_type")}
              />
            </label>
          </div>
        )}

      <div className="main-form__radio-container">
        <label>
          {t("Achievement_rate_of")}
          {formType}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isComplete"
            value="1"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "isComplete",
                  val: e.target.value,
                })
              )
            }
            checked={!!+publicationDetails.isComplete}
            type="radio"
          />
          {t("completed")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isComplete"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "isComplete",
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.isComplete}
            type="radio"
          />
          {t("In_preparation")}
        </label>
      </div>
      {!!+publicationDetails.isComplete ? (
        <>
          <div className="main-form__input-container">
            <label>
              {t("copy_of_the_draft")}
              {formType}
            </label>
            <FilesUploader
              uploadPath={`Publishings/${publicationDetails.id}/attachments`}
              attachmentType="Draft_Book_Copy"
              initialAttachments={
                publicationDetails.attachments["Draft_Book_Copy"]
              }
              deletePath="publishings/attachments"
            />
          </div>
          <div className="main-form__input-container">
            <label>{t("copy_attachment")}</label>
            <FilesUploader
              uploadPath={`Publishings/${publicationDetails.id}/attachments`}
              attachmentType="Attachment_COPY"
              initialAttachments={
                publicationDetails.attachments["Attachment_COPY"]
              }
              deletePath="publishings/attachments"
            />
          </div>
        </>
      ) : (
        <>
          <div className="main-form__input-container">
            <label>{t("copy_chapter_draft")}</label>
            <FilesUploader
              uploadPath={`Publishings/${publicationDetails.id}/attachments`}
              attachmentType="Draft_Chapter_Copy"
              initialAttachments={
                publicationDetails.attachments["Draft_Chapter_Copy"]
              }
              deletePath="publishings/attachments"
            />
          </div>
          <div className="main-form__input-container">
            <label>{t("copy_attachment")}</label>
            <FilesUploader
              uploadPath={`Publishings/${publicationDetails.id}/attachments`}
              attachmentType="Attachment_COPY"
              initialAttachments={
                publicationDetails.attachments["Attachment_COPY"]
              }
              deletePath="publishings/attachments"
            />
          </div>
          <div className="col-3">
            <div className="main-form__input-container">
              <label>
                {t("Achievement_rate_of")}
                {formType}
              </label>
              <InputNumber
                disabled={publicationDetails.isViewMode}
                name="completionRate"
                value={publicationDetails.completionRate}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "completionRate",
                      val: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>
                {t("time_required_complete_the")}
                {formType} {t("in_days")}
              </label>
              <InputNumber
                disabled={publicationDetails.isViewMode}
                name="remainDaysForCompletion"
                value={publicationDetails.remainDaysForCompletion}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "remainDaysForCompletion",
                      val: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="main-form__input-container">
              <label>{t("expected_date_completion")}</label>

              <DatePicker
                disabled={publicationDetails.isViewMode}
                name="expectedExpiryDate"
                value={convertDateToFormat(
                  publicationDetails.expectedExpiryDate || new Date()
                )}
                onChange={(date) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "expectedExpiryDate",
                      val: date,
                    })
                  )
                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
        </>
      )}
    </FormPanel>
  );
};
