import { NavLink } from "react-router-dom";

import { ReactComponent as Moon } from "../assets/icons/moon.svg";
import { ReactComponent as Sun } from "../assets/icons/sun.svg";
import ARLangLogo from "../assets/icons/ar.png";
import ENLangLogo from "../assets/icons/en.png";
import sloganLogo from "../assets/images/slogan.png";

import { useTranslation } from "react-i18next";
import { Logo } from "./Logo";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "../context/ThemeContext";
import { SocialIcons } from "./SocialIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../store/hooks";
import { selectAuth } from "../store/states/authSlice";
import { UserMenu } from "./UserMenu/UserMenu";

export const Header = () => {
  const { t, i18n } = useTranslation();
  const themeCtx = useContext(ThemeContext);
  const isAuth = useAppSelector(selectAuth);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header className={`no-print ${offset > 0 ? "active" : ""}`}>
      <div className="container">
        <div id="auth-nav-container">
          <div id="logo_desktop">
            <Logo />
          </div>
          <img id="logo_mobile" src={sloganLogo} alt="Slogan" />
          {isAuth && (
            <>
              <UserMenu />
            </>
          )}
        </div>

        {/* <ul id="navigation__menu">
          <li>
            <NavLink end className={" menu-item"} to={"/"}>
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink className="menu-item" to={"/about"}>
              {t("books")}
            </NavLink>
          </li>
          <li>
            <a className="menu-item" href={"/"}>
              {t("about_publishing_house")}
            </a>
          </li>
          <li>
            <a className="menu-item" href={"/"}>
              {t("services")}
            </a>
          </li>
          <li>
            <a className="menu-item" href={"/"}>
              {t("contact_us")}
            </a>
          </li>
        </ul> */}
        <div id="navigation__social">
          <SocialIcons />
          <button
            className={themeCtx.theme}
            id="change-theme"
            onClick={themeCtx.toggleTheme}
          >
            <Moon />
            <Sun />
          </button>

          {i18n.language === "ar" ? (
            <img
              onClick={() => changeLanguage("en")}
              src={ENLangLogo}
              alt="Arabic Logo"
            />
          ) : (
            <img
              onClick={() => changeLanguage("ar")}
              src={ARLangLogo}
              alt="Arabic Logo"
            />
          )}
          {!isAuth && (
            <NavLink id="login-btn" to={"/auth"}>
              <FontAwesomeIcon icon={faCircleUser} />
            </NavLink>
          )}
        </div>
      </div>
    </header>
  );
};
