import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { GetArbitrators } from "services/arbitrator.service";
import { Arbitrator } from "types/arbitrator";
import "./PublicationComments.scss";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { GetUsers } from "services/user.service";
import { User } from "types/user";
import { StaticButtons } from "./StaticButtons";
import { CommonPopup } from "./popups/CommonPopup";
import { ChooseArbitratorsPopup } from "./popups/ChooseArbitratorsPopup";
import { ArbitratorAcceptContractPopup } from "./popups/ArbitratorAcceptContractPopup";
import { ChooseProofreaderPopup } from "./popups/ChooseProofreaderPopup";
import { ProofreaderAcceptContractPopup } from "./popups/ProofreaderAcceptContractPopup";
import { RejectionFormPopup } from "./popups/RejectionFormPopup";
import { CommentsPopup } from "./popups/CommentsPopup";
import { RejectionReasonsPopup } from "./popups/RejectionReasonsPopup";
import { CriertiaPopup } from "./popups/CriertiaPopup";
import { pushAlert, selectAlert } from "store/states/alertSlice";
import { Attachment } from "types/publication";
import { GetReports } from "services/report.service";
import { userDetailsSlice } from "./../../../store/states/userDetailsSlice";
import { MatchingFormPopup } from "./popups/MatchingFormPopup";

export const PublicationComments = (
  props: React.PropsWithChildren<{
    fieldId: string;
    publishingId: number;
    actions?: any[];
    refreshPublicatoinDetails: any;
  }>
) => {
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const dispatch = useAppDispatch();
  const { role, isScientificCommitteeMember, isEditor } =
    useAppSelector(selectUserDetails);
  const { refetchDataCount, onAcceptBody } = useAppSelector(selectAlert);
  const { t, i18n } = useTranslation();
  const [comment, setComment] = useState("");
  const [selectedRejectionReasons, setSelectedRejectionReasons] = useState<
    string[]
  >([]);
  const [selectedProofreader, setSelectedProofreader] = useState<User>();
  const [selectedArbitrators, setSelectedArbitrators] = useState<Arbitrator[]>(
    publicationDetails.publishingCandidateArbitrators
  );

  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [showCommonPopup, setShowCommonPopup] = useState(false);
  const [showCommentsPopup, setShowCommentsPopup] = useState(false);
  const [showRejectionReasonsPopup, setShowRejectionReasonsPopup] =
    useState(false);
  const [showCriteriaPopup, setShowCriteriaPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showProofreaderAcceptContract, setShowProofreaderAcceptContract] =
    useState(false);
  const [showArbitratorAcceptContract, setShowArbitratorAcceptContract] =
    useState(false);
  const [showSelectProofreaders, setShowSelectProofreaders] = useState(false);
  const [showSelectArbitrator, setShowSelectArbitrator] = useState(false);
  const [isAcceptCandidatesAction, setIsAcceptCandidatesAction] =
    useState(false);
  const [isRecommendCandidatesAction, setIsRecommendCandidatesAction] =
    useState(false);
  const [isMembersCandidatesAction, setIsMembersCandidatesAction] =
    useState(false);
  const [showRejectionForm, setShowRejectionForm] = useState(false);
  const [showMatchingForm, setShowMatchingForm] = useState(false);
  const [proofreaders, setProofreaders] = useState<User[]>([]);
  const [arbitrators, setArbitrators] = useState<Arbitrator[]>([]);
  const [proofreaderUser, setProofreaderUser] = useState<any>();
  const [matchingFormDetails, setMatchingFormDetails] = useState({
    notesStatus: "",
    ignoredNotes: "",
    authorComment: "",
    notesCount: "",
    modifiedNotes: "",
    notModifiedNotesWithReasons: "",
    notModifiedNotesWithoutReasons: "",
  });
  const [contractBankDetails, setContractBankDetails] = useState({
    bankName: "",
    iban: "",
    accountName: "",
    swiftCode: "",
  });

  const [userAction, setuserAction] = useState({
    action: "",
    confirm: "",
    labelAR: "",
    labelEN: "",
    response: "",
    type: "",
  });

  useEffect(() => {
    props.refreshPublicatoinDetails();
  }, [refetchDataCount]);

  const handleProofreadersFormChange = (
    proofreader: User,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const tempProofreader = { ...proofreader };
    if (name === "amount") {
      tempProofreader.amount = +value;
    }
    setSelectedProofreader(tempProofreader);
  };
  const handleFormChange = (
    arbitrator: Arbitrator,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    let tempArbitrator = { ...arbitrator };
    if (userAction.type === "Arbitrator_Choose") {
      setSelectedArbitrators([arbitrator]);
    } else {
      if (name === "arbitration_money") {
        tempArbitrator.amount = +value;
        const filteredArbitrators = selectedArbitrators.filter(
          (a) => a.id !== tempArbitrator.id
        );
        setSelectedArbitrators([...filteredArbitrators, tempArbitrator]);
      } else {
        tempArbitrator.amount = 0;
        selectedArbitrators.filter((a) => a.id === tempArbitrator.id).length
          ? setSelectedArbitrators([
              ...selectedArbitrators.filter((a) => a.id !== tempArbitrator.id),
            ])
          : setSelectedArbitrators([...selectedArbitrators, tempArbitrator]);
      }
    }
  };

  useEffect(() => {
    GetUsers("Proofreader").then((response) => {
      setProofreaderUser(response.data);
    });
    if (isEditor) {
      GetUsers("Proofreader").then((response) => {
        setProofreaders(response.data);
      });
    }
    GetArbitrators(props.fieldId).then((response) => {
      setArbitrators(response.data);
    });
  }, []);
  useEffect(() => {
    dispatch(
      pushAlert({
        onAcceptBody: {
          ...onAcceptBody,
          attachments,
          arbitrators: selectedArbitrators,
          proofreader: selectedProofreader,
        },
      })
    );
  }, [attachments, selectedArbitrators, selectedProofreader]);

  useEffect(() => {
    dispatch(
      pushAlert({
        onAcceptBody: {
          ...onAcceptBody,
          rejectReasons: selectedRejectionReasons,
        },
      })
    );
  }, [selectedRejectionReasons]);

  const resetButtonsActionCheck = () => {
    setIsRecommendCandidatesAction(false);
    setIsMembersCandidatesAction(false);
    setIsAcceptCandidatesAction(false);
  };

  const handleMatchingFormDetailsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMatchingFormDetails((oldVal) => {
      return { ...oldVal, [e.target.name]: e.target.value };
    });
  };

  const handleBankDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContractBankDetails((oldVal) => {
      return { ...oldVal, [e.target.name]: e.target.value };
    });
  };

  const printContract = () => {
    const userDetails = JSON.parse(localStorage.user_details);
    const arbitratorId = arbitrators.filter((a: any) => {
      return a.userEmail === userDetails.email;
    })[0]?.id;
    const proofreaderId = proofreaderUser.filter((a: any) => {
      return a.email === userDetails.email;
    })[0]?.id;
    userDetails.role === "Arbitrator"
      ? GetReports(
          `Report/ArbitratorContract?arbitratorId=${arbitratorId}&publishingId=${publicationDetails.id}`,
          {},
          `${t("Conract")} ${t(userDetails.role)} - ${t("Request_num")} ${
            publicationDetails.id
          }.pdf`
        )
      : GetReports(
          `Report/ProofreaderContract?proofreaderId=${proofreaderId}&publishingId=${publicationDetails.id}`,
          {},
          `${t("Conract")} ${t(userDetails.role)} - ${t("Request_num")} ${
            publicationDetails.id
          }.pdf`
        );
  };

  const handleActionButtonClick = (actionBtn: any) => {
    setuserAction(actionBtn);
    if (actionBtn.type === "Arbitrator_Accept_Contract") {
      setShowArbitratorAcceptContract(true);
    } else if (actionBtn.type === "Arbitrator_Print_Contract") {
      printContract();
    } else if (actionBtn.type === "Proofreader_Print_Contract") {
      printContract();
    } else if (actionBtn.type === "Proofreader_Accept_Contract") {
      setShowProofreaderAcceptContract(true);
    } else if (actionBtn.type === "Proofreader_Choose") {
      setShowSelectProofreaders(true);
    } else if (actionBtn.type === "Show_Members_Candidates") {
      setIsMembersCandidatesAction(true);
      setShowSelectArbitrator(true);
    } else if (actionBtn.type === "Accept_Arbitrators_Candidates") {
      setIsAcceptCandidatesAction(true);
      setShowSelectArbitrator(true);
    } else if (
      actionBtn.type === "Arbitrator_Choose" ||
      actionBtn.type === "Arbitrators_Choose"
    ) {
      setIsRecommendCandidatesAction(true);
      setShowSelectArbitrator(true);
    } else if (actionBtn.type === "Reject_request") {
      setShowRejectionForm(true);
    } else if (actionBtn.type === "Link") {
      window.open(`../${actionBtn.link}`, "_blank");
    } else if (
      ["Add_Matching_Form", "Show_Matching_Form"].includes(actionBtn.type)
    ) {
      setShowMatchingForm(true);
    } else {
      setShowCommonPopup(true);
    }

    dispatch(
      pushAlert({
        successMsgAR: actionBtn.responseAR,
        successMsgEN: actionBtn.responseEN,
        msgAR: actionBtn.confirmAR,
        msgEN: actionBtn.confirmEN,
        type: actionBtn.action,
        hasControls: true,
        invoker: "addPublication",
        onAcceptBody: {
          publishingId: props.publishingId,
          arbitrators: selectedArbitrators,
          proofreader: selectedProofreader,
          comment,
          status: actionBtn?.action,
          bank: contractBankDetails,
          matchingForm: matchingFormDetails,
          rejectReasons: selectedRejectionReasons,
          attachments,
        },
      })
    );
  };
  return (
    <>
      <div className="main-form publication-comments">
        <div className="main-form__footer">
          <div className="col-4">
            <StaticButtons
              setShowCommentsPopup={setShowCommentsPopup}
              setShowRejectionReasonsPopup={setShowRejectionReasonsPopup}
              setShowCriteriaPopup={setShowCriteriaPopup}
            />

            {props.actions?.map((actionBtn) => {
              return (
                <button
                  key={actionBtn.action}
                  onClick={() => handleActionButtonClick(actionBtn)}
                  type="button"
                  style={{ background: actionBtn.color }}
                >
                  {i18n.language === "en"
                    ? actionBtn.labelEN
                    : actionBtn.labelAR}
                </button>
              );
            })}
          </div>
        </div>
        <CommonPopup
          setShowCommonPopup={setShowCommonPopup}
          showCommonPopup={showCommonPopup}
          userAction={userAction}
          attachments={(files) => setAttachments(files)}
        />
        {/* Select Arbitrator Popup */}
        <ChooseArbitratorsPopup
          resetButtonsActionCheck={resetButtonsActionCheck}
          setShowSelectArbitrator={setShowSelectArbitrator}
          showSelectArbitrator={showSelectArbitrator}
          setComment={setComment}
          comment={comment}
          userAction={userAction}
          isMembersCandidatesAction={isMembersCandidatesAction}
          isAcceptCandidatesAction={isAcceptCandidatesAction}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          arbitrators={arbitrators}
          selectedArbitrators={selectedArbitrators}
          setSelectedArbitrators={setSelectedArbitrators}
          handleFormChange={handleFormChange}
        />
        {/* Comments Popup */}
        <CommentsPopup
          setShowCommentsPopup={setShowCommentsPopup}
          showCommentsPopup={showCommentsPopup}
        />
        {/* Rejection Reasons */}
        <RejectionReasonsPopup
          setShowRejectionReasonsPopup={setShowRejectionReasonsPopup}
          showRejectionReasonsPopup={showRejectionReasonsPopup}
        />
        {/* Criteria */}
        <CriertiaPopup
          setShowCriteriaPopup={setShowCriteriaPopup}
          showCriteriaPopup={showCriteriaPopup}
        />
      </div>
      {/* Rejection Reasons */}
      <RejectionFormPopup
        setShowRejectionForm={setShowRejectionForm}
        showRejectionForm={showRejectionForm}
        setSelectedRejectionReasons={setSelectedRejectionReasons}
        selectedRejectionReasons={selectedRejectionReasons}
      />

      {/* Choose Proofreaders */}
      <ChooseProofreaderPopup
        setShowSelectProofreaders={setShowSelectProofreaders}
        showSelectProofreaders={showSelectProofreaders}
        setSearchTerm={setSearchTerm}
        proofreaders={proofreaders}
        searchTerm={searchTerm}
        handleProofreadersFormChange={handleProofreadersFormChange}
        selectedProofreader={selectedProofreader}
        setComment={setComment}
        comment={comment}
      />
      {/* Proofreader Accept Contract */}
      <ProofreaderAcceptContractPopup
        setShowProofreaderAcceptContract={setShowProofreaderAcceptContract}
        showProofreaderAcceptContract={showProofreaderAcceptContract}
        contractBankDetails={contractBankDetails}
        handleBankDetailsChange={handleBankDetailsChange}
      />
      {/* Arbitrator Accept Contract */}
      <ArbitratorAcceptContractPopup
        setShowArbitratorAcceptContract={setShowArbitratorAcceptContract}
        showArbitratorAcceptContract={showArbitratorAcceptContract}
        contractBankDetails={contractBankDetails}
        handleBankDetailsChange={handleBankDetailsChange}
      />

      {/* Matching Form */}
      <MatchingFormPopup
        setShowMatchingForm={setShowMatchingForm}
        showMatchingForm={showMatchingForm}
        matchingFormDetails={matchingFormDetails}
        handleMatchingFormDetailsChange={handleMatchingFormDetailsChange}
      />
    </>
  );
};
