import { FormPanel } from "components/FormPanel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import {
  addOtherPublicationSameTitle,
  modifyPublicationDetails,
  removeOtherPublicationSameTitle,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import DatePicker from "react-datepicker";
import convertDateToFormat from "utils/convertDateToFormat";
export const ProposalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formType = useAppSelector(selectFormType);
  const publicationDetails = useAppSelector(selectPublicationDetails);

  const getPerson = (formType: string) => {
    switch (formType) {
      case t("manu_script"):
        return t("investigator_book");

      case t("book_translation"):
        return t("translator_book");
      case t("dictionary"):
        return t("Author_dictionary");

      default:
        return t("author_book");
    }
  };
  return (
    <FormPanel title={`${t("info_about_title")} ${formType}`}>
      <div className="main-form__radio-container">
        <label>
          {t("has_content")}
          {formType} {t("specialty_qustion")} {getPerson(formType)}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isRelatedToAuthorSpecialization"
            value="1"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
            checked={!!+publicationDetails.isRelatedToAuthorSpecialization}
            type="radio"
          />
          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="isRelatedToAuthorSpecialization"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.isRelatedToAuthorSpecialization}
            type="radio"
          />
          {t("no")}
        </label>
      </div>

      {!+publicationDetails.isRelatedToAuthorSpecialization && (
        <div className="main-form__input-container">
          <label>
            {t("reason_interest_question")}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("reason_interest_question")}
            name="writingReasons"
            value={publicationDetails.writingReasons}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
      )}

      <div className="main-form__radio-container">
        <label>
          {t("has_the")}
          {getPerson(formType)}
          {t("other_publications_same_question")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasSameTopic"
            value="1"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
            checked={!!+publicationDetails.hasSameTopic}
            type="radio"
          />
          {t("yes")}
        </label>
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="hasSameTopic"
            value="0"
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: e.target.name,
                  val: e.target.value,
                })
              )
            }
            checked={!+publicationDetails.hasSameTopic}
            type="radio"
          />
          {t("no")}
        </label>
      </div>

      {!!+publicationDetails.hasSameTopic && (
        <>
          {publicationDetails.publishingSimilars.map((_: any, i: number) => (
            <div key={i} className="controlled-inputs">
              <div className="main-form__input-container">
                <label>
                  {t("title_for")}
                  {formType}
                </label>
                <input
                  autoComplete="off"
                  disabled={publicationDetails.isViewMode}
                  placeholder={`${t("title_for")}${formType}`}
                  type="text"
                  name="title"
                  value={publicationDetails.publishingSimilars[i].title}
                  onChange={(e) =>
                    dispatch(
                      modifyPublicationDetails({
                        key: e.target.name,
                        val: e.target.value,
                        dynamicFormKey: "publishingSimilars",
                        i,
                      })
                    )
                  }
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("publication_place")}</label>
                <input
                  autoComplete="off"
                  disabled={publicationDetails.isViewMode}
                  placeholder={t("publication_place")}
                  type="text"
                  name="publishingPlace"
                  value={
                    publicationDetails.publishingSimilars[i].publishingPlace
                  }
                  onChange={(e) =>
                    dispatch(
                      modifyPublicationDetails({
                        key: e.target.name,
                        val: e.target.value,
                        dynamicFormKey: "publishingSimilars",
                        i,
                      })
                    )
                  }
                />
              </div>
              <div className="main-form__input-container">
                <label>{t("publication_date")}</label>
                <DatePicker
                  disabled={publicationDetails.isViewMode}
                  placeholderText={t("publication_date")}
                  onChange={(date) =>
                    dispatch(
                      modifyPublicationDetails({
                        key: "publishingDate",
                        val: convertDateToFormat(
                          publicationDetails.publishingSimilars[i]
                            .publishingDate
                        ),
                        dynamicFormKey: "publishingSimilars",
                        i,
                      })
                    )
                  }
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
              <button
                className="btn delete"
                type="button"
                onClick={(event) =>
                  dispatch(removeOtherPublicationSameTitle({ removeId: i }))
                }
              >
                {t("delete")}
              </button>
            </div>
          ))}
          <div className="text-center">
            <button
              className="btn"
              type="button"
              onClick={() => dispatch(addOtherPublicationSameTitle())}
            >
              {t("add")}
            </button>
          </div>
          <div className="main-form__input-container">
            <label>
              {t("differences_between")}
              {formType} / {t("Mentioned_suggested")}
              <span className="required-input">*</span>
            </label>
            <textarea
              disabled={publicationDetails.isViewMode}
              placeholder={`${t("differences_between")}${formType} / ${t(
                "Mentioned_suggested"
              )}`}
              name="similarAndDifferentWithSuggest"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: e.target.name,
                    val: e.target.value,
                  })
                )
              }
              value={publicationDetails.similarAndDifferentWithSuggest}
            ></textarea>
          </div>
        </>
      )}
    </FormPanel>
  );
};
