import { FormPanel } from "components/FormPanel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
export const ContractFavOptions = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel title={t("contract_fav_options")}>
      <div className="main-form__radio-container">
        <div className="col f-box">
          <label>{t("contract_options")}</label>
          <div>
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                name="contractType"
                value="بيع الحقوق كاملة لمدة غير محددة"
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "contractOptions",
                      val: e.target.value,
                    })
                  )
                }
                checked={
                  publicationDetails.contractOptions ===
                  "بيع الحقوق كاملة لمدة غير محددة"
                }
                type="radio"
              />
              {t("Book_contract")}
            </label>
            <p className="contract_des">{t("Book_contract_des")}</p>
          </div>
          <div>
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                name="contractType"
                value={t("sale_copyright_limited_period")}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "contractOptions",
                      val: e.target.value,
                    })
                  )
                }
                checked={
                  publicationDetails.contractOptions ===
                  t("sale_copyright_limited_period")
                }
                type="radio"
              />
              {t("Intellectual_roperty_rights_contract")}
            </label>
            <p className="contract_des">
              {t("Intellectual_roperty_rights_contract_des")}
            </p>
          </div>
          <div>
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                name="contractType"
                value={t("partnership_system")}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "contractOptions",
                      val: e.target.value,
                    })
                  )
                }
                checked={
                  publicationDetails.contractOptions === t("partnership_system")
                }
                type="radio"
              />
              {t("Partnership_contracts_intellectual_property_rights")}
            </label>
            <p className="contract_des">
              {t("Partnership_contracts_intellectual_property_rights_des")}
            </p>
          </div>
        </div>
      </div>
      <div className="main-form__input-container">
        <label>{t("notes")}</label>
        <textarea
          disabled={publicationDetails.isViewMode}
          placeholder={t("notes")}
          name="contractNotes"
          onChange={(e) =>
            dispatch(
              modifyPublicationDetails({
                key: "contractNotes",
                val: e.target.value,
              })
            )
          }
          value={publicationDetails.contractNotes}
        ></textarea>
      </div>
    </FormPanel>
  );
};
