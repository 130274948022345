import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import { FormPanel } from "components/FormPanel";
import {
  GetPublications,
  GetPublicationsLists,
  RegisterPublication,
  UpdatePublication,
} from "services/publications.service";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  storePublicationDetails,
  resetPublicationDetails,
  selectPublicationDetails,
  modifyPublicationDetails,
  toggleViewMode,
} from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { PublicationComments } from "../../../../pages/PublicationComments";
import { CommonForm } from "./CommonForm";
import { ConferenceForm } from "./ConferenceForm";
import { ManuscriptBookForm } from "./ManuscriptBookForm";
import { ScientificLetter } from "./ScientificLetter";
import { ScientificResearch } from "./ScientificResearch";
import { TranslateBook } from "./TranslateBook";
import { modifyFormState, selectFormType } from "store/states/formTypeSlice";
import { ArbitrationForms } from "../../../../arbitrator/ArbitrationForms";
import Divider from "components/Divider";
import * as formCodes from "../../../../../utils/forms.js";
import { NomationArbitrators } from "./common/NomationArbitrators";
export const AddPublication = () => {
  const formType = useAppSelector(selectFormType);
  const { isApplicant } = useAppSelector(selectUserDetails);
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const publishingId = searchParams.get("publishingId") || 0;
  const isPrintViewMode =
    searchParams.get("isPrintViewMode") === "true" || false;
  const navigate = useNavigate();
  const [formSteps, setFormSteps] = useState({
    current: publishingId ? 2 : 1,
    total: 0,
    selectors: [],
  });
  const [applicationTypeLists, setApplicationTypeLists] = useState({
    fields: [],
    proposals: [],
    textbooks: [],
    applications: [],
    specializations: [],
  });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(resetPublicationDetails());
    if (isApplicant) {
      getPublicationsLists();
    } else {
      if (publishingId) getPublication(+publishingId);
    }
    if (window.location.pathname.includes("view")) {
      dispatch(toggleViewMode(true));
    }
  }, []);

  useEffect(() => {
    const formPanels = document.querySelectorAll(".form-panel-selector");
    setFormSteps({
      ...formSteps,
      total: formPanels.length,
      selectors: [].slice.call(formPanels),
    });
  }, [formType]);
  useEffect(() => {
    stepNavigate();
  }, [formSteps.selectors, formSteps.current]);

  const getPublicationsLists = () => {
    GetPublicationsLists().then((response) => {
      const res = response.data;
      setApplicationTypeLists({ ...res });
      if (publishingId) {
        getPublication(+publishingId);
      }
    });
  };

  const openHiddenPanels = () => {
    if (isPrintViewMode) {
      const elementsWithHideClass = document.querySelectorAll(".hide");
      for (let i = 1; i < elementsWithHideClass.length; i++) {
        elementsWithHideClass[i].classList.remove("hide");
      }
    }
  };

  const getPublication = (id: number) => {
    GetPublications(true, id).then((response) => {
      dispatch(storePublicationDetails(response.data));
      dispatch(modifyFormState(response.data.applicationId));
      openHiddenPanels();
    });
  };
  const stepNavigate = () => {
    formSteps.selectors.forEach((el: HTMLElement, i: number) => {
      i + 1 === formSteps.current
        ? el.classList.remove("hide")
        : el.classList.add("hide");
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Form Listen & Submit
  const handleFormChange = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | {
          target: {
            name: string;
            value: {
              publishingArbitratorId: number;
              proposedArbitratorId: number;
            };
          };
        },
    dynamicFormKey?:
      | "publishingCompetitors"
      | "publishingAuthors"
      | "publishingSimilars",
    i?: number
  ) => {
    const key = event.target.name;
    const val = event.target.value;
    dispatch(modifyPublicationDetails({ key, val, dynamicFormKey, i }));
    if (key === "applicationId") {
      dispatch(modifyFormState(val));
    }
  };
  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    RegisterPublication(publicationDetails).then((res) => {
      dispatch(modifyPublicationDetails({ key: "id", val: res.data.id }));
      dispatch(
        modifyPublicationDetails({
          key: "applicationName",
          val: res.data.applicationName,
        })
      );
    });
  };
  const handleFormUpdate = async (event: any, isSubmit = false) => {
    event.preventDefault();
    if (isApplicant && !publicationDetails.isViewMode) {
      const res = await UpdatePublication({ ...publicationDetails, isSubmit });

      dispatch(storePublicationDetails(res.data));
      isSubmit &&
        navigate("/applicant/thanks", {
          state: {
            title: {
              ar: publicationDetails.arabicTitle,
              en: publicationDetails.englishTitle,
            },
            id: publicationDetails.id,
          },
        });
    }
  };
  const renderForms = () => {
    switch (formType) {
      case t("manu_script"):
        return <ManuscriptBookForm />;
      case t("book_translation"):
        return <TranslateBook />;
      case t("scientific_letter"):
        return <ScientificLetter />;
      case t("conference"):
        return <ConferenceForm />;
      case t("research"):
        return <ScientificResearch />;
      case t("series"):
      case t("dictionary"):
      case t("atlas"):
      case t("encyclopedia"):
      case t("book"):
        return <CommonForm />;
    }
  };

  const validateForm = () => {
    let missingKeys: string[] = [];
    const formNumber = publicationDetails.applicationName.split("-")[1];
    const formCode = ("form" + formNumber) as
      | "form2"
      | "form3"
      | "form4"
      | "form5"
      | "form6"
      | "form7"
      | "form8"
      | "form9"
      | "form10"
      | "form11"
      | "form12"
      | "form13"
      | "form14"
      | "form15";
    const formPropKeys: any = formCodes[formCode];

    if (formPropKeys) {
      const tempPublicationDetails = publicationDetails as any;
      for (const key in formPropKeys) {
        const validationProps = formPropKeys[key];

        for (const validationProp of validationProps) {
          for (const ele in validationProp) {
            const validateMissingKeys = (x: any) => {
              if (!tempPublicationDetails[x] && +key <= formSteps.current) {
                missingKeys.push(x);
              }
            };
            if (ele === "mainInputs") {
              validationProp.mainInputs.map(validateMissingKeys);
            } else if (tempPublicationDetails[ele] === "1") {
              validationProp[ele].map(validateMissingKeys);
            }
            if (ele === "textareInputs") {
              validationProp[ele].map((prop: string) => {
                const key = prop.split("_")[0];
                const count = +prop.split("_")[1];
                if (tempPublicationDetails[key].split(" ").length > count) {
                  missingKeys.push(`${key}_${count}`);
                }
              });
            }
          }
        }
      }
    }
    return missingKeys;
  };
  const selectFieldName: any = applicationTypeLists.fields.filter(
    (field) => field["id"] === +publicationDetails.fieldId
  )[0];

  return (
    <div className="container">
      <Breadcrumb />
      <div
        className={
          publicationDetails.startArbitration ? "arbitrations-container" : ""
        }
      >
        {useMemo(
          () => !!publicationDetails.startArbitration && <ArbitrationForms />,
          [publicationDetails.startArbitration]
        )}
        <div>
          <form
            autoComplete="off"
            className={`main-form ${!isApplicant && "view-mode"}`}
            onKeyUp={validateForm}
          >
            <FormPanel title={t("Apply_Publication")}>
              <div
                className={
                  applicationTypeLists.specializations.filter(
                    (spec) => spec["fieldId"] === +publicationDetails.fieldId
                  ).length > 0
                    ? "col-4"
                    : "col-3"
                }
              >
                <div className="main-form__input-container">
                  <label>{t("field")}</label>
                  <select
                    value={publicationDetails.fieldId}
                    name="fieldId"
                    onChange={handleFormChange}
                  >
                    <option value="">{t("field")}</option>
                    {applicationTypeLists.fields.map((field) => (
                      <option key={field["id"]} value={field["id"]}>
                        {field["name"]}
                      </option>
                    ))}
                  </select>
                </div>
                {applicationTypeLists.specializations.filter(
                  (spec) => spec["fieldId"] === +publicationDetails.fieldId
                ).length > 0 && (
                  <div className="main-form__input-container">
                    <label>{t("Specialization")}</label>
                    <select
                      value={publicationDetails.specializationId}
                      name="specializationId"
                      onChange={handleFormChange}
                    >
                      <option>{t("Specialization")}</option>
                      {applicationTypeLists.specializations
                        .filter(
                          (spec) =>
                            spec["fieldId"] === +publicationDetails.fieldId
                        )
                        .map((spec) => (
                          <option key={spec["id"]} value={spec["id"]}>
                            {spec["name"]}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <div className="main-form__input-container">
                  <label>
                    {selectFieldName &&
                    selectFieldName["name"] &&
                    selectFieldName["name"].includes("مجل")
                      ? t("Magazine_name")
                      : t("book_type")}
                  </label>
                  <select
                    value={publicationDetails.proposalId}
                    name="proposalId"
                    onChange={handleFormChange}
                  >
                    <option>
                      {selectFieldName &&
                      selectFieldName["name"] &&
                      selectFieldName["name"].includes("مجل")
                        ? t("Magazine_name")
                        : t("book_type")}
                    </option>
                    {applicationTypeLists.proposals
                      .filter(
                        (proposal) =>
                          proposal["fieldId"] === +publicationDetails.fieldId
                      )
                      .map((proposal) => (
                        <option
                          key={proposal["proposalId"]}
                          value={proposal["proposalId"]}
                        >
                          {proposal["proposalName"]}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="main-form__input-container">
                  <label>{t("Application_form")}</label>
                  <select
                    name="applicationId"
                    value={publicationDetails.applicationId}
                    onChange={handleFormChange}
                  >
                    <option value="">{t("Application_form")}</option>
                    {applicationTypeLists.applications
                      .filter(
                        (app) =>
                          app["proposalId"] === +publicationDetails.proposalId
                      )
                      .map((app, i) => (
                        <option key={i} value={app["applicationId"]}>
                          {app["applicationDescription"]}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </FormPanel>
            {renderForms()}
            {formType && !publicationDetails.startArbitration && (
              <NomationArbitrators />
            )}
          </form>
        </div>
      </div>

      {!isPrintViewMode &&
        (!!publicationDetails.publishingActions?.length ||
          !!publicationDetails.publishingFeedbacks?.length) && (
          <PublicationComments
            fieldId={publicationDetails.fieldId}
            publishingId={+publishingId}
            actions={publicationDetails.publishingActions}
            refreshPublicatoinDetails={() => getPublication(+publishingId)}
          />
        )}
      {!isPrintViewMode &&
        formSteps.total > 1 &&
        publicationDetails.applicationId && (
          <>
            <Divider />
            <div className="main-form__footer col-2 no-print">
              <div className="col-2">
                {formSteps.current > 2 && (
                  <button
                    type="button"
                    onClick={() => {
                      setFormSteps({
                        ...formSteps,
                        current: formSteps.current - 1,
                      });
                      stepNavigate();
                    }}
                  >
                    {t("previous")}
                  </button>
                )}
                {formSteps.current === formSteps.total ? (
                  isApplicant &&
                  !publicationDetails.isViewMode && (
                    <button
                      type="button"
                      onClick={(event) => handleFormUpdate(event, true)}
                      disabled={
                        !!validateForm().length &&
                        formSteps.total === formSteps.current
                      }
                    >
                      {t("submit_request")}
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    disabled={
                      publicationDetails.applicationName !== "ق-13" &&
                      isApplicant &&
                      formSteps.current === formSteps.total - 2 &&
                      !publicationDetails.acknowledgement
                    }
                    onClick={(event) => {
                      setFormSteps({
                        ...formSteps,
                        current: formSteps.current + 1,
                      });
                      stepNavigate();
                      formSteps.current === 1
                        ? handleFormSubmit(event)
                        : handleFormUpdate(event);
                      validateForm();
                    }}
                  >
                    {t("next")}
                    {isApplicant &&
                      !publicationDetails.isViewMode &&
                      ` | ${
                        formSteps.current === 1
                          ? t("start_request")
                          : t("Save_draft")
                      }`}
                  </button>
                )}
              </div>
              <p>
                {t("step_number")} {formSteps.current - (!isApplicant ? 1 : 0)}{" "}
                {t("from_total")} {formSteps.total - (!isApplicant ? 1 : 0)}
              </p>
            </div>
          </>
        )}
      <div className="main-form__footer col-2 no-print">
        {isPrintViewMode && (
          <button type="button" onClick={window.print}>
            {t("Print")}
          </button>
        )}
      </div>

      {!publicationDetails.isViewMode && !!validateForm().length && (
        <div className="form-validate">
          {t("Please_enter_following_fields")}:
          <ul>
            {validateForm().map((key) => (
              <li key={key}>{t(key)}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
