import React from "react";
import { FormPanel } from "../../../../../components/FormPanel";
import { useTranslation } from "react-i18next";
import AcknowledgmentRightProperty from "./common/AcknowledgmentRightProperty";
import { ContractFavOptions } from "./common/ContractFavOptions";
import { TargetAudiance } from "./common/TargetAudiance";
import { RateOfBookCompleted } from "./common/RateOfBookCompleted";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
import { selectFormType } from "store/states/formTypeSlice";
import InputNumber from "components/InputNumber";

export const ConferenceForm = () => {
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  const formType = useAppSelector(selectFormType);

  const { t } = useTranslation();
  return (
    <>
      <FormPanel title={t("conference_info")}>
        <div className="main-form__radio-container">
          <label>{t("Authorship_lang")}</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="authoringLanguage"
              value="arabic"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "authoringLanguage",
                    val: e.target.value,
                  })
                )
              }
              checked={publicationDetails.authoringLanguage === "arabic"}
              type="radio"
            />
            {t("arabic")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="authoringLanguage"
              value="english"
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "authoringLanguage",
                    val: e.target.value,
                  })
                )
              }
              checked={publicationDetails.authoringLanguage === "english"}
              type="radio"
            />
            {t("english")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="authoringLanguage"
              value=""
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "authoringLanguage",
                    val: e.target.value,
                  })
                )
              }
              checked={
                !["arabic", "english"].includes(
                  publicationDetails.authoringLanguage
                )
              }
              type="radio"
            />
            {t("other")}
          </label>
          {!["arabic", "english"].includes(
            publicationDetails.authoringLanguage
          ) && (
            <label>
              <input
                autoComplete="off"
                disabled={publicationDetails.isViewMode}
                name="authoringLanguage"
                value={publicationDetails.authoringLanguage}
                onChange={(e) =>
                  dispatch(
                    modifyPublicationDetails({
                      key: "authoringLanguage",
                      val: e.target.value,
                    })
                  )
                }
                type="input"
                placeholder={t("language")}
              />
            </label>
          )}
        </div>
        <div className="col-3">
          <div className="main-form__input-container">
            <label>
              {t("title_for")}
              {formType} {t("by_ar")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={`${t("title_for")}${formType} ${t("by_ar")}`}
              type="text"
              name="arabicTitle"
              value={publicationDetails.arabicTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "arabicTitle",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("title_for")}
              {formType} {t("by_en")}
              <span className="required-input">*</span>
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={`${t("title_for")}${formType} ${t("by_en")}`}
              type="text"
              name="englishTitle"
              value={publicationDetails.englishTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "englishTitle",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="main-form__input-container">
            <label>
              {t("title_for")}
              {formType} {t("by_other")}
            </label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              placeholder={`${t("title_for")}${formType} ${t("by_other")}`}
              type="text"
              name="otherLanguageTitle"
              value={publicationDetails.otherLanguageTitle}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "otherLanguageTitle",
                    val: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("conference_tobic")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("conference_tobic")}
            name="topic"
            value={publicationDetails.topic}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "topic",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("conference_target")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("conference_target")}
            name="otherGoal"
            value={publicationDetails.otherGoal}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "otherGoal",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("scientific_method_followed")}{" "}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("scientific_method_followed")}
            name="scientificMethod"
            value={publicationDetails.scientificMethod}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "scientificMethod",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("conference_valeu")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("conference_valeu")}
            name="scientificValue"
            value={publicationDetails.scientificValue}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "scientificValue",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("list_search_conference")}{" "}
            <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("list_search_conference")}
            name="contentsList"
            value={publicationDetails.contentsList}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "contentsList",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("organizerBrief")} <span className="required-input">*</span>
          </label>
          <textarea
            disabled={publicationDetails.isViewMode}
            placeholder={t("organizerBrief")}
            name="organizerBrief"
            value={publicationDetails.organizerBrief}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "organizerBrief",
                  val: e.target.value,
                })
              )
            }
          ></textarea>
        </div>
        <div className="main-form__input-container">
          <label>
            {t("page_num")} {t("250_word_page")}
            <span className="required-input">*</span>
          </label>
          <InputNumber
            disabled={publicationDetails.isViewMode}
            placeholder={`${t("page_num")} ${t("250_word_page")}`}
            name="pagesNumber"
            value={publicationDetails.pagesNumber}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "pagesNumber",
                  val: e.target.value,
                })
              )
            }
          />
        </div>
      </FormPanel>
      <RateOfBookCompleted />
      <TargetAudiance />
      <ContractFavOptions />
      <AcknowledgmentRightProperty />
    </>
  );
};
