import { FormPanel } from "components/FormPanel";
import InputNumber from "components/InputNumber";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormType } from "store/states/formTypeSlice";
import {
  modifyPublicationDetails,
  selectPublicationDetails,
} from "store/states/publicationDetailsSlice";
export const TargetAudiance = () => {
  const formType = useAppSelector(selectFormType);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const publicationDetails = useAppSelector(selectPublicationDetails);
  return (
    <FormPanel title={`${t("target_audiance_for")}${formType}`}>
      <div className="main-form__radio-container col">
        <label>
          <input
            autoComplete="off"
            disabled={publicationDetails.isViewMode}
            name="targetAudience"
            value={t("Pre-university_education")}
            checked={publicationDetails.targetAudience?.includes(
              t("Pre-university_education")
            )}
            onChange={(e) =>
              dispatch(
                modifyPublicationDetails({
                  key: "targetAudience",
                  val: e.target.value,
                })
              )
            }
            type="checkbox"
          />
          {t("Pre-university_education")}
        </label>
        <div className="f-box">
          <label>{t("University_education")}:</label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("first_class")}
              checked={publicationDetails.targetAudience?.includes(
                t("first_class")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("first_class")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("second_class")}
              checked={publicationDetails.targetAudience?.includes(
                t("second_class")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("second_class")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("third_class")}
              checked={publicationDetails.targetAudience?.includes(
                t("third_class")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("third_class")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("fourth_class_after")}
              checked={publicationDetails.targetAudience?.includes(
                t("fourth_class_after")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("fourth_class_after")}
          </label>
        </div>
        <div className="f-box">
          <label>{t("Postgraduate")}:</label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("Higher_Diploma")}
              checked={publicationDetails.targetAudience?.includes(
                t("Higher_Diploma")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("Higher_Diploma")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("Master")}
              checked={publicationDetails.targetAudience?.includes(t("Master"))}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("Master")}
          </label>
          <label>
            <input
              autoComplete="off"
              disabled={
                publicationDetails.isViewMode ||
                publicationDetails.targetAudience.includes(
                  "تعليم ما قبل الجامعه"
                )
              }
              name="targetAudience"
              value={t("Doctorate")}
              checked={publicationDetails.targetAudience?.includes(
                t("Doctorate")
              )}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "targetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="checkbox"
            />
            {t("Doctorate")}
          </label>
        </div>
        <div className="f-box">
          <label>{t("other")}:</label>
          <label>
            <input
              autoComplete="off"
              disabled={publicationDetails.isViewMode}
              name="otherTargetAudience"
              placeholder={t("other")}
              value={publicationDetails.otherTargetAudience}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "otherTargetAudience",
                    val: e.target.value,
                  })
                )
              }
              type="text"
            />
          </label>
        </div>
        <div className="f-box">
          <label>
            {t("expected_request_for")}
            {formType} {t("categories_specified_above")}:
            <span className="required-input">*</span>
          </label>
          <label className="expectedOrderVolume">
            <InputNumber
              disabled={publicationDetails.isViewMode}
              name="expectedOrderVolume"
              placeholder={t("volume_requests")}
              value={publicationDetails.expectedOrderVolume}
              onChange={(e) =>
                dispatch(
                  modifyPublicationDetails({
                    key: "expectedOrderVolume",
                    val: e.target.value ? e.target.value : 0,
                  })
                )
              }
            />
          </label>
        </div>
      </div>
    </FormPanel>
  );
};
