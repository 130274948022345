import axios from "axios";
export const GetArchiveInfo = (id: string) => {
  return axios.get(`Archives/${id}`);
};
export const GetArchiveList = () => {
  return axios.get("Archives");
};
export const AddArchive = (archiveInfo: any) => {
  return axios.post("Archives", archiveInfo);
};
export const UpdateArchiveInfo = (id: any, archiveDetails: any) => {
  return axios.put(`Archives/${id}`, archiveDetails);
};
