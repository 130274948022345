import { GetAuthors } from "services/authors_acc.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "components/Popup/Popup";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import "./auhtors_account.scss";
export const AuthorsAcc = () => {
  const [authorsList, setAuthorsList] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [author, setAuthor] = useState({
    id: 0,
    createdAt: "",
    modifiedAt: "",
    doerId: 0,
    isActive: "",
    isDeleted: "",
    fullName: "",
    academicQualification: "",
    generalScientificSpecialization: "",
    scientificRank: "",
    otherScientificRank: "",
    exactScientificSpecialization: "",
    jobStatus: "",
    destinationUniversity: "",
    destinationCollege: "",
    destinationSection: "",
    destinationName: "",
    destinationCountry: "",
    destinationCity: "",
    destinationPOBox: "",
    destinationPostalCode: "",
    destinationPhoneNumber: "",
    destinationOtherPhoneNumber: "",
    destinationEmail: "",
    otherPhoneNumber: "",
    buildingNumber: "",
    poBox: "",
    postalCode: "",
    streetName: "",
    city: "",
    country: "",
    cvUrl: "",
    cvName: "",
    userProfileUrl: "",
    userProfileName: "",
    userId: 0,
    userName: "",
    userEmail: "",
    userPhoneNumber: "",
  });
  useEffect(() => {
    GetAuthors().then((res) => {
      setAuthorsList(res.data);
    });
  }, []);
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;
  return (
    <div className="container">
      <Breadcrumb />
      <div className="authors_accounts">
        <table className="basic-table authors" cellSpacing={0}>
          <thead>
            <tr>
              <th>{t("list")} </th>
              <th> {t("author_name")}</th>
              <th> {t("specialization")}</th>
              <th> {t("facluty")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {authorsList.length ? (
              authorsList.map((author: any, i: number) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{author.fullName}</td>
                  <td>{author.generalScientificSpecialization}</td>
                  <td>{author.destinationCollege}</td>
                  <td>
                    <button
                      className="btn normal"
                      onClick={() => {
                        setAuthor(authorsList[i]);
                        setShowPopup(true);
                      }}
                    >
                      {t("view")}
                    </button>
                    {author.cvUrl.length > 0 && (
                      <button className="btn success">
                        <a
                          className="authors_url"
                          target="blank"
                          href={baseURL + author.cvUrl}
                        >
                          {t("view_CV")}
                        </a>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12}>{t("no_data_show")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {author && (
        <Popup
          onClose={() => {
            setShowPopup(false);
          }}
          showPopup={showPopup}
        >
          <form
            autoComplete="off"
            className="modern-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <h2>{t("author_info")}</h2>
            <div className="col-2">
              <div className="modern-form__input-container">
                <label>{t("user_name")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="fullName"
                  readOnly
                  value={author.fullName}
                  placeholder={t("user_name")}
                  autoFocus
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("email")}</label>
                <input
                  autoComplete="off"
                  type="email"
                  name="userEmail"
                  readOnly
                  value={author.userEmail}
                  placeholder={t("email")}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="modern-form__input-container">
                <label>{t("phone")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="userPhoneNumber"
                  readOnly
                  value={author.userPhoneNumber}
                  placeholder={t("phone")}
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("personality_email")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationEmail"
                  readOnly
                  value={author.destinationEmail}
                  placeholder={t("personality_email")}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="modern-form__input-container">
                <label>{t("streetName")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="streetName"
                  readOnly
                  value={author.streetName}
                  placeholder={t("streetName")}
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("city")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationCity"
                  readOnly
                  value={author.destinationCity}
                  placeholder={t("city")}
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("country")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationCountry"
                  readOnly
                  value={author.destinationCountry}
                  placeholder={t("country")}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="modern-form__input-container">
                <label>{t("exact_scientific_specialization")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="exactScientificSpecialization"
                  readOnly
                  value={author.exactScientificSpecialization}
                  placeholder={t("exact_scientific_specialization")}
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("General_scientific_specialization")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="generalScientificSpecialization"
                  readOnly
                  value={author.generalScientificSpecialization}
                  placeholder={t("General_scientific_specialization")}
                />
              </div>
              <div className="modern-form__input-container">
                <label>{t("universtly")}</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="destinationUniversity"
                  readOnly
                  value={author.destinationUniversity}
                  placeholder={t("universtly")}
                />
              </div>
            </div>
            <div className="modern-form__footer">
              <button
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                {t("close")}
              </button>
            </div>
          </form>
        </Popup>
      )}
    </div>
  );
};
