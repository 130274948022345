import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "components/Popup/Popup";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pushAlert } from "store/states/alertSlice";
import { selectPublicationDetails } from "store/states/publicationDetailsSlice";
import { selectUserDetails } from "store/states/userDetailsSlice";
import { Arbitrator } from "types/arbitrator";

interface ChooseArbitratorsPopupProps {
  resetButtonsActionCheck: () => void;
  setShowSelectArbitrator: (type: boolean) => void;
  showSelectArbitrator: boolean;
  setComment: (comment: string) => void;
  comment: string;
  userAction: any;
  isMembersCandidatesAction: boolean;
  isAcceptCandidatesAction: boolean;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  arbitrators: Arbitrator[];
  selectedArbitrators: Arbitrator[];
  setSelectedArbitrators: (arbitrators: Arbitrator[]) => void;
  handleFormChange: (
    arbitrator: Arbitrator,
    e: ChangeEvent<HTMLInputElement>
  ) => void;
}
export const ChooseArbitratorsPopup = (props: ChooseArbitratorsPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showReferrersColumn, setShowReferrersColumn] = useState(false);
  const {
    isScientificCommitteeMember,
    isScientificCommitteeDecided,
    isSecretarialEmployee,
  } = useAppSelector(selectUserDetails);
  const { publishingCandidateArbitrators } = useAppSelector(
    selectPublicationDetails
  );
  const {
    resetButtonsActionCheck,
    setShowSelectArbitrator,
    showSelectArbitrator,
    setComment,
    comment,
    userAction,
    isMembersCandidatesAction,
    isAcceptCandidatesAction,
    searchTerm,
    setSearchTerm,
    arbitrators,
    selectedArbitrators,
    setSelectedArbitrators,
    handleFormChange,
  } = props;
  let tempArbitratorList: any = [];
  if (isMembersCandidatesAction) {
    tempArbitratorList = publishingCandidateArbitrators;
  } else {
    tempArbitratorList = isAcceptCandidatesAction
      ? selectedArbitrators
      : arbitrators;
  }

  useEffect(() => {
    setShowReferrersColumn(tempArbitratorList.some((a: any) => a.referrers));
  }, [tempArbitratorList]);

  useEffect(() => {
    let tempArbitratorList: any = [];
    if (isMembersCandidatesAction) {
      tempArbitratorList = publishingCandidateArbitrators;
    } else {
      tempArbitratorList = isAcceptCandidatesAction
        ? selectedArbitrators
        : arbitrators;
    }
  }, [publishingCandidateArbitrators, selectedArbitrators, arbitrators]);

  const moveItemUp = (i: any) => {
    const newArbitratorList = [...selectedArbitrators];
    const item = newArbitratorList[i];
    newArbitratorList.splice(i, 1);
    newArbitratorList.splice(i - 1, 0, item);
    setSelectedArbitrators(newArbitratorList);
  };

  const moveItemDown = (i: any) => {
    const newArbitratorList = [...selectedArbitrators];
    const item = newArbitratorList[i];
    newArbitratorList.splice(i, 1);
    newArbitratorList.splice(i + 1, 0, item);
    setSelectedArbitrators(newArbitratorList);
  };

  const renderArbitrators = () => {
    return tempArbitratorList
      .filter((arbitrator: any) =>
        arbitrator.fullNameArabic.includes(searchTerm)
      )
      .map((arbitrator: any, i: any) => (
        <tr key={arbitrator.id}>
          {showReferrersColumn && <td>{arbitrator.referrers}</td>}
          {!(isAcceptCandidatesAction || isMembersCandidatesAction) && (
            <td>
              <input
                autoComplete="off"
                className="select-arbitrators"
                onChange={(e) => handleFormChange(arbitrator, e)}
                checked={
                  !!selectedArbitrators?.filter((a) => a.id === arbitrator.id)
                    .length
                }
                name="publishingArbitrators"
                value={arbitrator.id}
                type={
                  userAction.type === "Arbitrator_Choose" ? "radio" : "checkbox"
                }
              />
            </td>
          )}
          <td>{arbitrator.fullNameArabic}</td>
          {userAction.type === "Arbitrators_Choose" &&
            !isScientificCommitteeMember && (
              <td>
                <input
                  autoComplete="off"
                  type="text"
                  name="arbitration_money"
                  placeholder={t("amount")}
                  disabled={
                    !selectedArbitrators?.filter((a) => a.id === arbitrator.id)
                      .length
                  }
                  value={
                    selectedArbitrators?.filter(
                      (a) => a.id === arbitrator.id
                    )[0]?.amount || 0
                  }
                  onChange={(e) => handleFormChange(arbitrator, e)}
                />
              </td>
            )}

          <td>{arbitrator.degree}</td>
          <td>{arbitrator.scientificSpecialization}</td>
          <td>{arbitrator.userEmail}</td>
        </tr>
      ));
  };

  const renderSelectionButtonName = () => {
    if (isScientificCommitteeDecided || isScientificCommitteeMember) {
      return t("transfer_to_secertarial_employee");
    } else if (isSecretarialEmployee) {
      return t("transfer_to_scientific_committee_decided");
    }
  };

  return (
    <Popup
      onClose={() => {
        resetButtonsActionCheck();
        setShowSelectArbitrator(false);
      }}
      showPopup={showSelectArbitrator}
      widePopup={true}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setShowSelectArbitrator(false);
          dispatch(
            pushAlert({
              show: true,
            })
          );
        }}
        className="modern-form"
      >
        <h2>
          {isMembersCandidatesAction
            ? t("List_Candidates")
            : isAcceptCandidatesAction
            ? t("List_nominated_arbitrators")
            : `${t("Please_select")}
      ${
        userAction.type === "Arbitrator_Choose"
          ? t("arbitrator")
          : t("arbitrators")
      }`}
        </h2>
        {!(isMembersCandidatesAction || isAcceptCandidatesAction) && (
          <span>{t("minimum_5")}</span>
        )}

        <div className="comments-container__arbitratos-selection">
          {!(isAcceptCandidatesAction || isMembersCandidatesAction) && (
            <input
              autoComplete="off"
              onKeyUp={(event) =>
                setSearchTerm((event.target as HTMLInputElement).value)
              }
              type="text"
              placeholder={`${t("search_for")} ${
                userAction.type === "Arbitrator_Choose"
                  ? t("arbitrator")
                  : t("arbitrators")
              }`}
            />
          )}

          <div className="table-responsive">
            <table className="basic-table" cellSpacing={0}>
              <thead>
                <tr>
                  {!(isAcceptCandidatesAction || isMembersCandidatesAction) && (
                    <th>{t("choose")}</th>
                  )}
                  {showReferrersColumn && <th>{t("Referrer")}</th>}
                  <th>{t("arbitrator_name")}</th>
                  {userAction.type === "Arbitrators_Choose" &&
                    !isScientificCommitteeMember && (
                      <th>{t("arbitration_amount")} SAR</th>
                    )}
                  <th>{t("Scientific_specialization")}</th>
                  <th>{t("degree")}</th>
                  <th>{t("email")}</th>
                </tr>
              </thead>
              <tbody>
                {arbitrators.length ? (
                  renderArbitrators()
                ) : (
                  <tr>
                    <td colSpan={10}>{t("no_data_show")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!(isAcceptCandidatesAction || isMembersCandidatesAction) && (
            <table className="basic-table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>{t("sort")}</th>
                  {showReferrersColumn && <th>{t("Referrer")}</th>}
                  <th>{t("arbitrator_name")}</th>
                  <th>{t("email")}</th>
                </tr>
              </thead>
              <tbody>
                {selectedArbitrators?.map((a, i) => (
                  <tr key={a.id}>
                    <td>
                      <button
                        className="arrow"
                        type="button"
                        onClick={() => {
                          moveItemUp(i);
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "small" }}
                          icon={faChevronUp}
                        />
                      </button>
                      <button
                        type="button"
                        className="arrow"
                        onClick={() => moveItemDown(i)}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "small" }}
                          icon={faChevronDown}
                        />
                      </button>
                    </td>
                    <td>
                      {a.fullNameArabic}
                      {isScientificCommitteeMember && (
                        <span
                          onClick={() =>
                            setSelectedArbitrators([
                              ...selectedArbitrators.filter(
                                (arbitrator) => arbitrator.id !== a.id
                              ),
                            ])
                          }
                        >
                          x
                        </span>
                      )}
                    </td>
                    <td>{a.userEmail}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {!isMembersCandidatesAction && (
          <div className="modern-form__footer">
            <button disabled={selectedArbitrators.length < 5}>
              {isAcceptCandidatesAction
                ? t("Acceptance_nominated_arbitrators")
                : renderSelectionButtonName()}
            </button>
          </div>
        )}
      </form>
    </Popup>
  );
};
