import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SocialIcons = () => {
  return (
    <ul>
      <li>
        <a href={"https://www.facebook.com/ksupressadmin"} target="	_blank">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </li>
      <li>
        <a href={"https://twitter.com/ksupress_"} target="	_blank">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
      <li>
        <a
          target="	_blank"
          href={
            "https://api.whatsapp.com/send/?phone=966114695718&text&type=phone_number&app_absent=0"
          }
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </li>
    </ul>
  );
};
