import {
  AddFields,
  DeleteFields,
  EditFields,
  EnableDisableField,
  GetFields,
} from "services/fields.service";
import "../Fields/Fields.scss";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { Popup } from "components/Popup/Popup";
import { useTranslation } from "react-i18next";
import { GetBookType } from "services/book_type.service";

export const Fields = () => {
  const { t } = useTranslation();

  const [fieldsList, setFieldsList] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const fieldObject = {
    name: "",
    id: 0,
    isActive: true,
    proposalsIds: [],
  };
  const [selectedField, setSelectedField] = useState<any>(fieldObject);
  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [bookTypeList, setBookTypeList] = useState<any>([]);
  useEffect(() => {
    GetFields().then((res) => setFieldsList(res.data));
    GetBookType().then((res) => setBookTypeList(res.data));
  }, []);

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedField({
      ...selectedField,
      [event.target.name]: event.target.value,
    });
  };

  const toggleUserActivity = (field: any) => {
    const updatedFieldsList = fieldsList.map((f: any) =>
      f.id === field.id ? { ...field, isActive: !field.isActive } : f
    );
    setFieldsList(updatedFieldsList);
    EnableDisableField(field.id, field).then(() =>
      GetFields().then((res) => setFieldsList(res.data))
    );
  };

  const handleCheckBoxChange = (id: number) => {
    const idx = selectedField.proposalsIds.indexOf(id);
    if (idx > -1) {
      const temp = selectedField.proposalsIds;
      temp.splice(idx, 1);
      setSelectedField({ ...selectedField, proposalsIds: temp });
    } else {
      setSelectedField({
        ...selectedField,
        proposalsIds: [...selectedField.proposalsIds, id],
      });
    }
  };

  return (
    <div className="container">
      <Breadcrumb>
        <button
          onClick={() => {
            setShowAddEditPopup(true);
            setIsEditMode(false);
            setSelectedField(fieldObject);
          }}
        >
          {t("add_field")}
        </button>
      </Breadcrumb>
      <div className="filds-list">
        <table className="basic-table" cellSpacing={0}>
          <thead>
            <tr>
              <th> {t("number")}</th>
              <th> {t("Fields")}</th>
              <th colSpan={2}></th>
              <th>{t("activation")}</th>
            </tr>
          </thead>
          <tbody>
            {fieldsList.map((field: any) => (
              <tr key={field.id}>
                <td>{field.id}</td>
                <td>{field.name}</td>
                <td>
                  <button
                    className="btn edit"
                    onClick={() => {
                      setIsEditMode(true);
                      setShowAddEditPopup(true);
                      setSelectedField({
                        name: field.name,
                        id: field.id,
                        proposalsIds: field.proposalsIds,
                      });
                    }}
                  >
                    {t("Edit")}
                  </button>
                </td>
                <td>
                  <button
                    className="btn delete"
                    onClick={() => {
                      setShowRemovePopup(true);
                      setSelectedField({
                        name: field.name,
                        id: field.id,
                        proposalsIds: field.proposalsIds,
                      });
                    }}
                  >
                    {t("delete")}
                  </button>
                </td>
                <td>
                  <div className="check-box">
                    <input
                      autoComplete="off"
                      type="checkbox"
                      key={field.id}
                      checked={field.isActive}
                      onChange={() => toggleUserActivity(field)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Popup
        onClose={() => {
          setShowAddEditPopup(false);
        }}
        showPopup={showAddEditPopup}
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            isEditMode
              ? EditFields(
                  selectedField.id,
                  selectedField,
                  selectedField.proposalsIds
                ).then(() => {
                  GetFields().then((res) => setFieldsList(res.data));
                  setShowAddEditPopup(false);
                })
              : AddFields(selectedField, selectedField.proposalsIds).then(
                  () => {
                    GetFields().then((res) => setFieldsList(res.data));
                    setShowAddEditPopup(false);
                  }
                );
          }}
          className="modern-form"
        >
          <h2>{isEditMode ? t("edit_field") : t("add_field")}</h2>
          <div className="modern-form__input-container">
            <label>{t("field_name")}</label>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={selectedField.name}
              placeholder={t("field_name")}
              onChange={handleFormChange}
              autoFocus
            />
            <div className="fields-table">
              <table className="basic-table" cellSpacing={0}>
                <thead>
                  <tr>
                    <th>{t("Field_books")}</th>
                  </tr>
                </thead>
                <tbody>
                  {bookTypeList.map((q: any) => (
                    <tr key={q.id}>
                      <td>
                        <label>
                          <input
                            autoComplete="off"
                            type="checkbox"
                            name={q.name}
                            value={q.id}
                            checked={selectedField.proposalsIds.includes(q.id)}
                            onChange={() => handleCheckBoxChange(q.id)}
                          />
                          {q.name}
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modern-form__footer">
            <button> {t("save")}</button>
          </div>
        </form>
      </Popup>
      <Popup
        onClose={() => {
          setShowRemovePopup(false);
        }}
        showPopup={showRemovePopup}
      >
        <div className="popup__remove">
          <p>
            {t("delete_field_request")}
            <b> {selectedField.name} ؟</b>
          </p>
          <div>
            <button
              className="btn normal"
              onClick={() => {
                setShowRemovePopup(false);
              }}
            >
              {t("close")}
            </button>
            <button
              className="btn delete"
              onClick={() => {
                DeleteFields(selectedField.id ?? 0, selectedField).then(() => {
                  GetFields().then((res) => setFieldsList(res.data));
                  setShowRemovePopup(false);
                });
              }}
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
