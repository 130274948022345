import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import ThemeContext from "../context/ThemeContext";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { ProgressBar } from "components/ProgressBar";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectLoading, trackPromises } from "store/states/loadingSlice";
import { pushAlert } from "store/states/alertSlice";
import { Alert } from "./Alert";

export default function Layout({ children }: React.PropsWithChildren) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "light");
  const dispatch = useAppDispatch();
  const requestsList = useAppSelector(selectLoading);
  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
  }, [theme]);
  const toggleTheme = () => {
    const current_theme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", current_theme);
    setTheme(current_theme);
  };

  axios.interceptors.request.use(
    function (request) {
      dispatch(trackPromises({ url: request.url, type: "add" }));
      return request;
    },
    function (error) {
      dispatch(trackPromises({ url: error.config.url, type: "remove" }));
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response: any) {
      showAlert(response, "success");

      return response;
    },
    function (error) {
      showAlert(error.response, "Reject");
      dispatch(trackPromises({ url: error.config.url, type: "remove" }));

      return Promise.reject(error);
    }
  );

  const showAlert = (response: any, type: string) => {
    if (response?.data.statusCode === 401) {
      localStorage.removeItem("user_token");
      window.location.reload();
    }
    if (!response?.hasOwnProperty("__logged__")) {
      response["__logged__"] = true;
      if (
        response.data.type &&
        !["application/pdf", "document", "officedocument"].some((s) =>
          response.data.type.includes(s)
        )
      ) {
        dispatch(
          pushAlert({
            show: true,
            type,
            msgAR: response.data.confirmAR || response.data.message,
            msgEN: response.data.confirmEN || response.data.message,
          })
        );

        setTimeout(() => {
          dispatch(
            pushAlert({
              show: false,
              msg: "",
            })
          );
        }, 3000);
      }
      dispatch(trackPromises({ url: response.config.url, type: "remove" }));
    }
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {requestsList.length > 0 && <ProgressBar />}
      <Alert />
      <Header />
      <div id="main-body">
        <Outlet />
      </div>
      <Footer />
    </ThemeContext.Provider>
  );
}
