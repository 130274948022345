import React, { ChangeEvent } from "react";
import styles from "./InputNumber.module.scss";

interface InputNumberProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputNumber: React.FC<InputNumberProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (newValue < 0) {
      event.target.value = "0";
    }
    onChange(event);
  };

  return (
    <input
      autoComplete="off"
      className={styles.input}
      type="number"
      value={value}
      onChange={handleChange}
      min={0}
      {...rest}
    />
  );
};

export default InputNumber;
