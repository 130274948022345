import axios from "axios";

import { Arbitrator } from "types/arbitrator";
import { User } from "types/user";

export const RegisterPublication = (publication: any) => {
  return axios.post("publishings", publication);
};
export const UpdatePublication = (publication: any) => {
  return axios.put(`publishings/${publication.id}`, publication);
};

export const GetPublications = (isActive: boolean, id?: number) => {
  return axios.get(`publishings/${id ?? `?isActive=${isActive}`}`);
};

export const GetPublicationAttachments = (id: number) => {
  return axios.get(`publishings/${id}/attachments`);
};

export const GetPublicationsLists = () => {
  return axios.get("publishings/lists");
};
export const RemovePublication = (id: number) => {
  return axios.delete(`publishings/${id}`);
};

interface IrequestBody {
  publishingId: number;
  arbitrators: Arbitrator[];
  proofreader: User | undefined;
  comment: string;
  status: string;
  bank: any;
  matchingForm: any;
  rejectReasons: string[];
  attachments: [];
}
export const AddCommentOnPublication = (requestBody: IrequestBody | any) => {
  const body = {
    publishingId: requestBody.publishingId,
    comment: requestBody.comment,
    attachments: requestBody.attachments,
    status: requestBody.status,
    rejectReasons: requestBody.rejectReasons,
    bank: requestBody.bank,
    matchingForm: requestBody.matchingForm,
  };
  let payload = {};
  if (requestBody.status === "Choose arbitrators") {
    const CandidatesArbitrators =
      requestBody.arbitrators?.map((a: Arbitrator) => ({
        ArbitratorId: a.id,
        amount: a.amount,
      })) || [];
    payload = { ...body, CandidatesArbitrators };
  } else if (requestBody.proofreader && requestBody.status === "Choose proofreader") {
    const candidateProofreader = {
      proofreaderId: requestBody.proofreader.id,
      amount: requestBody.proofreader.amount,
    };
    payload = { ...body, candidateProofreader };
  } else {
    const arbitratorsIds = requestBody.arbitrators?.map((a: Arbitrator) => a.id) || [];
    payload = { ...body, arbitratorsIds };
  }

  return axios.post("PublishingFeedbacks", payload);
};
